import { Rate, Space, Table, Typography, Input, DatePicker, AutoComplete } from "antd";
import Avatar from "antd/es/avatar/avatar";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getCustomerPaymentHistory, getCustomers, getDateWisePaymentHistory, getInventory, getOrders, getPaymentHistory, getRefunds, getStatusPaymentHistory, getUsers, getVendors } from "../../API";

function PaymentHistory() {

    const [loading, setLoading] = useState(false);
    const [datasource, setDatasource] = useState([]);
    const [billsList, setBillsList] = useState([]);
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [customers, setCustomers] = useState("")

    useEffect(() => {
        setLoading(true);
        getPaymentHistory("1").then(res => {
            setLoading(false);
            setDatasource(res.PaymentsHistory)
            setBillsList(res.PaymentsHistory)
        })
        getCustomers("1")
            .then(res => {
                setCustomers(res.Customers)
            })
    }, []);

    const refreshFilters = () => {
        getPaymentHistory("1").then(res => {
            setLoading(false);
            setDatasource(res.PaymentsHistory)
            setBillsList(res.PaymentsHistory)
        })
        getCustomers("1")
            .then(res => {
                setCustomers(res.Customers)
            })
    }

    const getDataByDateRange = () => {
        console.log(startDate, " - ", endDate)
        getDateWisePaymentHistory("1", startDate, endDate)
            .then(res => {
                setDatasource(res.PaymentsHistory)
            })
    }

    const getCustomerWisePaymentHistory = (mobile) => {
        console.log(mobile)
        getCustomerPaymentHistory("1", mobile)
            .then(res => {
                setDatasource(res.PaymentsHistory)
            })
    }

    const getPaymentHistoryByStatus = (status) => {
        getStatusPaymentHistory("1", status)
        .then(res => {
            setDatasource(res.PaymentsHistory)
        })
    }

    return (
        <Space size={20} direction="vertical">
            <Typography.Title level={4}>
                Payment History
            </Typography.Title>
            <button className="btn btn-danger" onClick={() => refreshFilters()}>Clear Search</button>
            <Space direction="horizontal" >
                <button className="btn btn-danger" onClick={() => getPaymentHistoryByStatus("FULL")}>Get Full Payments</button>
                <button className="btn btn-danger" onClick={() => getPaymentHistoryByStatus("PARTIAL")}>Get Partial Payments</button>
            </Space>
            <label className="form-label" htmlFor="purchasedfrom">
                Search By Customer Name
            </label>
            <AutoComplete
                className="form-control"
                //value={inputValueCustomer}
                options={customers}
                autoFocus={true}
                style={{ width: 200 }}
                filterOption={(inputValue, option) =>
                    option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
                onSelect={(value, data) => {
                    // setInputValueVendor(value);
                    // setSelectedVendorID(data.id);
                    // setSelectedVendorName(data.vendor_name);
                    // searchByVendor(data.id);
                    //getCustomerWisePaymentHistory(data.mobile)
                    getCustomerWisePaymentHistory(data.mobile)
                    //searchBillsByCustomer(data.id);
                }}
                onChange={() => {
                    //setInputValueVendor("");
                }}
            />
            <label className="form-label" htmlFor="purchasedfrom">
                Search By Bill ID
            </label>
            <AutoComplete
                className="form-control"
                //value={inputValueCustomer}
                options={billsList}
                autoFocus={true}
                style={{ width: 200 }}
                filterOption={(inputValue, option) =>
                    option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
                onSelect={(value, data) => {
                    // setInputValueVendor(value);
                    // setSelectedVendorID(data.id);
                    // setSelectedVendorName(data.vendor_name);
                    // searchByVendor(data.id);
                    //getCustomerWisePaymentHistory(data.mobile)
                    //getCustomerWisePaymentHistory(data.mobile)
                    //searchBillsByCustomer(data.id);
                }}
                onChange={() => {
                    //setInputValueVendor("");
                }}
            />
            <div className="mb-3">
                <Space direction="horizontal" >

                    <div className="mb-3">
                        <label className="form-label" htmlFor="purchasedfrom">
                            Select Start Date
                        </label>
                        <DatePicker className="form-control" onChange={(dateString) => {
                            // setStartDate(moment(date).format('YYYY-MM-DD'))
                            //console.log(moment(dateString).format('YYYY-MM-DD'))
                            //console.log(dateString)
                            var tempStartDate = new Intl.DateTimeFormat('en-UK', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(dateString);
                            console.log(tempStartDate.split('/').reverse().join('-'))
                            setStartDate(tempStartDate.split('/').reverse().join('-'))
                        }} />
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="purchasedfrom">
                            Select End Date
                        </label>
                        <DatePicker className="form-control" onChange={(dateString) => {
                            var tempStartDate = new Intl.DateTimeFormat('en-UK', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(dateString);
                            console.log(tempStartDate.split('/').reverse().join('-'))
                            setEndDate(tempStartDate.split('/').reverse().join('-'))
                        }} />
                    </div>
                    <button className="btn btn-danger" onClick={() => { getDataByDateRange() }}>Search By Date</button>
                </Space>
            </div>
            <Table
                columns={[
                    {
                        title: "Payment ID",
                        dataIndex: "payment_id"
                    },
                    {
                        title: "Bill ID",
                        dataIndex: "bill_id"
                    },
                    {
                        title: "Total Amount",
                        dataIndex: "amount"
                    },
                    {
                        title: "Advance",
                        dataIndex: "advance"
                    },
                    {
                        title: "Customer Name",
                        dataIndex: "customer_name"
                    },
                    {
                        title: "Customer Mobile",
                        dataIndex: "customer_mobile"
                    },
                    {
                        title: "Date",
                        dataIndex: "date"
                    },
                    {
                        title: "Payment Type",
                        dataIndex: "status"
                    },
                    // {
                    //     title: "Email",
                    //     dataIndex: "email"
                    // },
                    // {
                    //     title: "Username",
                    //     dataIndex: "username"
                    // },
                    // {
                    //     title: "Address",
                    //     dataIndex: "address",
                    //     render: (address) => {
                    //         return <span>{address.address} {address.city} {address.state}</span>
                    //     }
                    // },

                ]}
                loading={loading}
                dataSource={datasource}
                pagination={{
                    pageSize: 5
                }}>

            </Table>
        </Space>
    )
}

export default PaymentHistory;