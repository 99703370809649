import React, { useState } from "react";
import Input from 'antd/es/input/Input';
import { AddVendor } from "../../API";

const AddVendors = () => {

    const [vendorName, setVendorName] = useState("");
    const [vendorMobile, setVendorMobile] = useState("");
    const [vendorLocation, setVendorLocation] = useState("");

    const addVendorNew = () => {
        if (vendorName === '') {
            alert('Please add Vendor Name.');
        } else if (vendorMobile === '') {
            alert('Please add Vendor Mobile.');
        } else if (vendorLocation === '') {
            alert('Please add Vendor Location.');
        } else {
            AddVendor(vendorName, vendorMobile, vendorLocation)
            .then(res => {
                setVendorName("");
                setVendorMobile("");
                setVendorLocation("");
                alert("Vendor Added Successfully.");
            })
        }
    }

    return (
        <div className="container mt-5">
            <h2 className="mb-3">Add Vendor Details</h2>
            {/* <form onSubmit={onSubmit}> */}
            <div className="mb-3">
                <label className="form-label" htmlFor="title">
                    Vendor Name
                </label>
                <Input value={vendorName} className="form-control" id="quantity" required onChange={(evt) => { setVendorName(evt.target.value) }} />
            </div>
            <div className="mb-3">
                <label className="form-label" htmlFor="price">
                    Vendor Mobile
                </label>
                <Input value={vendorMobile} className="form-control" type="number" id="quantity" required onChange={(evt) => { setVendorMobile(evt.target.value) }} />
            </div>
            <div className="mb-3">
                <label className="form-label" htmlFor="price">
                    Vendor Location
                </label>
                <Input value={vendorLocation} className="form-control" id="quantity" required onChange={(evt) => { setVendorLocation(evt.target.value) }} />
            </div>
            <button className="btn btn-danger mb-5" onClick={() => addVendorNew()}>
                Add Vendor
            </button>
        </div>
    )
}

export default AddVendors;