import { Space, Typography, Card, Statistic, Table, Modal, DatePicker, Input } from "antd";
import { DollarCircleFilled, DollarCircleOutlined, ShoppingCartOutlined, ShoppingOutlined, UserOutlined } from "@ant-design/icons"
import { useEffect, useState } from "react";
import { getCustomers, getDateWiseWaste, getInventory, getInventoryDatewise, getInventoryFromDateToDate, getOrders, getOrdersByDate, getOrdersFromDateToDate, getWasteFromDateToDate, getWastedProducts } from "../../API";
import { Chart } from "react-google-charts";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

function Dashboard() {

    const [orders, setOrders] = useState(0);
    const [inventory, setInventory] = useState(0);
    const [customers, setCustomers] = useState(0);
    const [revenue, setRevenue] = useState(0);
    const [purchase, setPurchase] = useState(0);
    const [wasteMaterial, setWasteMaterial] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [dhadbookInventory, setDhadabookInventory] = useState([]);
    const [dhadabookOrders, setDhadabookOrders] = useState([]);
    const [dhadabookWasteMaterial, setDhadabookWasteMaterial] = useState([]);
    const [dhadabookRevenue, setDhadabookRevenue] = useState(0);
    const [dhadabookPurchase, setDhadabookPurchase] = useState(0);
    const [dhadabookWaste, setDhadabookWaste] = useState(0);
    const [dhadabookTaxes, setDhadabookTaxes] = useState(0);
    const [selectedFinalDate, setSelectedFinalDate] = useState("");
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("");
    const [showNotificationModal, setShowNotificationModal] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState("")

    useEffect(() => {
        let newDate = new Date()
        let date = newDate.getDate();
        if (date < 10) {
            date = "0"+date;
        } else {

        }
        let month = newDate.getMonth() + 1;
        if (month < 10) {
            month = "0"+month;
        } else {

        }
        let year = newDate.getFullYear();
        let finaldate = date+"-"+month+"-"+year;
        setSelectedFinalDate(finaldate);
        getOrders("1").then(res => {
            setOrders(res.Bills.length);
            var tempRevenue = 0;
            var tempOrderProducts = [];
            for (let i = 0; i < res.Bills.length; i++) {
                //tempRevenue = tempRevenue + parseFloat(res.Bills[i].total_amount)
                tempOrderProducts = tempOrderProducts.concat(JSON.parse(res.Bills[i].billdata));
            }
            console.log(tempOrderProducts);
            setDhadabookOrders(tempOrderProducts);
            for (let j = 0; j < tempOrderProducts.length; j++) {
                tempRevenue = tempRevenue + (parseFloat(tempOrderProducts[j].price)*parseFloat(tempOrderProducts[j].qty))
            }
            setRevenue(tempRevenue);
            setDhadabookRevenue(tempRevenue);
        });
        getCustomers("1").then(res => {
            setCustomers(res.Customers.length);
        });
        getInventory("1").then(res => {
            setInventory(res.Products.length);
            let tempInventory = [];
            var tempTaxes = 0;
            tempInventory = [...res.Products];
            setDhadabookInventory(tempInventory);
            var tempPurchase = 0;
            for (let i = 0; i < res.Products.length; i++) {
                tempTaxes = tempTaxes + parseFloat(res.Products[i].levitax);
                tempPurchase = tempPurchase + (parseFloat(res.Products[i].purchase_price) * parseFloat(res.Products[i].quantity));
            }
            setPurchase(tempPurchase);
            setDhadabookTaxes(tempTaxes);
            setDhadabookPurchase(tempPurchase);
        });
        getWastedProducts("1").then(res => {
            //setLoading(false);
            setDhadabookWasteMaterial(res.Products);
            var tempWaste = 0;
            for (let i = 0; i < res.Products.length; i++) {
                tempWaste = tempWaste + (parseFloat(res.Products[i].product_price) * parseFloat(res.Products[i].quantity))
            }
            setDhadabookWaste(tempWaste);
        })
    }, []);

    const getTotalData = () => {
        getOrders("1").then(res => {
            setOrders(res.Bills.length);
            var tempRevenue = 0;
            var tempOrderProducts = [];
            for (let i = 0; i < res.Bills.length; i++) {
                //tempRevenue = tempRevenue + parseFloat(res.Bills[i].total_amount)
                tempOrderProducts = tempOrderProducts.concat(JSON.parse(res.Bills[i].billdata));
            }
            console.log(tempOrderProducts);
            setDhadabookOrders(tempOrderProducts);
            for (let j = 0; j < tempOrderProducts.length; j++) {
                tempRevenue = tempRevenue + (parseFloat(tempOrderProducts[j].price)*parseFloat(tempOrderProducts[j].qty))
            }
            setRevenue(tempRevenue);
            setDhadabookRevenue(tempRevenue);
        });
        getCustomers("1").then(res => {
            setCustomers(res.Customers.length);
        });
        getInventory("1").then(res => {
            setInventory(res.Products.length);
            let tempInventory = [];
            var tempTaxes = 0;
            tempInventory = [...res.Products];
            setDhadabookInventory(tempInventory);
            var tempPurchase = 0;
            for (let i = 0; i < res.Products.length; i++) {
                tempTaxes = tempTaxes + parseFloat(res.Products[i].levitax);
                tempPurchase = tempPurchase + (parseFloat(res.Products[i].purchase_price) * parseFloat(res.Products[i].quantity));
            }
            setPurchase(tempPurchase);
            setDhadabookTaxes(tempTaxes);
            setDhadabookPurchase(tempPurchase);
        });
        getWastedProducts("1").then(res => {
            //setLoading(false);
            setDhadabookWasteMaterial(res.Products);
            var tempWaste = 0;
            for (let i = 0; i < res.Products.length; i++) {
                tempWaste = tempWaste + (parseFloat(res.Products[i].product_price) * parseFloat(res.Products[i].quantity))
            }
            setDhadabookWaste(tempWaste);
        })
        setIsModalVisible(true);
    }

    const setTodaysDate = () => {
        let newDate = new Date()
        let date = newDate.getDate();
        if (date < 10) {
            date = "0"+date;
        } else {

        }
        let month = newDate.getMonth() + 1;
        if (month < 10) {
            month = "0"+month;
        } else {

        }
        let year = newDate.getFullYear();
        let finaldate = year+"-"+month+"-"+date;
        setStartDate(finaldate);
        setEndDate(finaldate);
        //getDateWiseReport();
        getInventoryFromDateToDate(finaldate, finaldate)
            .then(res => {
                let tempInventory = [];
                tempInventory = [...res.Products];
                setDhadabookInventory(tempInventory);
                var tempPurchase = 0;
                var tempTaxes = 0;
                for (let i = 0; i < res.Products.length; i++) {
                    tempTaxes = tempTaxes + parseFloat(res.Products[i].levitax);
                    tempPurchase = tempPurchase + (parseFloat(res.Products[i].purchase_price) * parseFloat(res.Products[i].quantity));
                }
                //setPurchase(tempPurchase);
                setDhadabookTaxes(tempTaxes);
                setDhadabookPurchase(tempPurchase);
            })
            getOrdersFromDateToDate(finaldate, finaldate, "")
            .then(res => {
                console.log(res);
                var tempRevenue = 0;
                var tempOrderProducts = [];
                for (let i = 0; i < res.Bills.length; i++) {
                    //tempRevenue = tempRevenue + parseFloat(res.Bills[i].total_amount)
                    tempOrderProducts = tempOrderProducts.concat(JSON.parse(res.Bills[i].billdata));
                }
                console.log(tempOrderProducts);
                setDhadabookOrders(tempOrderProducts);
                for (let j = 0; j < tempOrderProducts.length; j++) {
                    tempRevenue = tempRevenue + (parseFloat(tempOrderProducts[j].price)*parseFloat(tempOrderProducts[j].qty))
                }
                setDhadabookRevenue(tempRevenue);
            })
            getWasteFromDateToDate(finaldate, finaldate)
            .then(res => {
                setDhadabookWasteMaterial(res.Products);
                var tempWaste = 0;
                for (let i = 0; i < res.Products.length; i++) {
                    tempWaste = tempWaste + (parseFloat(res.Products[i].product_price) * parseFloat(res.Products[i].quantity))
                }
                setDhadabookWaste(tempWaste);
            })
            setIsModalVisible(true);
    }

    const getDateWiseReport = () => {
        if (startDate === "") {
            alert("Please select start date.")
        } else if (endDate === "") {
            alert("Please select end date.")
        } else {
            console.log(startDate);
            getInventoryFromDateToDate(startDate, endDate)
            .then(res => {
                let tempInventory = [];
                tempInventory = [...res.Products];
                setDhadabookInventory(tempInventory);
                var tempPurchase = 0;
                var tempTaxes = 0;
                for (let i = 0; i < res.Products.length; i++) {
                    tempTaxes = tempTaxes + parseFloat(res.Products[i].levitax);
                    tempPurchase = tempPurchase + (parseFloat(res.Products[i].purchase_price) * parseFloat(res.Products[i].quantity));
                }
                //setPurchase(tempPurchase);
                setDhadabookTaxes(tempTaxes);
                setDhadabookPurchase(tempPurchase);
            })
            getOrdersFromDateToDate(startDate, endDate)
            .then(res => {
                var tempRevenue = 0;
                var tempOrderProducts = [];
                for (let i = 0; i < res.Bills.length; i++) {
                    //tempRevenue = tempRevenue + parseFloat(res.Bills[i].total_amount)
                    tempOrderProducts = tempOrderProducts.concat(JSON.parse(res.Bills[i].billdata));
                }
                console.log(tempOrderProducts);
                setDhadabookOrders(tempOrderProducts);
                for (let j = 0; j < tempOrderProducts.length; j++) {
                    tempRevenue = tempRevenue + (parseFloat(tempOrderProducts[j].price)*parseFloat(tempOrderProducts[j].qty))
                }
                setDhadabookRevenue(tempRevenue);
            })
            getWasteFromDateToDate(startDate, endDate)
            .then(res => {
                setDhadabookWasteMaterial(res.Products);
                var tempWaste = 0;
                for (let i = 0; i < res.Products.length; i++) {
                    tempWaste = tempWaste + (parseFloat(res.Products[i].product_price) * parseFloat(res.Products[i].quantity))
                }
                setDhadabookWaste(tempWaste);
            })
            setIsModalVisible(true);
        }
    }

    const downloadPdfDocument = (downloadFileName) => {
        const input = document.getElementById("testId");
        const pdf = new jsPDF({ unit: "px", format: "letter", userUnit: "px" });
        pdf.html(input, { margin: [15, 0, 15, 0], html2canvas: { scale: 0.95 } }).then(() => {
            pdf.save(downloadFileName+".pdf");
        });
    }

    return (
        <Space size={12} direction="vertical">
            <Typography.Title level={4}>
                Dashboard
            </Typography.Title>
            <Space direction="horizontal">
                <DashboardCard
                    icon={<ShoppingCartOutlined
                        style={{
                            color: 'green',
                            backgroundColor: 'rgba(0, 255, 0, 0.5)',
                            borderRadius: 20,
                            fontSize: 24,
                            padding: 8
                        }} />}
                    title="Orders"
                    value={orders} />
                <DashboardCard
                    icon={<ShoppingOutlined
                        style={{
                            color: 'blue',
                            backgroundColor: 'rgba(0, 0, 255, 0.25)',
                            borderRadius: 20,
                            fontSize: 24,
                            padding: 8
                        }} />}
                    title="Products"
                    value={inventory} />
                <DashboardCard
                    icon={<UserOutlined
                        style={{
                            color: 'purple',
                            backgroundColor: 'rgba(0, 255, 255, 0.5)',
                            borderRadius: 20,
                            fontSize: 24,
                            padding: 8
                        }} />}
                    title="Customers"
                    value={customers} />
                <DashboardCard
                    icon={<DollarCircleOutlined
                        style={{
                            color: 'red',
                            backgroundColor: 'rgba(255, 0, 0, 0.25)',
                            borderRadius: 20,
                            fontSize: 24,
                            padding: 8
                        }} />}
                    title="Revenue"
                    value={revenue} />
            </Space>
            <Space direction="horizontal">
                <button className="btn btn-danger mt-3" onClick={() => getTotalData()}>Show All Time Report</button>
                <button className="btn btn-danger mt-3" onClick={() => setTodaysDate()}>Show Today's Report</button>
            </Space>
            <Space direction="horizontal" >
                <div className="mb-3">
                    <label className="form-label" htmlFor="purchasedfrom">
                        Select Start Date
                    </label>
                    <DatePicker className="form-control" onChange={(dateString) => {
                        // setStartDate(moment(date).format('YYYY-MM-DD'))
                        //console.log(moment(dateString).format('YYYY-MM-DD'))
                        //console.log(dateString)
                        var tempStartDate = new Intl.DateTimeFormat('en-UK', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(dateString);
                        console.log(tempStartDate.split('/').reverse().join('-'))
                        setStartDate(tempStartDate.split('/').reverse().join('-'))
                    }} />
                </div>
                <div className="mb-3">
                    <label className="form-label" htmlFor="purchasedfrom">
                        Select End Date
                    </label>
                    <DatePicker className="form-control" onChange={(dateString) => {
                        var tempStartDate = new Intl.DateTimeFormat('en-UK', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(dateString);
                        console.log(tempStartDate.split('/').reverse().join('-'))
                        setEndDate(tempStartDate.split('/').reverse().join('-'))
                    }} />
                </div>
                <button className="btn btn-danger mt-3" onClick={() => getDateWiseReport()}>Show report by date</button>
                <button className="btn btn-danger mt-3" onClick={() => setShowNotificationModal(true)}>Send Bulk Notifiction</button>
            </Space>
            <Space>
                <RecentOrders />
                <EmptyView />
                <BarChart orders={orders} customers={customers} revenue={revenue} purchase={purchase} />
            </Space>
            <Modal
                visible={showNotificationModal}
                okText="Send"
                closable={false}
                cancelText="Cancel"
                onOk={() => {
                    setShowNotificationModal(false);
                    //editIndex();
                    //downloadPdfDocument("test");
                }}
                onCancel={() => {
                    setShowNotificationModal(false);
                    setNotificationMessage("")
                }}>
                <div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="stock">
                            Enter Notification Message
                        </label>
                        <Input value={notificationMessage} className="form-control mb-1" id="stock" required onChange={(evt) => { setNotificationMessage(evt.target.value) }}/>
                    </div>
                </div>
            </Modal>
            <Modal
                visible={isModalVisible}
                okText="Print"
                closable={false}
                cancelText="Close"
                onOk={() => {
                    let newDate = new Date()
                    let date = newDate.getDate();
                    if (date < 10) {
                        date = "0"+date;
                    } else {

                    }
                    let month = newDate.getMonth() + 1;
                    if (month < 10) {
                        month = "0"+month;
                    } else {

                    }
                    let year = newDate.getFullYear();
                    let finaldate = date+"-"+month+"-"+year;
                    downloadPdfDocument("Report "+finaldate);
                }}
                onCancel={() => {
                    setIsModalVisible(false);
                }}>
                <div id="testId" class="mt-10 mb-7">
                    <div class="row justify-content-center">
                    <div class="col-lg-15 col-xl-15">
                        <div class="card">
                        <div class="card-body p-4">
                            <div class="text-muted mb-2">Date: {selectedFinalDate}</div>
                            <div class="text-muted mb-2">Products Purchased</div>
                            <div class="border-top border-gray-200 pt-4 mt-2 mb-2"></div>
                            <div>
                                <Table
                                    columns={[
                                        {
                                            title: "Vendor Name",
                                            dataIndex: "vendor_name"
                                        },
                                        {
                                            title: "Product Name",
                                            dataIndex: "product_name"
                                        },
                                        {
                                            title: "Purchase Price",
                                            dataIndex: "purchase_price",
                                            render: (value) => <span>₹{value}</span>
                                        },
                                        {
                                            title: "Quantity",
                                            dataIndex: "quantity"
                                        },
                                        {
                                            title: "Carat",
                                            dataIndex: "carat"
                                        },
                                    ]}
                                    //loading={loading}
                                    dataSource={dhadbookInventory}
                                    pagination={false} 
                                    >

                                </Table>
                            </div>
                            <div class="text-muted mb-2 mt-5">Products Sold</div>
                            <div class="border-top border-gray-200 pt-4 mt-2 mb-2"></div>
                            <div>
                                <Table
                                    columns={[
                                        {
                                            title: "Product",
                                            dataIndex: "title"
                                        },
                                        {
                                            title: "Quantity",
                                            dataIndex: "qty"
                                        },
                                        {
                                            title: "Rate",
                                            dataIndex: "price",
                                            render: (value) => <span>₹{value}</span>
                                        },
                                        {
                                            title: "Cost",
                                            dataIndex: "price",
                                            render: (value, data) => <span>₹{(parseFloat(data.price)*parseFloat(data.qty))}</span>
                                        },
                                    ]}
                                    dataSource={dhadabookOrders}
                                    pagination={false}>
                                </Table>
                            </div>
                            <div class="text-muted mb-2 mt-5">Waste Material</div>
                            <div class="border-top border-gray-200 pt-4 mt-2 mb-2"></div>
                            <div>
                                <Table
                                    columns={[
                                        {
                                            title: "Product Name",
                                            dataIndex: "product_name"
                                        },
                                        {
                                            title: "Product Quantity",
                                            dataIndex: "quantity"
                                        },
                                        {
                                            title: "Price",
                                            dataIndex: "product_price",
                                            render: (value) => <span>₹{value}</span>
                                        },
                                        {
                                            title: "Expiry Date",
                                            dataIndex: "expiry_date"
                                        },
                                    ]}
                                    dataSource={dhadabookWasteMaterial}
                                    pagination={false}>

                                </Table>
                            </div>
                            <div class="text-muted mb-2 mt-5">Levi and Tax</div>
                            <div class="border-top border-gray-200 pt-4 mt-2 mb-2"></div>
                            <div>
                            <Table
                                columns={[
                                    {
                                        title: "Product Name",
                                        dataIndex: "product_name"
                                    },
                                    {
                                        title: "Levi/Tax",
                                        dataIndex: "levitax",
                                        render: (value) => <span>₹{value}</span>
                                    },
                                ]}
                                dataSource={dhadbookInventory}
                                pagination={false}>

                            </Table>
                            </div>
                            <div class="mt-5">
                            <div class="d-flex justify-content-end mt-3">
                                <h5 class="me-3">Total Purchase:</h5>
                                <h5>₹{dhadabookPurchase}</h5>
                            </div>
                            <div class="d-flex justify-content-end mt-3">
                                <h5 class="me-3">Total Waste:</h5>
                                <h5>₹{dhadabookWaste}</h5>
                            </div>
                            <div class="d-flex justify-content-end mt-3">
                                <h5 class="me-3">Total Levi/Tax:</h5>
                                <h5>₹{dhadabookTaxes}</h5>
                            </div>
                            <div class="d-flex justify-content-end mt-3">
                                <h5 class="me-3">Total Sale:</h5>
                                <h5>₹{dhadabookRevenue}</h5>
                            </div>
                            <div class="d-flex justify-content-end mt-3">
                                <h5 class="me-3">Total Profits:</h5>
                                <h5>₹{parseFloat(dhadabookRevenue) - parseFloat(dhadabookPurchase) - parseFloat(dhadabookWaste) - parseFloat(dhadabookTaxes)}</h5>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </Modal>
        </Space>
    )
}

function DashboardCard({ title, value, icon }) {
    return (
        <Card>
            <Space direction="horizontal">
                {icon}
                <Statistic title={title} value={value}></Statistic>
            </Space>
        </Card>
    )
}

function RecentOrders() {

    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getOrders("1").then((res) => {
            console.log(res);
            setDataSource(res.Bills.splice(0, 3));
            setLoading(false);
        })
    }, []);

    return (
        <>
            <Typography.Text>Recent Orders</Typography.Text>
            <Table
                columns={[
                    {
                        title: "Bill ID",
                        dataIndex: "billid"
                    },
                    {
                        title: "Customer Name",
                        dataIndex: "customer_name"
                    },
                    {
                        title: "Customer Mobile",
                        dataIndex: "customer_mobile"
                    },
                    {
                        title: "Total",
                        dataIndex: "total_amount"
                    },
                ]}
                loading={loading}
                dataSource={dataSource}>

            </Table>
        </>
    )

}

function EmptyView() {
    return (
        <Space>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
        </Space>
    )
}

function BarChart({ orders, customers, revenue, purchase }) {

    const data = [
        ["Year", "Purchase", "Sale"],
        [new Date().getFullYear(), purchase, revenue],
    ];

    const options = {
        chart: {
            title: "Company Performance",
            subtitle: "Sales, Expenses, and Profit: "+new Date().getFullYear(),
        },
    };

    return (
        <Chart
            chartType="Bar"
            width="100%"
            height="400px"
            marginLeft="200px"
            data={data}
            options={options}
        />
    )
}

export default Dashboard;