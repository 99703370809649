import React, { useState } from "react";
import Input from 'antd/es/input/Input';
import { AddPincodes } from "../../API";

const AddPincodesScreen = () => {

    const [pincode, setPincode] = useState("");
    const [city, setCity] = useState("");
    const [deliveryCharges, setDeliveryCharges] = useState("");
    const [kmIndex, setkmIndex] = useState("");
    const [dozenIndex, setDozenIndex] = useState("");
    const [judiIndex, setJudiIndex] = useState("");
    const [packIndex, setPackIndex] = useState("");

    const addPincodeNew = () => {
        if (pincode === '') {
            alert('Please add proper Pincode.');
        } else if (city === '') {
            alert('Please add City.');
        } else if (deliveryCharges === '') {
            alert('Please add Delivery Charges.');
        } else if (kmIndex === '') {
            alert('Please add KM Index.');
        } else if (dozenIndex === '') {
            alert('Please add Dozen Index.');
        } else if (judiIndex === '') {
            alert('Please add Judi Index.');
        } else if (packIndex === '') {
            alert('Please add Pack Index.');
        } else {
            AddPincodes(pincode, city, deliveryCharges, kmIndex, dozenIndex, judiIndex, packIndex)
            .then(res => {
                setPincode("");
                setCity("");
                alert("Pincode Added Successfully.");
            })
        }
        
    }

    return (
        <div className="container mt-5">
            <h2 className="mb-3">Add Pincode Details</h2>
            <div className="mb-3">
                <label className="form-label" htmlFor="title">
                    Pincode Number
                </label>
                <Input value={pincode} className="form-control" type="number" id="quantity" required onChange={(evt) => { setPincode(evt.target.value) }} />
            </div>
            <div className="mb-3">
                <label className="form-label" htmlFor="price">
                    Pincode City
                </label>
                <Input value={city} className="form-control" id="quantity" required onChange={(evt) => { setCity(evt.target.value) }} />
            </div>
            <div className="mb-3">
                <label className="form-label" htmlFor="title">
                    Delivery Charges
                </label>
                <Input value={deliveryCharges} className="form-control" type="number" id="quantity" required onChange={(evt) => { setDeliveryCharges(evt.target.value) }} />
            </div>
            <div className="mb-3">
                <label className="form-label" htmlFor="title">
                    KM Index
                </label>
                <Input value={kmIndex} className="form-control" type="number" id="quantity" required onChange={(evt) => { setkmIndex(evt.target.value) }} />
            </div>
            <div className="mb-3">
                <label className="form-label" htmlFor="title">
                    Dozen Index
                </label>
                <Input value={dozenIndex} className="form-control" type="number" id="quantity" required onChange={(evt) => { setDozenIndex(evt.target.value) }} />
            </div>
            <div className="mb-3">
                <label className="form-label" htmlFor="title">
                    Judi Index
                </label>
                <Input value={judiIndex} className="form-control" type="number" id="quantity" required onChange={(evt) => { setJudiIndex(evt.target.value) }} />
            </div>
            <div className="mb-3">
                <label className="form-label" htmlFor="title">
                    Pack Index
                </label>
                <Input value={packIndex} className="form-control" type="number" id="quantity" required onChange={(evt) => { setPackIndex(evt.target.value) }} />
            </div>
            <button className="btn btn-danger mb-5" onClick={() => addPincodeNew()}>
                Add Pincode
            </button>
        </div>
    )
}

export default AddPincodesScreen;