import { Rate, Space, Table, Typography, Modal, Input } from "antd";
import Avatar from "antd/es/avatar/avatar";
import { useEffect, useState } from "react";
import { Link, useNavigation } from "react-router-dom";
import { addTimeSlots, editTimeSlots, getInventory, getTimeSlots, getWastedProducts } from "../../API";


const TimeSlots = () => {
    const [loading, setLoading] = useState(false);
    const [datasource, setDatasource] = useState([]);
    const [timeSlotVisible, setTimeVisible] = useState(false);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState("");

    useEffect(() => {
        setLoading(true);
        getTimeSlots("1").then(res => {
            setLoading(false);
            setDatasource(res.TimeSlots);
        })
    }, []);

    const timeSlotAddition = () => {
        if (selectedTimeSlot === "") {
            alert("Please enter time slot");
        } else {
            addTimeSlots(selectedTimeSlot, "").then(res => {
                alert("Time Slot Added Successfully.")
                getTimeSlots("1").then(res => {
                    setLoading(false);
                    setDatasource(res.TimeSlots);
                })
            })
        }
    }

    const timeSlotEdition = (id, status) => {
        editTimeSlots(id, status).then(res => {
            alert("Time Slot edited Successfully.")
            getTimeSlots("1").then(res => {
                setLoading(false);
                setDatasource(res.TimeSlots);
            })
        })
    }

    return (
        <Space size={20} direction="vertical">
            <Typography.Title level={4} style={{ marginTop: '30px' }}>
                Time Slots
            </Typography.Title>
            <button className="btn btn-danger" onClick={() => setTimeVisible(true)}>Add Time Slot</button>
            <Table
                columns={[
                    // {
                    //     title: "Thumbnail",
                    //     dataIndex: "thumbnail",
                    //     render: (link) => {
                    //         return <Avatar src={link} />
                    //     }
                    // },
                    {
                        title: "Time Slot",
                        dataIndex: "time_slot"
                    },
                    {
                        title: "",
                        dataIndex: "status",
                        render: (value, data) => 
                        <button className="btn btn-danger" onClick={() => {
                            timeSlotEdition(data.id, data.status === "ACTIVE" ? "DEACTIVE" : "ACTIVE")
                        }}>{data.status === "ACTIVE" ? "DE-ACTIVATE" : "ACTIVATE"}</button>
                    },
                ]}
                loading={loading}
                dataSource={datasource}
                pagination={{
                    pageSize: 5
                }}>

            </Table>
            <Modal
                visible={timeSlotVisible}
                closable={false}
                okText="Ok"
                cancelText="Close"
                onOk={() => {
                    setTimeVisible(false);
                    //editIndex();
                    timeSlotAddition()
                }}
                onCancel={() => {
                    setTimeVisible(false);
                }}>
                <div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="stock">
                            Enter Time Slot
                        </label>
                        <Input value={selectedTimeSlot} className="form-control mb-1" id="stock" required onChange={(evt) => { setSelectedTimeSlot(evt.target.value) }}/>
                    </div>
                </div>
            </Modal>
        </Space>
    )
}

export default TimeSlots;

