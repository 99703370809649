import React, { useState } from "react";
import Input from 'antd/es/input/Input';
import { AddUsersNew, AddVendor } from "../../API";
import { Rate, Space, Table, Typography, AutoComplete, Modal } from "antd";

const AddUsers = () => {

    const [name, setName] = useState("");
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [mobile, setMobile] = useState("");
    const [selectedRole, setSelectedRole] = useState("");
    const [roles, setRoles] = useState([
        {
            value: "PurchaseStaff",
            label: "Purchase Staff"
        },
        {
            value: "DeliveryStaff",
            label: "Delivery Staff"
        }
    ])

    const AddUserNew = () => {
        if (name === '') {
            alert('Please add Name.');
        } else if (username === '') {
            alert('Please add Username.');
        } else if (password === '') {
            alert('Please add Password.');
        } else if (mobile === '') {
            alert('Please add Mobile.');
        } else if (selectedRole === '') {
            alert('Please select User Role.');
        } else {
            AddUsersNew(name, username, mobile, password, selectedRole)
            .then(res => {
                setName("");
                setUserName("");
                setPassword("");
                setMobile("")
                alert("User Added Successfully.");
            })
        }
    }

    return (
        <div className="container mt-5">
            <h2 className="mb-3">Add User Details</h2>
            {/* <form onSubmit={onSubmit}> */}
            <div className="mb-3">
                <label className="form-label" htmlFor="title">
                    Name
                </label>
                <Input value={name} className="form-control" required onChange={(evt) => { setName(evt.target.value) }} />
            </div>
            <div className="mb-3">
                <label className="form-label" htmlFor="title">
                    UserName
                </label>
                <Input value={username} className="form-control" required onChange={(evt) => { setUserName(evt.target.value) }} />
            </div>
            <div className="mb-3">
                <label className="form-label" htmlFor="price">
                    User Mobile
                </label>
                <Input value={mobile} className="form-control" type="number" id="quantity" required onChange={(evt) => { setMobile(evt.target.value) }} />
            </div>
            <div className="mb-3">
                <label className="form-label" htmlFor="price">
                    User Password
                </label>
                <Input value={password} className="form-control" id="quantity" required onChange={(evt) => { setPassword(evt.target.value) }} />
            </div>
            <div className="mb-3">
                <label className="form-label" htmlFor="purchasedfrom">
                    User Role
                </label>
                <AutoComplete
                    className="form-control"
                    //value={inputValueCustomer}
                    options={roles}
                    autoFocus={true}
                    style={{ width: 200 }}
                    filterOption={(inputValue, option) =>
                        option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                    onSelect={(value, data) => {
                        setSelectedRole(value)
                        //searchBillsByCustomer(data.id);
                    }}
                    onChange={() => {
                        setSelectedRole("");
                    }}
                />
            </div>
            <button className="btn btn-danger mb-5" onClick={() => AddUserNew()}>
                Add User
            </button>
        </div>
    )
}

export default AddUsers;