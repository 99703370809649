import { Rate, Space, Table, Typography, Modal, Input } from "antd";
import Avatar from "antd/es/avatar/avatar";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { editDeliveryIndex, getCustomerAddresses, getCustomers, getDeliveryIndex, getInventory, getOrders } from "../../API";

function DeliveryChargesDetails() {

    const [loading, setLoading] = useState(false);
    const [datasource, setDatasource] = useState([]);
    const [showEditIndex, setShowEditIndex] = useState(false);
    const [selectedDeliveryIndexTitle, setSelectedDeliveryIndexTitle] = useState("")
    const [selectedDeliveryIndex, setSelectedDeliveryIndex] = useState("")

    useEffect(() => {
        setLoading(true);
        getDeliveryIndex("1").then(res => {
            setLoading(false);
            setDatasource(res.Charges)
        })
    }, []);

    const editIndex = () => {
        editDeliveryIndex(selectedDeliveryIndex, selectedDeliveryIndexTitle)
        .then(res => {
            refreshDeliveryIndex();
        })
    }

    const refreshDeliveryIndex = () => {
        getDeliveryIndex("1").then(res => {
            setLoading(false);
            setDatasource(res.Charges)
        })
    }

    return (
        <Space size={20} direction="vertical">
            <Typography.Title level={4}>
                Customers
            </Typography.Title>
            {/* <Link to="/addcustomers" className="btn btn-danger" type="submit">
                Add Customers
            </Link> */}
            <Table
                columns={[
                    // {
                    //     title: "Image",
                    //     dataIndex: "image",
                    //     render: (link) => {
                    //         return <Avatar src={link} />
                    //     }
                    // },
                    {
                        title: "Charge Name",
                        dataIndex: "charge_name"
                    },
                    {
                        title: "Multiplier",
                        dataIndex: "multiplier"
                    },
                    {
                        title: "",
                        dataIndex: "billdata",
                        render: (value, data) => <button className="btn btn-danger" onClick={() => {
                            setSelectedDeliveryIndexTitle(data.charge_name)
                            setShowEditIndex(true)
                        }}>Edit Index</button>
                    },
                    // {
                    //     title: "Email",
                    //     dataIndex: "email"
                    // },
                    // {
                    //     title: "Username",
                    //     dataIndex: "username"
                    // },
                    // {
                    //     title: "Pincode",
                    //     dataIndex: "address",
                    //     render: (address) => {
                    //         return <span>{address.postalCode}</span>
                    //     }
                    // },
                    // {
                    //     title: "Address",
                    //     dataIndex: "address",
                    //     render: (address) => {
                    //         return <span>{address.address} {address.city} {address.state}</span>
                    //     }
                    // },

                ]}
                loading={loading}
                dataSource={datasource}
                pagination={{
                    pageSize: 5
                }}>

            </Table>
            <Modal
                visible={showEditIndex}
                closable={false}
                okText="Ok"
                cancelText="Close"
                onOk={() => {
                    setShowEditIndex(false);
                    editIndex();
                }}
                onCancel={() => {
                    setShowEditIndex(false);
                }}>
                <div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="stock">
                            {selectedDeliveryIndexTitle}
                        </label>
                        <label className="form-label" htmlFor="stock">
                            Set Multiplier
                        </label>
                        <Input value={selectedDeliveryIndex} className="form-control mb-1" id="stock" required onChange={(evt) => { setSelectedDeliveryIndex(evt.target.value) }}/>
                    </div>
                </div>
            </Modal>
        </Space>
    )
}

export default DeliveryChargesDetails;