import { Rate, Space, Table, Typography, Modal, Input, Menu, Dropdown } from "antd";
import Avatar from "antd/es/avatar/avatar";
import { useEffect, useState } from "react";
import { Link, useNavigation } from "react-router-dom";
import { EditProduct, editProductsByQuantity, getAllProducts, getInventory, getProducts, getProductsByQuantity, setOfferProduct } from "../../API";
import { BASE_URL_PRODUCTION } from "../../Utils/AppConstants";

function ProductList() {

    const [loading, setLoading] = useState(false);
    const [datasource, setDatasource] = useState([]);
    const [dataSearchedSource, setdataSearchedSource] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEditQuantityVisible, setIsEditQuantityVisible] = useState(false);
    const [selectedEditQuantity, setSelectedEditQuantity] = useState('');
    const [selectedEditId, setSelectedEditId] = useState('');
    const [isAddToWasteModalVisible, setIsAddToWasteModalVisible] = useState(false);
    const [selectedProductID, setSelectedProductID] = useState('');
    const [editedProductName, setEditedProductName] = useState('');
    const [editedPrice, setEditedPrice] = useState('');
    const [editedActualPrice, setEditedActualPrice] = useState('');
    const [editedDescription, setEditedDescription] = useState('');
    const [editedAvailability, setEditedAvailability] = useState('');
    const [editedCategory, setEditedCategory] = useState('');
    const [editedUnit, setEditedUnit] = useState('');
    const [editedSize, setEditedSize] = useState('');
    const [editedStorageTips, setEditedStroageTips] = useState('');
    const [editedDisclaimer, setEditedDisclaimer] = useState('');
    const [editedCoutryOfOrigin, setEditedCountryOfOrigin] = useState('');

    const [selectedWasteQuantity, setSelectedWasteQuantity] = useState('');
    const [selectedWasteProductID, setSelectedWasteProductID] = useState('');
    const [selectedWasteProductName, setSelectedWasteProductName] = useState('');
    const [selectedWasteProductPrice, setSelectedWasteProductPrice] = useState('');
    const [searchString, setSearchString] = useState("");

    useEffect(() => {
        setLoading(true);
        getAllProducts("1")
        .then(res => {
            console.log(res);
            setLoading(false);
            setDatasource(res.Products);
            setdataSearchedSource(res.Products);
        })
    }, []);

    const reloadProducts = () => {
        getAllProducts("1")
        .then(res => {
            console.log(res);
            setLoading(false);
            setDatasource(res.Products);
            setdataSearchedSource(res.Products);
            setIsModalVisible(false);
            setEditedPrice('');
        })
    }

    const productEdit = () => {
        EditProduct(selectedProductID, editedPrice, editedCategory, editedProductName, editedActualPrice, editedDescription, editedAvailability, editedUnit, editedSize, editedStorageTips, editedDisclaimer, editedCoutryOfOrigin)
        .then(res => {
            reloadProducts();
        })
    }

    const EditProductQuantity = () => {
        editProductsByQuantity(selectedEditId, selectedEditQuantity)
        .then(res => {
            setIsEditQuantityVisible(false);
            setSelectedEditQuantity("");
            reloadProducts();
        })
    }

    const handleCategoryClick = ({key}) => {
        console.log(key)
        setEditedCategory(key)
        //you can perform setState here
    }

    const handleClick = ({key}) => {
        console.log(key)
        setEditedAvailability(key)
        //you can perform setState here
    }

    const handleUnitClick = ({key}) => {
        console.log(key)
        setEditedUnit(key)
        //you can perform setState here
    }

    const category = (
        <Menu onClick={handleCategoryClick}>
            <Menu.Item key="Vegetables">Vegetables</Menu.Item>
            <Menu.Item key="Leafy Vegetables">Leafy Vegetables</Menu.Item>
            <Menu.Item key="Exotic Vegetables">Exotic Vegetables</Menu.Item>
        </Menu>
    );

    const menu = (
        <Menu onClick={handleClick}>
            <Menu.Item key="AVAILABLE">AVAILABLE</Menu.Item>
            <Menu.Item key="NOT AVAILABLE">NOT AVAILABLE</Menu.Item>
            <Menu.Item key="DELETED">DELETE</Menu.Item>
        </Menu>
    );

    const unit = (
        <Menu onClick={handleUnitClick}>
            <Menu.Item key="KG">KG</Menu.Item>
            <Menu.Item key="Dozen">Dozen</Menu.Item>
            <Menu.Item key="Bunch">Bunch</Menu.Item>
            <Menu.Item key="Pack">Pack</Menu.Item>
        </Menu>
    );

    const searchText = (e) => {
        let text = e.toLowerCase()
        let trucks = datasource;
        let filteredName = trucks.filter((item) => {
            return item.productName.toLowerCase().match(text)
        })
        if (!text || text === '') {
            setdataSearchedSource(datasource);
        } else if (!Array.isArray(filteredName) && !filteredName.length) {
            // set no data flag to true so as to render flatlist conditionally
          
        } else if (Array.isArray(filteredName)) {
        //   this.setState({
        //     noData: false,
        //     data: filteredName
        //   })
            setdataSearchedSource(filteredName)
        }
    }

    return (
        <Space size={20} direction="vertical">
            <Typography.Title level={4} style={{ marginTop: '30px' }}>
                Inventory
            </Typography.Title>
            <Link to="/addnewproduct" className="btn btn-danger" type="submit">
                Add New Product
            </Link>
            <Link to="/addwaste" className="btn btn-danger">
                Add Waste Entry
            </Link>
            <label className="form-label" htmlFor="stock">
                Search by Product Name
            </label>
            <Input value={searchString} className="form-control mb-1" style={{ width: '400px' }} id="stock" required onChange={(evt) => { 
                setSearchString(evt.target.value);
                searchText(evt.target.value)
            }}/>
            <Table
                columns={[
                    {
                        title: "Product Name",
                        dataIndex: "productName"
                    },
                    {
                        title: "Price",
                        dataIndex: "price",
                        render: (value) => <span>₹{value}</span>
                    },
                    {
                        title: "Storage Quantity",
                        dataIndex: "quantity",
                        render: (value) => <span>{value}</span>
                    },
                    {
                        title: "Exact Quantity",
                        dataIndex: "exact_quantity",
                        render: (value) => <span>{value}</span>
                    },
                    {
                        title: "Product Category",
                        dataIndex: "category"
                    },
                    {
                        title: "Product Unit",
                        dataIndex: "unit"
                    },
                    {
                        title: "Product Quality",
                        dataIndex: "quality",
                        render: (value) => <span>{value === "1" ? "First" : value === "2" ? "Second" : "Third"} </span>
                    },
                    {
                        title: "Discount",
                        dataIndex: "discount",
                        render: (value) => <span>₹{value}</span>
                    },
                    {
                        title: "Description",
                        dataIndex: "description"
                    },
                    {
                        title: "Availabilty",
                        dataIndex: "availability"
                    },
                    {
                        title: "Is Offer",
                        dataIndex: "isOfferProduct"
                    },
                    {
                        title: "",
                        dataIndex: "billdata",
                        render: (value, data) => <button className="btn btn-danger" onClick={() => {
                            console.log("Edit");
                            setSelectedProductID(data.id);
                            setEditedProductName(data.productName);
                            setEditedActualPrice(data.price);
                            setEditedPrice(data.discount);
                            setEditedDescription(data.description);
                            setEditedAvailability(data.availability);
                            setEditedCategory(data.category);
                            setEditedUnit(data.unit);
                            setEditedSize(data.size);
                            setEditedStroageTips(data.storage_tips);
                            setEditedDisclaimer(data.disclaimer);
                            setEditedCountryOfOrigin(data.country_of_origin);
                            setIsModalVisible(true)
                        }}>Edit Product</button>
                    },
                    {
                        title: "",
                        dataIndex: "billdata",
                        render: (value, data) => <button className="btn btn-danger" onClick={() => {
                            console.log("Edit");
                            setSelectedEditId(data.id);
                            setIsEditQuantityVisible(true)
                            //EditProductQuantity(data.id);
                            
                        }}>Set Quantity</button>
                    },
                    {
                        title: "",
                        dataIndex: "billdata",
                        render: (value, data) => <button className="btn btn-danger" onClick={() => {
                            console.log("Edit");
                            // setSelectedEditId(data.id);
                            // setIsEditQuantityVisible(true)
                            setOfferProduct(data.id, "YES")
                            .then(res => {
                                alert("Product added in Offer Products")
                                reloadProducts();
                            })
                            //EditProductQuantity(data.id);
                            
                        }}>Set Offer Product</button>
                    },
                    {
                        title: "",
                        dataIndex: "billdata",
                        render: (value, data) => <button className="btn btn-danger" onClick={() => {
                            console.log("Edit");
                            // setSelectedEditId(data.id);
                            // setIsEditQuantityVisible(true)
                            setOfferProduct(data.id, "NO")
                            .then(res => {
                                alert("Product Removed from Offer Products")
                                reloadProducts();
                            })
                            //EditProductQuantity(data.id);
                            
                        }}>Remove Offer Product</button>
                    },
                    // {
                    //     title: "",
                    //     dataIndex: "billdata",
                    //     render: (value, data) => <button className="btn btn-danger" onClick={() => {
                    //         console.log("Add To Waste");
                    //         setSelectedWasteProductID(data.id);
                    //         setSelectedWasteProductName(data.productName);
                    //         setSelectedWasteProductPrice(data.discount);
                    //     }}>Add To Waste</button>
                    // },
                ]}
                loading={loading}
                dataSource={dataSearchedSource}
                pagination={{
                    pageSize: 5
                }}>

            </Table>
            <Modal
                visible={isModalVisible}
                okText="Save"
                closable={false}
                cancelText="Close"
                onOk={() => {
                    productEdit()
                    //downloadPdfDocument("test");
                }}
                onCancel={() => {
                    setIsModalVisible(false);
                }}>
                <div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="stock">
                            New Product Name
                        </label>
                        <Input value={editedProductName} className="form-control mb-1" id="stock" required onChange={(evt) => { setEditedProductName(evt.target.value) }}/>
                        <label className="form-label" htmlFor="stock">
                            New Actual Price
                        </label>
                        <Input value={editedActualPrice} className="form-control mb-1" type="number" id="stock" required onChange={(evt) => { setEditedActualPrice(evt.target.value) }}/>
                        <label className="form-label" htmlFor="stock">
                            New Discount Price
                        </label>
                        <Input value={editedPrice} className="form-control mb-1" type="number" id="stock" required onChange={(evt) => { setEditedPrice(evt.target.value) }}/>
                        <label className="form-label" htmlFor="stock">
                            New Description
                        </label>
                        <Input value={editedDescription} className="form-control mb-1" id="stock" required onChange={(evt) => { setEditedDescription(evt.target.value) }}/>
                        <label className="form-label" htmlFor="stock">
                            New Size
                        </label>
                        <Input value={editedSize} className="form-control mb-1" id="stock" required onChange={(evt) => { setEditedSize(evt.target.value) }}/>
                        <label className="form-label" htmlFor="stock">
                            New Disclaimer
                        </label>
                        <Input value={editedDisclaimer} className="form-control mb-1" id="stock" required onChange={(evt) => { setEditedDisclaimer(evt.target.value) }}/>
                        <label className="form-label" htmlFor="stock">
                            New Storage Tips
                        </label>
                        <Input value={editedStorageTips} className="form-control mb-1" id="stock" required onChange={(evt) => { setEditedStroageTips(evt.target.value) }}/>
                        <label className="form-label" htmlFor="stock">
                            New Origin
                        </label>
                        <Input value={editedCoutryOfOrigin} className="form-control mb-1" id="stock" required onChange={(evt) => { setEditedCountryOfOrigin(evt.target.value) }}/>
                        <label className="form-label" htmlFor="stock">
                            Set Category
                        </label>
                        <Dropdown overlay={category} trigger={["click"]}>
                            <a
                                className="form-control"
                                onClick={(e) => e.preventDefault()}
                            >
                            {editedCategory}
                            </a>
                        </Dropdown>
                        <label className="form-label" htmlFor="stock">
                            Set Availability
                        </label>
                        <Dropdown overlay={menu} trigger={["click"]}>
                            <a
                                className="form-control"
                                onClick={(e) => e.preventDefault()}
                            >
                            {editedAvailability}
                            </a>
                        </Dropdown>
                        <label className="form-label" htmlFor="stock">
                            Set Unit
                        </label>
                        <Dropdown overlay={unit} trigger={["click"]}>
                            <a
                                className="form-control"
                                onClick={(e) => e.preventDefault()}
                            >
                            {editedUnit}
                            </a>
                        </Dropdown>
                    </div>
                </div>
            </Modal>
            <Modal
                visible={isAddToWasteModalVisible}
                okText="Save"
                closable={false}
                cancelText="Close"
                onOk={() => {
                    productEdit();
                    //downloadPdfDocument("test");
                }}
                onCancel={() => {
                    setIsAddToWasteModalVisible(false);
                }}>
                <div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="stock">
                            Enter Waste Quantity
                        </label>
                        <Input value={selectedWasteQuantity} className="form-control mb-1" type="number" id="stock" required onChange={(evt) => { setSelectedWasteQuantity(evt.target.value) }}/>
                    </div>
                </div>
            </Modal>
            <Modal
                visible={isEditQuantityVisible}
                okText="Save"
                closable={false}
                cancelText="Close"
                onOk={() => {
                    EditProductQuantity()
                    //downloadPdfDocument("test");
                }}
                onCancel={() => {
                    setIsEditQuantityVisible(false);
                }}>
                <div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="stock">
                            Enter Set Quantity
                        </label>
                        <Input value={selectedEditQuantity} className="form-control mb-1" type="number" id="stock" required onChange={(evt) => { setSelectedEditQuantity(evt.target.value) }}/>
                    </div>
                </div>
            </Modal>
        </Space>
    )
}

export default ProductList;