import { Modal, Rate, Space, Table, Typography, AutoComplete, Input, Alert } from "antd";
import Avatar from "antd/es/avatar/avatar";
import { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { EditBillStatus, EditBillStatusPacked, EditDeliveryBoy, EditOrder, EditProduct, SetOrderRefund, addRefunds, getAcceptedOrders, getCustomers, getDeliveryUsers, getInventory, getOrders, getOrdersByCustomer, getOrdersByID, getOrdersByStatus, getOrdersDelivered, getPlacedOrders, getTodaysAcceptedOrders, getTodaysOrders } from "../../API";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useReactToPrint } from 'react-to-print';


function CustomerOrders() {
    //var productListSelected = [];
    const [loading, setLoading] = useState(false);
    const [datasource, setDatasource] = useState([]);
    const [datasourceAccepted, setDatasourceAccepted] = useState([]);
    const [deliveryBoys, setDeliveryBoys] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isDeliveredModalVisible, setIsDeliveredModalVisible] = useState(false);
    const [selectedCustomerName, setSelectedCustomerName] = useState('');
    const [selectedCustomerMobile, setSelectedCustomerMobile] = useState('');
    const [selectedBill, setSelectedBill] = useState('');
    const [selectedBillID, setSelectedBillID] = useState('');
    const [selectedBillDate, setSelectedBillDate] = useState('');
    const [selectedTotalAmount, setSelectedTotalAmount] = useState('');
    const [selectedAdvancePayment, setSelectedAdvancePayment] = useState('');
    const [selectedAddress, setSelectedAddress] = useState('');
    const [selectedDeliveryCharges, setSelectedDeliveryCharges] = useState('');
    const [selectedProductList, setSelectedProductList] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [inputValueCustomer, setInputValueCustomer] = useState('');
    const [selectedCustomerID, setSelectedCustomerID] = useState("");
    const [selectedCustomerNameSearch, setSelectedCustomerNameSearch] = useState("");
    const [searchedBillID, setSearchedBillID] = useState('');
    const [editProductBool, setEditProductBool] = useState(false);
    const [editID, setEditID] = useState(false);
    const [editQuantity, setEditQuantity] = useState(false);
    const [editPrice, setEditPrice] = useState(false);
    const [showDeliveryBoysDialog, setShowDeliveryBoysDialog] = useState(false);
    const [selectedDeliveryBoyBillID, setSelectedDeliveryBoyBillID] = useState('');
    const [selectedDeliveryBoyID, setSelectedDeliveryBoyID] = useState('');
    const [selectedDeliveryBoyName, setSelectedDeliveryBoyName] = useState('');
    const [selectedDeliveryBoyMobile, setSelectedDeliveryBoyMobile] = useState('');
    const [selectedDeliveryTimeSlot, setSelectedDeliveryTimeSlot] = useState('');
    const [packedOrders, setPackedOrders] = useState([]);

    const [selectedRefundUserID, setSelectedUserRefundID] = useState('');
    const [selectedRefundUserName, setSelectedUserRefundName] = useState('');
    const [selectedRefundUserMobile, setSelectedUserRefundMobile] = useState('');
    const [selectedRefundOrderID, setSelectedUserOrderID] = useState('');
    const [selectedRefundOrderAmount, setSelectedRefundOrderAmount] = useState(0);
    const [selectedRefundPaymentID, setSelectedUserPaymentID] = useState('');
    const [selectedRefundType, setSelectedUserRefundType] = useState('');
    const [showRefundDialog, setShowRefundDialog] = useState(false);

    const [deliveredOrders, setDeliveredOrders] = useState([])

    const [showEditProduct, setShowEditProduct] = useState(true);
    const componentRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        getPlacedOrders("1").then(res => {
            setLoading(false);
            setDatasource(res.Bills)
        });
        getAcceptedOrders("1")
            .then(res => {
                console.log(res);
                setDatasourceAccepted(res.Bills);
            })
        getCustomers("1").then(res => {
            //setLoading(false);
            setCustomers(res.Customers)
        })
        getDeliveryUsers("1").then(res => {
            setDeliveryBoys(res.Users)
        })
        getOrdersByStatus().then(res => {
            setPackedOrders(res.Bills)
        })
        getOrdersDelivered().then(res => {
            setDeliveredOrders(res.Bills)
        })
    }, []);

    const downloadPdfDocument = (downloadFileName) => {
        setShowEditProduct(false);
        setTimeout(() => {
            const input = document.getElementById("testId");
            html2canvas(input)
                .then((canvas) => {
                    const imgData = canvas.toDataURL('image/png');
                    const pdf = new jsPDF();
                    // if (selectedProductList.length >= 20) {
                    //     //pdf.addPage(2)
                    //     //pdf.addJS(input)
                    //     pdf.addImage(imgData, 'JPEG', 0, 0, 45, imgData.length*0.00034);
                    // } else {
                    //     pdf.addImage(imgData, 'JPEG', 0, 0, 80, 200);
                    // }
                    pdf.addImage(imgData, 'JPEG', 0, 0, 40, imgData.length*0.00034);
                    //pdf.addImage(imgData, 'JPEG', 0, 0, 120, imgData.length*0.0009);
                    //pdf.addImage(imgData, 'JPEG', 0, 0,);
                    pdf.save(`${downloadFileName}.pdf`);
                    setShowEditProduct(true);
                    //console.log(imgData.length*0.001)
                    //pdf.addImage()
                })
        }, 500)
    }

    const getTodaysOrdersStatus = () => {
        let newDate = new Date()
        let date = newDate.getDate();
        if (date < 10) {
            date = "0" + date;
        } else {

        }
        let month = newDate.getMonth() + 1;
        if (month < 10) {
            month = "0" + month;
        } else {

        }
        let year = newDate.getFullYear();
        let finaldate = date + "-" + month + "-" + year;
        getTodaysOrders(finaldate)
            .then(res => {
                setDatasource(res.Bills);
            })
        getTodaysAcceptedOrders(finaldate)
            .then(res => {
                setDatasourceAccepted(res.Bills);
            })
    }

    const getAllOrders = () => {
        // getOrders("1").then(res => {
        //     setLoading(false);
        //     setDatasource(res.Bills);
        //     setInputValueCustomer("");
        //     setSearchedBillID("");
        //     getAllAcceptedOrders();
        // });
        setInputValueCustomer("");
        setSearchedBillID("");
        getAllAcceptedOrders();
        setLoading(true);
        getPlacedOrders("1").then(res => {
            setLoading(false);
            setDatasource(res.Bills)
        });
        getAcceptedOrders("1")
            .then(res => {
                console.log(res);
                setDatasourceAccepted(res.Bills);
            })
        getCustomers("1").then(res => {
            //setLoading(false);
            setCustomers(res.Customers)
        })
        getDeliveryUsers("1").then(res => {
            setDeliveryBoys(res.Users)
        })
        getOrdersByStatus().then(res => {
            setPackedOrders(res.Bills)
        })
        getOrdersDelivered().then(res => {
            setDeliveredOrders(res.Bills)
        })
    }

    const getAllAcceptedOrders = () => {
        getAcceptedOrders("1")
            .then(res => {
                console.log(res);
                setDatasourceAccepted(res.Bills);
            })
    }

    const getBillByBillID = (billid) => {
        getOrdersByID(billid).then(res => {
            setLoading(false);
            setDatasource(res.Bills);
            setInputValueCustomer("");
        });
    }

    const searchBillsByCustomer = (customer_mobile) => {
        getOrdersByCustomer(customer_mobile).then(res => {
            setLoading(false);
            setDatasource(res.Bills)
        });
    }

    const acceptOrder = (BillId) => {
        EditBillStatus(BillId)
            .then(res => {
                alert("Order " + BillId + " Accepted.");
                getAllOrders();
            })
    }

    const packOrder = (BillId) => {
        EditBillStatusPacked(BillId)
            .then(res => {
                alert("Order " + BillId + " Packed.");
                getAllOrders();
            })
    }

    const editOrder = (id, qty, price) => {
        let tempProductList = selectedProductList;
        for (let i = 0; i < tempProductList.length; i++) {
            if (tempProductList[i].id === id) {
                tempProductList[i].qty = qty;
                tempProductList[i].price = price
            } else {

            }
        }
        setSelectedProductList(tempProductList);
        calculateTotal();
    }

    const calculateTotal = () => {
        let tempTotalAmount = 0;
        for (let i = 0; i < selectedProductList.length; i++) {
            tempTotalAmount = tempTotalAmount + (parseFloat(selectedProductList[i].price) * parseFloat(selectedProductList[i].qty));
        }
        setEditProductBool(false)
        setSelectedTotalAmount(tempTotalAmount);
        editOrderMethod(tempTotalAmount);
    }

    const editOrderMethod = (tempTotalAmount) => {
        console.log(JSON.stringify(selectedProductList));
        EditOrder(selectedBill, JSON.stringify(selectedProductList), tempTotalAmount)
            .then(res => {
                alert("Order " + selectedBillID + " Edited Successfully.");
                getPlacedOrders("1").then(res => {
                    setLoading(false);
                    setDatasource(res.Bills)
                });
                getAcceptedOrders("1")
                    .then(res => {
                        console.log(res);
                        setDatasourceAccepted(res.Bills);
                    })
                getCustomers("1").then(res => {
                    //setLoading(false);
                    setCustomers(res.Customers)
                })
            })
    }

    const setDeliveryBoy = () => {
        EditDeliveryBoy(selectedDeliveryBoyBillID, selectedDeliveryBoyID, selectedDeliveryBoyName, selectedDeliveryBoyMobile)
            .then(res => {
                getPlacedOrders("1").then(res => {
                    setLoading(false);
                    setDatasource(res.Bills)
                });
                getAcceptedOrders("1")
                    .then(res => {
                        console.log(res);
                        setDatasourceAccepted(res.Bills);
                    })
                getCustomers("1").then(res => {
                    //setLoading(false);
                    setCustomers(res.Customers)
                })
                getDeliveryUsers("1").then(res => {
                    setDeliveryBoys(res.Users)
                })
                getOrdersByStatus().then(res => {
                    setPackedOrders(res.Bills)
                })
                getOrdersDelivered().then(res => {
                    setDeliveredOrders(res.Bills)
                })
                setShowDeliveryBoysDialog(false);
            })
    }

    const addToRefund = () => {
        if (selectedRefundOrderAmount === "" || selectedRefundOrderAmount === 0 || selectedRefundOrderAmount === "0") {
            alert("Refund Amount can not be empty.")
        } else {
            addRefunds(selectedRefundUserID, selectedRefundUserName, selectedRefundUserMobile, selectedRefundOrderID, selectedRefundOrderAmount, selectedRefundPaymentID, selectedRefundType)
                .then(res => {
                    SetOrderRefund(selectedRefundOrderID)
                        .then(res => {
                            getPlacedOrders("1").then(res => {
                                setLoading(false);
                                setDatasource(res.Bills)
                            });
                            getAcceptedOrders("1")
                                .then(res => {
                                    console.log(res);
                                    setDatasourceAccepted(res.Bills);
                                })
                            getCustomers("1").then(res => {
                                //setLoading(false);
                                setCustomers(res.Customers)
                            })
                            getDeliveryUsers("1").then(res => {
                                setDeliveryBoys(res.Users)
                            })
                            setShowRefundDialog(false)
                            alert("Refund Successfull.")
                        })

                })
        }
    }

    const setEditProduct = () => {
        //setShowEditProduct(false);
        downloadPdfDocument("#" + selectedBillID + "_Bill_PDF")
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <Space size={20} direction="vertical">
            <Typography.Title level={4}>
                Orders
            </Typography.Title>
            {/* <Link to="/addorders" className="btn btn-danger" type="submit">
                Add Orders
            </Link> */}
            <button className="btn btn-danger" onClick={() => getAllOrders()}>Clear Search</button>
            <button className="btn btn-danger" onClick={() => getTodaysOrdersStatus()}>Show Todays Orders</button>
            <div className="row">
                <div className="mb-3">
                    <label className="form-label" htmlFor="purchasedfrom">
                        Search By Customer Name
                    </label>
                    <AutoComplete
                        className="form-control"
                        //value={inputValueCustomer}
                        options={customers}
                        autoFocus={true}
                        style={{ width: 200 }}
                        filterOption={(inputValue, option) =>
                            option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                        onSelect={(value, data) => {
                            setInputValueCustomer(value);
                            setSelectedCustomerID(data.id);
                            searchBillsByCustomer(data.mobile);
                        }}
                        onChange={() => {
                            setInputValueCustomer("");
                        }}
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label" htmlFor="purchasedfrom">
                        Search By Bill ID
                    </label>
                    <AutoComplete
                        className="form-control"
                        //value={searchedBillID}
                        options={datasource}
                        autoFocus={true}
                        style={{ width: 200 }}
                        filterOption={(inputValue, option) =>
                            option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                        onSelect={(value, data) => {
                            setSearchedBillID(value);
                            //setSelectedCustomerID(data.id);
                            getBillByBillID(data.billid);
                        }}
                        onChange={() => {
                            setSearchedBillID("");
                        }}
                    />
                </div>
            </div>
            <label className="form-label" htmlFor="purchasedfrom">
                Accepted Orders
            </label>
            <div>
                <Table
                    columns={[
                        {
                            title: "Bill ID",
                            dataIndex: "billid"
                        },
                        {
                            title: "Bill Date",
                            dataIndex: "date"
                        },
                        {
                            title: "Order Status",
                            dataIndex: "order_status"
                        },
                        {
                            title: "Total",
                            dataIndex: "total_amount",
                            render: (value) => <span>₹{value}</span>
                        },
                        {
                            title: "Advance",
                            dataIndex: "advance_payment_amount",
                            render: (value, data) => <span>₹{data.order_type === "ONLINE PAYMENT" ? data.total_amount : value}</span>
                        },
                        {
                            title: "Customer Name",
                            dataIndex: "customer_name"
                        },
                        {
                            title: "Customer Mobile",
                            dataIndex: "customer_mobile"
                        },
                        // {
                        //     title: "Delivery Boy",
                        //     dataIndex: "delivery_boy_name"
                        // },
                        // {
                        //     title: "Delivery Boy Mobile",
                        //     dataIndex: "delivery_boy_mobile"
                        // },
                        {
                            title: "Order Type",
                            dataIndex: "order_type"
                        },
                        {
                            title: "Payment ID",
                            dataIndex: "payment_id"
                        },
                        {
                            title: "",
                            dataIndex: "billdata",
                            render: (value, data) => <button className="btn btn-danger" onClick={() => {
                                setSelectedCustomerName(data.customer_name);
                                setSelectedCustomerMobile(data.customer_mobile);
                                setSelectedBill(data.id);
                                setSelectedBillID(data.billid);
                                setSelectedBillDate(data.date);
                                setSelectedTotalAmount(data.total_amount);
                                setSelectedAddress(data.address)
                                setSelectedDeliveryCharges(data.delivery_charges)
                                if (data.order_type === "ONLINE PAYMENT") {
                                    setSelectedAdvancePayment(data.total_amount)
                                } else {
                                    setSelectedAdvancePayment(data.advance_payment_amount)
                                }
                                let productListSelected = [];
                                productListSelected = [...JSON.parse(data.billdata)];
                                setSelectedProductList(productListSelected);
                                //console.log(productListSelected);
                                //openDialogBill();
                                setIsModalVisible(true);
                            }}>View Details</button>
                        },
                        // {
                        //     title: "",
                        //     dataIndex: "billdata",
                        //     render: (value, data) => 
                        //     <button className="btn btn-danger" onClick={() => {
                        //         packOrder(data.billid);
                        //     }}>Set Order Packed</button>
                        // },
                        {
                            title: "",
                            dataIndex: "billdata",
                            render: (value, data) =>
                                <button className="btn btn-danger" onClick={() => {
                                    setSelectedDeliveryBoyBillID(data.billid)
                                    setShowDeliveryBoysDialog(true)
                                }}>Set Delivery Boy</button>
                        },
                    ]}
                    loading={loading}
                    dataSource={datasourceAccepted}
                    pagination={{
                        pageSize: 5
                    }}>

                </Table>
            </div>
            <label className="form-label" htmlFor="purchasedfrom">
                Orders Received
            </label>
            <div>
                <Table
                    columns={[
                        {
                            title: "Bill ID",
                            dataIndex: "billid"
                        },
                        {
                            title: "Order Status",
                            dataIndex: "order_status"
                        },
                        {
                            title: "Total",
                            dataIndex: "total_amount",
                            render: (value) => <span>₹{value}</span>
                        },
                        {
                            title: "Advance",
                            dataIndex: "advance_payment_amount",
                            render: (value, data) => <span>₹{data.order_type === "ONLINE PAYMENT" ? data.total_amount : value}</span>
                        },
                        {
                            title: "Delivery Charges",
                            dataIndex: "delivery_charges",
                            render: (value) => <span>₹{value}</span>
                        },
                        {
                            title: "Customer Name",
                            dataIndex: "customer_name"
                        },
                        {
                            title: "Customer Mobile",
                            dataIndex: "customer_mobile"
                        },
                        {
                            title: "Order Type",
                            dataIndex: "order_type"
                        },
                        {
                            title: "Payment ID",
                            dataIndex: "payment_id"
                        },
                        {
                            title: "",
                            dataIndex: "billdata",
                            render: (value, data) => <button className="btn btn-danger" onClick={() => {
                                setSelectedCustomerName(data.customer_name);
                                setSelectedCustomerMobile(data.customer_mobile);
                                setSelectedBill(data.id);
                                setSelectedBillID(data.billid);
                                setSelectedBillDate(data.date);
                                setSelectedTotalAmount(data.total_amount);
                                setSelectedAddress(data.address)
                                setSelectedDeliveryCharges(data.delivery_charges)
                                setSelectedDeliveryTimeSlot(data.delivery_time_slot)
                                if (data.order_type === "ONLINE PAYMENT") {
                                    setSelectedAdvancePayment(data.total_amount)
                                } else {
                                    setSelectedAdvancePayment(data.advance_payment_amount)
                                }

                                let productListSelected = [];
                                productListSelected = [...JSON.parse(data.billdata)];
                                setSelectedProductList(productListSelected);
                                //console.log(productListSelected);
                                //openDialogBill();
                                setIsModalVisible(true);
                            }}>View Details</button>
                        },
                        {
                            title: "",
                            dataIndex: "billdata",
                            render: (value, data) =>
                                <button className="btn btn-danger" onClick={() => {
                                    acceptOrder(data.billid);
                                }}>Accept Order</button>
                        },
                        {
                            title: "",
                            dataIndex: "billdata",
                            render: (value, data) => <button className="btn btn-danger" onClick={() => {
                                //addRefunds(data.customer_id, data.customer_name, data.customer_mobile, data.billid)
                                setSelectedUserRefundID(data.customer_id)
                                setSelectedUserRefundName(data.customer_name)
                                setSelectedUserRefundMobile(data.customer_mobile)
                                setSelectedUserOrderID(data.billid)
                                setSelectedRefundOrderAmount(parseInt(data.total_amount) + parseInt(data.delivery_charges))
                                setSelectedUserPaymentID(data.payment_id)
                                setSelectedUserRefundType("FULL")
                                setShowRefundDialog(true)
                            }}>Refund User</button>
                        }

                    ]}
                    loading={loading}
                    dataSource={datasource}
                    pagination={{
                        pageSize: 5
                    }}>

                </Table>
            </div>
            <label className="form-label" htmlFor="purchasedfrom">
                Packed Orders
            </label>
            <div>
                <Table
                    columns={[
                        {
                            title: "Bill ID",
                            dataIndex: "billid"
                        },
                        {
                            title: "Order Status",
                            dataIndex: "order_status"
                        },
                        {
                            title: "Total",
                            dataIndex: "total_amount",
                            render: (value) => <span>₹{value}</span>
                        },
                        {
                            title: "Advance",
                            dataIndex: "advance_payment_amount",
                            render: (value, data) => <span>₹{data.order_type === "ONLINE PAYMENT" ? data.total_amount : value}</span>
                        },
                        {
                            title: "Customer Name",
                            dataIndex: "customer_name"
                        },
                        {
                            title: "Customer Mobile",
                            dataIndex: "customer_mobile"
                        },
                        {
                            title: "Delivery Boy Name",
                            dataIndex: "delivery_boy_name"
                        },
                        {
                            title: "Delivery Boy Mobile",
                            dataIndex: "delivery_boy_mobile"
                        },
                        {
                            title: "Pickup Boy Name",
                            dataIndex: "pickup_person"
                        },
                        {
                            title: "Pickup Boy Vehicle",
                            dataIndex: "pickup_vehicle"
                        },
                        {
                            title: "Pickup Boy Mobile",
                            dataIndex: "pickup_mobile"
                        },
                    ]}
                    loading={loading}
                    dataSource={packedOrders}
                    pagination={{
                        pageSize: 5
                    }}>

                </Table>
            </div>
            <label className="form-label" htmlFor="purchasedfrom">
                Delivered Orders
            </label>
            <div>
                <Table
                    columns={[
                        {
                            title: "Bill ID",
                            dataIndex: "billid"
                        },
                        {
                            title: "Order Status",
                            dataIndex: "order_status"
                        },
                        {
                            title: "Total",
                            dataIndex: "total_amount",
                            render: (value) => <span>₹{value}</span>
                        },
                        {
                            title: "Advance",
                            dataIndex: "advance_payment_amount",
                            render: (value, data) => <span>₹{data.order_type === "ONLINE PAYMENT" ? data.total_amount : value}</span>
                        },
                        {
                            title: "Customer Name",
                            dataIndex: "customer_name"
                        },
                        {
                            title: "Customer Mobile",
                            dataIndex: "customer_mobile"
                        },
                        {
                            title: "Delivery Boy Name",
                            dataIndex: "delivery_boy_name"
                        },
                        {
                            title: "Delivery Boy Mobile",
                            dataIndex: "delivery_boy_mobile"
                        },
                        {
                            title: "Pickup Boy Name",
                            dataIndex: "pickup_person"
                        },
                        {
                            title: "Pickup Boy Vehicle",
                            dataIndex: "pickup_vehicle"
                        },
                        {
                            title: "Pickup Boy Mobile",
                            dataIndex: "pickup_mobile"
                        },
                        {
                            title: "",
                            dataIndex: "billdata",
                            render: (value, data) => <button className="btn btn-danger" onClick={() => {
                                setSelectedCustomerName(data.customer_name);
                                setSelectedCustomerMobile(data.customer_mobile);
                                setSelectedBill(data.id);
                                setSelectedBillID(data.billid);
                                setSelectedBillDate(data.date);
                                setSelectedTotalAmount(data.total_amount);
                                setSelectedAddress(data.address)
                                setSelectedDeliveryCharges(data.delivery_charges)
                                if (data.pending_amount_payment_stat === "PAID WITH CASH") {
                                    setSelectedAdvancePayment(data.total_amount)
                                } else {
                                    setSelectedAdvancePayment(data.advance_payment_amount)
                                }
                                let productListSelected = [];
                                productListSelected = [...JSON.parse(data.billdata)];
                                setSelectedProductList(productListSelected);
                                //console.log(productListSelected);
                                //openDialogBill();
                                setIsDeliveredModalVisible(true);
                            }}>View Details</button>
                        },
                    ]}
                    loading={loading}
                    dataSource={deliveredOrders}
                    pagination={{
                        pageSize: 5
                    }}>

                </Table>
            </div>
            <Modal
                visible={isDeliveredModalVisible}
                okText="Print"
                closable={false}
                cancelText="Close"
                // onOk={() => {
                //     //downloadPdfDocument("#"+selectedBillID+"_Bill_PDF");
                //     setEditProduct()
                // }}
                onOk={() => {
                    setShowEditProduct(false)
                    setTimeout(() => {
                        handlePrint()
                    }, 500)
                    setTimeout(() => {
                        setShowEditProduct(true)
                    }, 500)
                }}
                onCancel={() => {
                    setSelectedAdvancePayment('')
                    setIsDeliveredModalVisible(false);
                }}>
                <div ref={componentRef} id="testId" class="container mt-10 mb-7">
                    <div class="row justify-content-center">
                        <div class="col-lg-15 col-xl-15">
                            <div class="card">
                                <div class="card-body p-5">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="text-muted mb-2">Customer Name</div>
                                            <strong>{selectedCustomerName}</strong>
                                        </div>
                                        <div class="col-md-6 text-md-end">
                                            <div class="text-muted mb-2">Customer Mobile</div>
                                            <strong>{selectedCustomerMobile}</strong>
                                        </div>
                                    </div>

                                    <div class="border-top border-gray-200 pt-4 mt-4 mb-5">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="text-muted mb-2">Order No.</div>
                                                <strong>#{selectedBillID}</strong>
                                            </div>
                                            <div class="col-md-6 text-md-end">
                                                <div class="text-muted mb-2">Payment Date</div>
                                                <strong>{selectedBillDate}</strong>
                                            </div>
                                        </div>
                                        <div class="border-top border-gray-200 pt-4 mt-4 mb-1"></div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="text-muted mb-2">Address.</div>
                                                <strong>{selectedAddress}</strong>
                                            </div>
                                            <div class="col-md-6 text-md-end">
                                                <div class="text-muted mb-2">Selected Time Slot</div>
                                                <strong>{selectedDeliveryTimeSlot}</strong>
                                            </div>
                                        </div>
                                    </div>
                                    {showEditProduct ?
                                        <div>
                                            <Table
                                                columns={[
                                                    {
                                                        title: "Product",
                                                        dataIndex: "title"
                                                    },
                                                    {
                                                        title: "Weight",
                                                        dataIndex: "qty"
                                                    },
                                                    {
                                                        title: "Rate",
                                                        dataIndex: "price",
                                                        render: (value) => <span>₹{value}</span>
                                                    },
                                                    {
                                                        title: "Cost",
                                                        dataIndex: "price",
                                                        render: (value, data) => <span>₹{(parseFloat(data.price) * parseFloat(data.qty))}</span>
                                                    },
                                                    {
                                                        title: "",
                                                        dataIndex: "price",
                                                        render: (value, data) =>
                                                            <button className="btn btn-danger" onClick={() => {
                                                                //acceptOrder(data.billid);
                                                                setEditProductBool(true)
                                                                setEditID(data.id);
                                                                setEditQuantity(data.qty);
                                                                setEditPrice(data.price);
                                                            }}>Edit Product</button>
                                                    },
                                                ]}
                                                loading={loading}
                                                dataSource={selectedProductList}
                                                pagination={false}>
                                            </Table>
                                        </div> :
                                        <div>
                                            <Table
                                                columns={[
                                                    {
                                                        title: "Product",
                                                        dataIndex: "title"
                                                    },
                                                    {
                                                        title: "Weight",
                                                        dataIndex: "qty"
                                                    },
                                                    {
                                                        title: "Rate",
                                                        dataIndex: "price",
                                                        render: (value) => <span>₹{value}</span>
                                                    },
                                                    {
                                                        title: "Cost",
                                                        dataIndex: "price",
                                                        render: (value, data) => <span>₹{(parseFloat(data.price) * parseFloat(data.qty))}</span>
                                                    },
                                                ]}
                                                loading={loading}
                                                dataSource={selectedProductList}
                                                pagination={false}>
                                            </Table>
                                        </div>}

                                    <div class="mt-5">
                                        <div class="d-flex justify-content-end mt-3">
                                            <h5 class="me-3">Delivery Charges:</h5>
                                            <h5>₹{selectedDeliveryCharges}</h5>
                                        </div>
                                        {selectedAdvancePayment === '' ? null :
                                            <div>
                                                {/* <div class="d-flex justify-content-end mt-3">
                                    <h5 class="me-3">Advance Payment:</h5>
                                    <h5>₹{selectedAdvancePayment}</h5>
                                </div> */}
                                                <div class="d-flex justify-content-end mt-3">
                                                    <h5 class="me-3">Pending Payment:</h5>
                                                    <h5>₹0</h5>
                                                </div>
                                            </div>}
                                        <div class="d-flex justify-content-end mt-3">
                                            <h5 class="me-3">Total:</h5>
                                            <h5>₹{selectedTotalAmount}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                visible={isModalVisible}
                okText="Print"
                closable={false}
                cancelText="Close"
                // onOk={() => {
                //     //downloadPdfDocument("#"+selectedBillID+"_Bill_PDF");
                //     setEditProduct()
                // }}
                onOk={() => {
                    setShowEditProduct(false)
                    setTimeout(() => {
                        handlePrint()
                    }, 500)
                    setTimeout(() => {
                        setShowEditProduct(true)
                    }, 500)
                }}
                onCancel={() => {
                    setSelectedAdvancePayment('')
                    setIsModalVisible(false);
                }}>
                <div ref={componentRef} id="testId" class="container mt-10 mb-7">
                    <div class="row justify-content-center">
                        <div class="col-lg-15 col-xl-15">
                            <div class="card">
                                <div class="card-body p-5">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="text-muted mb-2">Customer Name</div>
                                            <strong>{selectedCustomerName}</strong>
                                        </div>
                                        <div class="col-md-6 text-md-end">
                                            <div class="text-muted mb-2">Customer Mobile</div>
                                            <strong>{selectedCustomerMobile}</strong>
                                        </div>
                                    </div>

                                    <div class="border-top border-gray-200 pt-4 mt-4 mb-5">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="text-muted mb-2">Order No.</div>
                                                <strong>#{selectedBillID}</strong>
                                            </div>
                                            <div class="col-md-6 text-md-end">
                                                <div class="text-muted mb-2">Payment Date</div>
                                                <strong>{selectedBillDate}</strong>
                                            </div>
                                        </div>
                                        <div class="border-top border-gray-200 pt-4 mt-4 mb-1"></div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="text-muted mb-2">Address.</div>
                                                <strong>{selectedAddress}</strong>
                                            </div>
                                            <div class="col-md-6 text-md-end">
                                                <div class="text-muted mb-2">Selected Time Slot</div>
                                                <strong>{selectedDeliveryTimeSlot}</strong>
                                            </div>
                                        </div>
                                    </div>
                                    {showEditProduct ?
                                        <div>
                                            <Table
                                                columns={[
                                                    {
                                                        title: "Product",
                                                        dataIndex: "title"
                                                    },
                                                    {
                                                        title: "Weight",
                                                        dataIndex: "qty"
                                                    },
                                                    {
                                                        title: "Rate",
                                                        dataIndex: "price",
                                                        render: (value) => <span>₹{value}</span>
                                                    },
                                                    {
                                                        title: "Cost",
                                                        dataIndex: "price",
                                                        render: (value, data) => <span>₹{(parseFloat(data.price) * parseFloat(data.qty))}</span>
                                                    },
                                                    {
                                                        title: "",
                                                        dataIndex: "price",
                                                        render: (value, data) =>
                                                            <button className="btn btn-danger" onClick={() => {
                                                                //acceptOrder(data.billid);
                                                                setEditProductBool(true)
                                                                setEditID(data.id);
                                                                setEditQuantity(data.qty);
                                                                setEditPrice(data.price);
                                                            }}>Edit Product</button>
                                                    },
                                                ]}
                                                loading={loading}
                                                dataSource={selectedProductList}
                                                pagination={false}>
                                            </Table>
                                        </div> :
                                        <div>
                                            <Table
                                                columns={[
                                                    {
                                                        title: "Product",
                                                        dataIndex: "title"
                                                    },
                                                    {
                                                        title: "Weight",
                                                        dataIndex: "qty"
                                                    },
                                                    {
                                                        title: "Rate",
                                                        dataIndex: "price",
                                                        render: (value) => <span>₹{value}</span>
                                                    },
                                                    {
                                                        title: "Cost",
                                                        dataIndex: "price",
                                                        render: (value, data) => <span>₹{(parseFloat(data.price) * parseFloat(data.qty))}</span>
                                                    },
                                                ]}
                                                loading={loading}
                                                dataSource={selectedProductList}
                                                pagination={false}>
                                            </Table>
                                        </div>}

                                    <div class="mt-5">
                                        <div class="d-flex justify-content-end mt-3">
                                            <h5 class="me-3">Delivery Charges:</h5>
                                            <h5>₹{selectedDeliveryCharges}</h5>
                                        </div>
                                        {selectedAdvancePayment === '' ? null :
                                            <div>
                                                <div class="d-flex justify-content-end mt-3">
                                                    <h5 class="me-3">Advance Payment:</h5>
                                                    <h5>₹{selectedAdvancePayment}</h5>
                                                </div>
                                                <div class="d-flex justify-content-end mt-3">
                                                    <h5 class="me-3">Pending Payment:</h5>
                                                    <h5>₹{parseInt(selectedTotalAmount) - parseInt(selectedAdvancePayment)}</h5>
                                                </div>
                                            </div>}
                                        <div class="d-flex justify-content-end mt-3">
                                            <h5 class="me-3">Total:</h5>
                                            <h5>₹{selectedTotalAmount}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                visible={editProductBool}
                okText="Save"
                closable={false}
                cancelText="Close"
                onOk={() => {
                    editOrder(editID, editQuantity, editPrice)
                    //downloadPdfDocument("test");
                }}
                onCancel={() => {
                    setEditProductBool(false);
                }}>
                <div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="stock">
                            Enter Waste Quantity
                        </label>
                        <Input value={editQuantity} className="form-control mb-1" type="number" id="stock" required onChange={(evt) => { setEditQuantity(evt.target.value) }} />
                    </div>
                </div>
            </Modal>
            <Modal
                visible={showRefundDialog}
                okText="Save"
                closable={false}
                cancelText="Close"
                onOk={() => {
                    addToRefund()
                }}
                onCancel={() => {
                    setShowRefundDialog(false);
                }}>
                <div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="stock">
                            Enter Refund Amount
                        </label>
                        <Input value={selectedRefundOrderAmount} className="form-control mb-1" type="number" id="stock" required onChange={(evt) => {
                            setSelectedRefundOrderAmount(evt.target.value)
                            if (parseInt(evt.target.value) === selectedRefundOrderAmount) {
                                setSelectedUserRefundType("FULL")
                            } else {
                                setSelectedUserRefundType("PARTIAL")
                            }
                        }} />
                    </div>
                </div>
            </Modal>
            <Modal
                visible={showDeliveryBoysDialog}
                okText="Save"
                closable={false}
                cancelText="Close"
                onOk={() => {
                    //editOrder(editID, editQuantity, editPrice)
                    //downloadPdfDocument("test");
                    setDeliveryBoy()
                }}
                onCancel={() => {
                    setShowDeliveryBoysDialog(false);
                }}>
                <div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="stock">
                            Select Delivery Boy
                        </label>
                        <AutoComplete
                            className="form-control"
                            value={selectedDeliveryBoyName}
                            options={deliveryBoys}
                            autoFocus={true}
                            style={{ width: 200 }}
                            filterOption={(inputValue, option) =>
                                option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                            onSelect={(value, data) => {
                                setSelectedDeliveryBoyName(value)
                                //setSearchedBillID(value);
                                //setSelectedCustomerID(data.id);
                                //getBillByBillID(data.billid);
                                setSelectedDeliveryBoyID(data.id)
                                setSelectedDeliveryBoyName(data.name)
                                setSelectedDeliveryBoyMobile(data.mobile)

                            }}
                            onChange={() => {
                                setSelectedDeliveryBoyName("");
                            }}
                        />
                    </div>
                </div>
            </Modal>
        </Space>
    )
}


export default CustomerOrders;