import React from "react";

const TermsAndConditions = () => {
    return (
        <>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                textAlign: "justify"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 24,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                    }}
                >
                    Terms &amp; Conditions
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                textAlign: "justify"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                &nbsp;
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                textAlign: "justify"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                This document is an electronic record in terms of The Information
                Technology Act, 2000 ("IT Act") and rules thereunder, and is published in
                accordance with the provisions of Rule 3 (1) under Information Technology
                Rules which mandates for publishing of rules and regulations, privacy
                policy and terms of use for access or usage of the Website. This
                electronic record is generated by a computer system and does not require
                any physical or digital signatures.
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                textAlign: "justify"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                The domain name&nbsp;
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                <a href="http://www.Farmeasy.co.in">
                    <span style={{ color: "#262726" }}>www.Farmeasy.co.in</span>
                </a>{" "}
                is an ecommerce portal owned and operated by FarmEasy Natural &amp; Fresh
                (OPC) Pvt Ltd. A company incorporated under the indian companies Act,
                Having its registered office at&nbsp;
                </span>
                <span style={{ fontSize: 21, fontFamily: '"Candara",sans-serif' }}>
                PL No 17B , 17C &amp; 20, V-1133 , Sector - 19 , Vashi , Navi Mumbai ,
                Thane - 400705 , Maharashtra
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                textAlign: "justify"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                &nbsp;
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                textAlign: "justify"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                Your use / access of the Website, its related sites and hyperlinks, shall
                be governed by the terms and conditions, policies, rules of Farm
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                E
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                asy ("Terms") posted on the Website from time to time. These Terms applies
                to all users (whether Registered user or Guest User) of the Website.
                Please read the Terms and conditions very carefully before using the
                Website as your use of the Website is subject to your acceptance of and
                compliance with the Terms. If you do not agree or are not willing to be
                bound by the terms and conditions of this User Agreement and any Rules and
                Policies of Farmeasy, please do not click on the "I Accept" button/check
                box and on the "Continue" button or "Pay Now" button and do not subscribe
                or seek to obtain access to or otherwise use the Website. By subscribing
                to or using the website or any of our services through the Website you
                agree that you have read, understood and are bound by the Terms, Policies,
                directions of Farm
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                E
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                asy regardless of how you subscribe to or use the Website. This Agreement
                comes into effect upon your "Acceptance" of these terms and conditions
                ("Terms") however Farm
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                E
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                asy at its sole discretion reserves its right not to accept a user from
                registering on the Website without assigning any reason thereof.
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                textAlign: "justify"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                &nbsp;
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                textAlign: "justify"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 24,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                    }}
                >
                    DEFINITION
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                textAlign: "justify"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                Agreement" means the terms and conditions as detailed herein including all
                schedules, appendices, annexures, Privacy Policy, Disclaimer and will
                include the references to this Agreement as amended, novated,
                supplemented, varied or replaced from time to time. "You"/ "User" shall
                mean the end user accessing the Website, its contents and using the
                services offered through the Website.
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                textAlign: "justify"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                Farmeasy"/ "we"/ "us"/ "our" shall mean&nbsp;
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                FARMEASY NATURAL AND FRESH (OPC)
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                &nbsp;PRIVATE LIMITED or its affiliates, franchisees, service providers,
                successors and assigns "Site" means the online shopping platform owned and
                operated by&nbsp;
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                FARMEASY NATURAL AND FRESH (OPC)
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                &nbsp;PRIVATE LIMITED, which provides a venue to the users to buy the
                Products listed on Site and Mobile application (iOS and Android)
                "Customer"/"Buyer" shall mean the person or any legal entity who access
                the Website and places an order for and or purchases any products
                /services against the invitation of offer for sale of products / services
                on the Website.
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                textAlign: "justify"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                Acceptance" shall mean your affirmative action in clicking on "Checkout"
                and on the "continue button as provided on the registration page or
                clicking on "Pay Now" button while transacting as User or such other
                actions that implies your acceptance.
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                textAlign: "justify"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                Product" shall mean the Products offered for sale on the website from time
                to time. "Delivery" shall mean the physical delivery of goods under your
                order placed with us. "Price" means the price communicated at the time of
                order and confirmed by us. "Payment Facility " shall mean applicable mode
                of payments made available to User from time to time. "Content" means all
                text, graphics, user interfaces, visual interfaces, photographs,
                trademarks, logos, sounds, music, artwork and computer code collectively.
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                textAlign: "justify"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                By impliedly or expressly accepting this User Agreement, Policies and
                Terms, You also accept and agree to be bound by Rules, Policies and Terms
                of Farmeasy as provided from time to time in its Website and corresponding
                hyperlinks.
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                textAlign: "justify"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 24,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                    }}
                >
                    Registration and Submission of personal details
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                textAlign: "justify"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                You can use the services offered by the Website as a "Registered User". As
                part of the registration process on the Site, Farm
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                E
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                asy may collect certain personally identifiable information about you
                including first and last name, email address, mobile phone number and
                contact details, Postal code, Demographic profile (like your age, gender,
                occupation, education, address etc.) and information about the pages on
                the site you visit/access, the links you click on the site, the number of
                times you access the page and any such browsing information. As a
                Registered User, you are responsible for maintaining the confidentiality
                of your user id and password. You are also responsible for maintaining the
                confidentiality of your email id and mobile number and for restricting
                access to your computer, computer system and computer network, and you are
                responsible for all activities that occur under your user id and password,
                email id and mobile l number as the case may be. You agree to maintain and
                promptly update all data provided by you and to keep it true, accurate,
                current and complete. If you provide any information that is untrue,
                inaccurate, not current or incomplete or we have reasonable grounds to
                suspect that such information is untrue, inaccurate, not current or
                incomplete, or not in accordance with the Terms, we have the right to
                indefinitely suspend or terminate or block access of your user account
                with the Website and refuse to provide you with access to the Website. We
                will collect personally identifiable information about you only as part of
                a voluntary registration process, on-line survey, or contest or any
                combination thereof.
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                textAlign: "justify"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 24,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                    }}
                >
                    User’s Liability
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                textAlign: "justify"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                You agree to comply with all applicable laws in connection with your use
                of the Website, and such further limitations as may be set forth in any
                written or on-screen notice from us. By using the Website you represent
                that you are at least 18 years old. Our website is not directed to
                children under the age of 18. If we become aware that a child and/ or
                customer is under the age of 18 and has registered or using our Website,
                we are entitled to remove his or her account from our system and cancel
                any order placed by him or her. You agree and undertake not to reverse
                engineer, modify, copy, distribute, transmit, display, perform, reproduce,
                publish, license, create derivative works from, transfer, or sell any
                information or software obtained from the Website. Reproduction, copying
                of the content for commercial or non-commercial purposes and unwarranted
                modification of data and information within the content of the Website is
                not permitted. You agree and undertake not to display, upload, modify,
                publish, transmit, update or share any information that:
                </span>
            </p>
            <div
                style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "8.0pt",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif'
                }}
            >
                <ol style={{ marginBottom: "0in", listStyleType: "lower-roman" }}>
                <li
                    style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: "8.0pt",
                    marginLeft: "0in",
                    fontSize: "11.0pt",
                    fontFamily: '"Calibri",sans-serif'
                    }}
                >
                    <span
                    style={{
                        fontFamily: '"Candara",sans-serif',
                        fontSize: "16.0pt",
                        color: "#262726"
                    }}
                    >
                    belongs to another person and to which you do not have any legal right
                    </span>
                </li>
                </ol>
            </div>
            <ol style={{ marginBottom: "0in", marginTop: "0in" }} start={2} type="i">
                <li
                style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: "0in",
                    marginLeft: "0in",
                    fontSize: "11.0pt",
                    fontFamily: '"Calibri",sans-serif',
                    color: "#262726",
                    textAlign: "justify",
                    lineHeight: "normal",
                    background: "white"
                }}
                >
                <span style={{ fontSize: 21, fontFamily: '"Candara",sans-serif' }}>
                    is grossly harmful, harassing, blasphemous, defamatory, obscene,
                    pornographic, invasive of another's privacy, hateful, or racially,
                    ethnically objectionable, disparaging, relating or encouraging money
                    laundering or gambling, or otherwise unlawful in any manner what so
                    ever;
                </span>
                </li>
                <li
                style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: "0in",
                    marginLeft: "0in",
                    fontSize: "11.0pt",
                    fontFamily: '"Calibri",sans-serif',
                    color: "#262726",
                    textAlign: "justify",
                    lineHeight: "normal",
                    background: "white"
                }}
                >
                <span style={{ fontSize: 21, fontFamily: '"Candara",sans-serif' }}>
                    harm minors in any way;
                </span>
                </li>
                <li
                style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: "0in",
                    marginLeft: "0in",
                    fontSize: "11.0pt",
                    fontFamily: '"Calibri",sans-serif',
                    color: "#262726",
                    textAlign: "justify",
                    lineHeight: "normal",
                    background: "white"
                }}
                >
                <span style={{ fontSize: 21, fontFamily: '"Candara",sans-serif' }}>
                    infringes any patent, trademark, copyright or other proprietary rights
                    or third party's trade secrets or rights of publicity or privacy;
                </span>
                </li>
                <li
                style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: "0in",
                    marginLeft: "0in",
                    fontSize: "11.0pt",
                    fontFamily: '"Calibri",sans-serif',
                    color: "#262726",
                    textAlign: "justify",
                    lineHeight: "normal",
                    background: "white"
                }}
                >
                <span style={{ fontSize: 21, fontFamily: '"Candara",sans-serif' }}>
                    violates any law for the time being in force;
                </span>
                </li>
                <li
                style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: "0in",
                    marginLeft: "0in",
                    fontSize: "11.0pt",
                    fontFamily: '"Calibri",sans-serif',
                    color: "#262726",
                    textAlign: "justify",
                    lineHeight: "normal",
                    background: "white"
                }}
                >
                <span style={{ fontSize: 21, fontFamily: '"Candara",sans-serif' }}>
                    impersonate another person contains software viruses or any other
                    computer code, files or programs designed to interrupt, destroy or limit
                    the functionality of any computer resource; or contains any Trojan
                    horses, worms, time bombs, cancelbots, easter eggs or other computer
                    programming routines that may damage, detrimentally interfere with,
                    diminish value of, surreptitiously intercept or expropriate any system,
                    data or personal information.
                </span>
                </li>
                <li
                style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: "0in",
                    marginLeft: "0in",
                    fontSize: "11.0pt",
                    fontFamily: '"Calibri",sans-serif',
                    color: "#262726",
                    textAlign: "justify",
                    lineHeight: "normal",
                    background: "white"
                }}
                >
                <span style={{ fontSize: 21, fontFamily: '"Candara",sans-serif' }}>
                    You, as a Buyer, understand that upon initiating a transaction you are
                    entering into a legally binding and enforceable contract with Farmeasy
                    to purchase the products and /or services from Farmeasy, and pay the
                    transaction price to Farmeasy using appropriate Payment Facility. You,
                    as a Buyer, understand that the all payment facility may not be
                    available in full or in part for certain category of products and for
                    certain locations and hence you may not be entitled to use that Payment
                    Facility in respect of the transactions for those products or locations.
                </span>
                </li>
            </ol>
            <p
                style={{
                marginRight: "0in",
                marginLeft: ".5in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "0in",
                textAlign: "justify",
                lineHeight: "normal",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                &nbsp;
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "0in",
                textAlign: "justify",
                lineHeight: "normal",
                background: "white"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 24,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                    }}
                >
                    Availability of Website and its services:
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "0in",
                textAlign: "justify",
                lineHeight: "normal",
                background: "white"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                    }}
                >
                    &nbsp;
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                This Website is controlled and operated by&nbsp;
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                FARMEASY NATURAL AND FRESH (OPC)
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                &nbsp;PRIVATE LIMITED
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                &nbsp;from India and Farm
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                E
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                asy makes no representation that the materials are appropriate or will be
                available for use in other locations. If you use this web site from
                outside the India, you are entirely responsible for compliance with all
                applicable local laws. Access to Website:
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                We will do our utmost to ensure that availability of the website will be
                uninterrupted and that transmissions will be error-free. However, due to
                the nature of the Internet, this cannot be guaranteed. Also, your access
                to the website may also be occasionally suspended or restricted to allow
                for repairs, maintenance, or the introduction of new facilities or
                services at any time without prior notice. We will attempt to limit the
                frequency and duration of any such suspension or restriction.
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "0in",
                textAlign: "justify",
                lineHeight: "normal",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                &nbsp;
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                textAlign: "justify"
                }}
            >
                <strong>
                <span style={{ fontSize: 24, fontFamily: '"Candara",sans-serif' }}>
                    Permission to access website:
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                textAlign: "justify"
                }}
            >
                <span style={{ fontSize: 21, fontFamily: '"Candara",sans-serif' }}>
                Farm
                </span>
                <span style={{ fontSize: 21, fontFamily: '"Candara",sans-serif' }}>E</span>
                <span style={{ fontSize: 21, fontFamily: '"Candara",sans-serif' }}>
                asy grants you a limited, revocable permission to access and make personal
                use of this Website, but not to download or modify it, its Contents or any
                portion of it, except with express written consent of Farm
                </span>
                <span style={{ fontSize: 21, fontFamily: '"Candara",sans-serif' }}>E</span>
                <span style={{ fontSize: 21, fontFamily: '"Candara",sans-serif' }}>
                asy.; any downloading or copying of account information for the benefit of
                another seller; or any use of data mining, robots, or similar data
                gathering and extraction tools. The use of the Website and its Content
                grants no rights to you in relation to any copyright, designs, trademarks
                and all other intellectual property and material rights relating to the
                Content.
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                textAlign: "justify"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                The Contents including but not limited to the design, structure,
                selection, coordination, expression, "look and feel" and arrangement of
                such Content, contained on the Website are original to Farm
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                E
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                asy and are owned and controlled or licensed by us, and are protected by
                trade dress, copyright, patent and trademark laws, and various other
                intellectual property rights. Except as expressly provided in Terms of
                Use, no part of the Website and no Content may be copied, reproduced,
                republished, uploaded, posted, publicly displayed, encoded, translated,
                transmitted or distributed in any way (including "mirroring") to any other
                computer, server, website or other medium for publication or distribution
                or for any commercial enterprise, without our express prior written
                consent
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                textAlign: "justify"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                &nbsp;
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                margin: "0in",
                textAlign: "justify",
                background: "white"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 24,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                    }}
                >
                    Applicability of Terms:
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                margin: "0in",
                textAlign: "justify",
                background: "white"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                    }}
                >
                    &nbsp;
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                You will be subject to the policies, conditions and Terms of use in force
                at the time that you use the website or that you order goods from us,
                unless any change to those policies or these conditions is required to be
                made by law or government authority (in which case it will apply to orders
                previously placed by you).
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                textAlign: "justify"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 24,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                    }}
                >
                    Communications:
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                textAlign: "justify"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                When you place an order with us or share your email/phone number via
                registration, you consent to receive further communication from us via
                e-mail, SMS, phone call, or by posting notices on the Website or by any
                other mode of communication.
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                <br />{" "}
                <span style={{ background: "white" }}>
                    For contractual purposes, you consent to receive communications
                    including SMS, e-mails, or phone calls from us with respect to your
                    order.
                </span>
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                textAlign: "justify"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 24,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                    }}
                >
                    Your Obligations:
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                You shall
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "0in",
                textAlign: "justify",
                lineHeight: "normal",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                i. not acquire any ownership rights by downloading material /Contents from
                this website.
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "0in",
                textAlign: "justify",
                lineHeight: "normal",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                ii. read the Terms and policies of website and agree to accept the Terms
                before use of Website
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "0in",
                textAlign: "justify",
                lineHeight: "normal",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                ii
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                i
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                . comply with all applicable laws in connection with your use of the
                Website, and such further limitations as may be set forth in any written
                or on-screen notice from us
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "0in",
                textAlign: "justify",
                lineHeight: "normal",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                i
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                v. not refuse to accept the delivery of the Products under your order upon
                delivery except for damages and deficiencies
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "0in",
                textAlign: "justify",
                lineHeight: "normal",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                v. authorize us to declare and provide declaration to any governmental
                authority on your behalf stating the aforesaid purpose of the products
                ordered by you on the website.
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "0in",
                textAlign: "justify",
                lineHeight: "normal",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                &nbsp;
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                margin: "0in",
                textAlign: "justify",
                background: "white"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 24,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                    }}
                >
                    Products/Services:
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                margin: "0in",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                All products/services and information displayed on Website constitute an
                "invitation to offer". Your order for purchase constitutes your "offer".
                All orders placed by you on the Website are subject to availability and
                acceptance by us and conditional on your acceptance and adherence to these
                terms and conditions.
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                margin: "0in",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                &nbsp;
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                margin: "0in",
                textAlign: "justify",
                background: "white"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 24,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                    }}
                >
                    Our Contract:
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                Your order is an offer to us to buy the product(s) in your order. We will
                confirm our acceptance of your offer either through an e-mail / SMS or
                through shipment of the items you requested. Upon placing an order, you
                will receive an e-mail confirming receipt of your order with details (the
                "Order Confirmation E-mail").
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                The Order Confirmation E-mail is an acknowledgement that we have received
                your order, and does not confirm acceptance of your offer to buy the
                product(s) ordered. Our acceptance to your offer takes place and the
                contract of sale for a product ordered by you concludes only upon actual
                dispatch of products to you and an e-mail confirmation in that regard is
                sent to you (the "Dispatch E-mail").
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                We, at our sole discretion, reserve our right to limit the quantity of
                items and cancel any order, even after acceptance. Farm
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                E
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                asy has complete right to accept or reject an order placed online. No act
                or omission of Farm
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                E
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                asy prior to actual dispatch of the product(s) ordered will constitute an
                acceptance of your offer. You agree not to dispute the decision of Farm
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                E
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                asy in this regard.
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                &nbsp;
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 24,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                    }}
                >
                    Cancellation of Order:
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                You can cancel your order fully or partially anytime up to the cut-off
                time of the slot for which you have placed an order by calling our
                customer service. In such a case we will refund any payments already made
                by you for the order as per the refund policy applicable at that time. For
                details please refer to our Cancellation Policy on the Websit
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                e
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                &nbsp;
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                }}
                >
                &nbsp;
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 24,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                    }}
                >
                    Price:
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                All the products listed on the Website will be sold at Indian Rupees
                either at Maximum Retail Price (MRP) (inclusive of all taxes) or at an
                Offer/discounted price with taxes, unless otherwise specified. The prices
                mentioned at the time of ordering will be the prices charged to you on the
                date of delivery. Although prices of most of the products do not fluctuate
                on a daily basis but some of the commodities and fresh food products
                prices do change on a daily basis.
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                In case of products such as Fresh Food, Vegetables and Fruits etc. the
                delivered weight may have variance and product will be billed accordingly
                on the actual weight. There may be cases where M.R.P. of the product
                delivered is different from the M.R.P. quoted / reflected on the Website
                due to various reasons including time lag in updating prices post changes
                in M.R.P. or product belonging to a different batch or different M.R.P.s
                being used by manufacturers in different regions. Prices and taxes thereon
                in respect of all products are subject to change from time to time,
                without prior notice by Farmeasy. We strive to provide you with the best
                prices possible on our Website.
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                margin: "0in",
                textAlign: "justify",
                background: "white"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 24,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                    }}
                >
                    Variable Weight Policy :
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                Please note that the item(s) in this product may vary slightly in size and
                weight. Hence, the actual weight of the product delivered can have a small
                variance. You will be billed based on the actual weight during order
                processing.
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                margin: "0in",
                textAlign: "justify",
                background: "white"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 24,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                    }}
                >
                    Mode of Payment
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                Farmeasy offers you multiple payment options both offline and online.
                Please refer our Price and Payment Policy document for details
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                margin: "0in",
                textAlign: "justify",
                background: "white"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 24,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                    }}
                >
                    Delivery Charges
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                delivery charge will be applicable for all the orders
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                . Its vary by different location and quantity of order
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                margin: "0in",
                textAlign: "justify",
                background: "white"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 24,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                    }}
                >
                    Delivery/Shipment of Product:
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                Delivery of the Products under your Order on the Website or Application
                shall be made as per our Delivery/shipment policy mentioned herein. Your
                orders will be delivered between 48 hours from date of order. Title of the
                Product shall pass on to you upon receipt of payment.
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                margin: "0in",
                textAlign: "justify",
                background: "white"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                    }}
                >
                    &nbsp;
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                margin: "0in",
                textAlign: "justify",
                background: "white"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 24,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                    }}
                >
                    Mispricing:
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                Despite our best efforts, there may be an instance of mispricing of items
                in our catalogue/offer on Website. However, we verify prices as part of
                our dispatch procedures. If a product's correct price is lower than our
                stated price, we charge the lower amount and send you the item. If a
                product's correct price is higher than our stated price, you may return at
                the time of delivery or we may not supply the product unless the problem
                get resolved and if you do not want to purchase the Product in such higher
                price we shall cancel your order and refund you the amount paid by you for
                such cancelled transaction as per existing refund policy. We are committed
                to and it will be our utmost endeavor not to sell any product at a price
                higher than the MRP printed on the product pack. However, despite our
                possible efforts, due to various reasons including technical lapses, time
                lag on updating price of the Product on website, in case you get billed
                erroneously at a price higher than MRP printed on the product pack, we
                shall undertake to refund the difference immediately, as per the company
                policy.
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                margin: "0in",
                textAlign: "justify",
                background: "white"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 24,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                    }}
                >
                    Return / Exchange of Products:
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                You can return your purchased products subject to our Return / Exchange
                policy.
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                margin: "0in",
                textAlign: "justify",
                background: "white"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                    }}
                >
                    &nbsp;
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                margin: "0in",
                textAlign: "justify",
                background: "white"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 24,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                    }}
                >
                    Exactness / Accuracy not guaranteed:
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                margin: "0in",
                textAlign: "justify",
                background: "white"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                    }}
                >
                    &nbsp;
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                margin: "0in",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                We have made significant efforts to accurately display the colors /
                dimension / specification of products that appear on the Website. However,
                we do not warrant/guarantee that product description or other content of
                this Website is accurate, complete, reliable, current, or error-free. The
                particular technical specifications and settings of your computer and its
                display could affect the accuracy of its display of the colors, dimension,
                specifications of products offered on this Website.
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                margin: "0in",
                textAlign: "justify",
                background: "white"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                    }}
                >
                    &nbsp;
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                margin: "0in",
                textAlign: "justify",
                background: "white"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 24,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                    }}
                >
                    Copyright:
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                margin: "0in",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                All Content included on the website, such as text, graphics, logos, button
                icons, images, audio clips, digital downloads, data compilations, and
                software, is the property of Farm
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                E
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                asy, its affiliates or its content suppliers and is protected by the
                Intellectual Property Laws of India.
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                margin: "0in",
                textAlign: "justify",
                background: "white"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 24,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                    }}
                >
                    Trademarks:
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                The trademark Farm
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                E
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                asy, its logo and all products and logos denoted with TM are trademarks
                owned or registered trademarks of Farm
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                E
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                asy or its affiliates.
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                margin: "0in",
                textAlign: "justify",
                background: "white"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 24,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                    }}
                >
                    Intellectual Property Claims:
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                Farm
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                E
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                asy and its affiliates or its content suppliers respect the intellectual
                property of others. If you believe that your intellectual property rights
                have been used in a way that gives rise to concerns of infringement,
                please write to us with your concerns.
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                margin: "0in",
                textAlign: "justify",
                background: "white"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 24,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                    }}
                >
                    Disclaimer:
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                You acknowledge and undertake that you are accessing the services on the
                Website and transacting at your own risk and are using your best and
                prudent judgment before placing any order or availing the services or
                entering into any transactions with us through our Website. We shall not
                be held liable nor responsible for any representations or
                warranties/guarantees of the products save and except the limited
                manufacturer's warranty/guarantee on the respective Product is assigned
                unto us and we hereby expressly disclaim and any all responsibility and
                liability in that regard.
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                margin: "0in",
                textAlign: "justify",
                background: "white"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 24,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                    }}
                >
                    Severability:
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                If any of these Terms is deemed invalid, void or unenforceable in whole or
                in part for any reasons, such invalidity or unenforceability condition or
                part thereof will be deemed severable and will not affect the validity and
                enforceability of any remaining condition or part of such condition /
                Terms.
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                margin: "0in",
                textAlign: "justify",
                background: "white"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 24,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                    }}
                >
                    Amendments:
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                Farm
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                E
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                asy reserves its right to amend the Website, Policies, Conditions or Terms
                of use and at any time without prior notice. All such updates and
                amendments shall be posted on the Website and will be effective from the
                time of its posting on the Website. You are advised to regularly check for
                any amendments or updates to the terms and conditions contained in this
                User Agreement, Terms and Policies.
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                margin: "0in",
                textAlign: "justify",
                background: "white"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 24,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                    }}
                >
                    Indemnification:
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                You agree to indemnify, defend and hold harmless us and affiliates
                including employees, directors, officers, agents and their successors and
                assigns from and against any and all losses, liabilities, claims, damages,
                costs and expenses (including legal fees and disbursements in connection
                therewith and interest chargeable thereon) asserted against or incurred by
                us arising out of your actions or inactions or result from your breach of
                the Terms and Conditions herein or any document incorporated by
                reference/hyperlink or infringement of intellectual property rights or
                your violation of any law, rules or regulations or the rights of any third
                party. You hereby expressly release Farmeasy and/or its affiliates and/or
                any of its officers and representatives from any cost, damage, liability
                or other consequence of any of the actions/inactions of the
                Manufacturers/Brand owners of the Products purchased through the website
                and specifically waiver any claims or demands that you may have in this
                behalf under any statute, contract or otherwise.
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 24,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                    }}
                >
                    This clause shall survive the expiry or termination of this Agreement.
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 24,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                    }}
                >
                    &nbsp;
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 24,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                    }}
                >
                    Limitation on Liability:
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                In no event shall we or our affiliates be liable for any special,
                indirect, incidental, consequential or exemplary damages including but not
                limited to, damages for loss of profits, goodwill, use, data or other
                intangible losses arising out of or in connection with the Website, its
                services or this User Agreement whatsoever, whether in an action of
                contract, negligence or other tortious action. Without prejudice to the
                generality of this section the total liability of Farm
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                E
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                asy to you for all liabilities arising out of this User Agreement be it in
                tort or contract is limited to the value of the product ordered by you.
                However in case of missing a delivery in transit, our liability is limited
                only to delivery at a later agreed time at no additional charge.
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                margin: "0in",
                textAlign: "justify",
                background: "white"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 24,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                    }}
                >
                    Waiver:
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                Our failure to enforce any provision of the Terms or respond to a breach
                by you shall not in any way waive our right to enforce subsequently any
                provision of the Terms or to act with respect to similar breaches.
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                margin: "0in",
                textAlign: "justify",
                background: "white"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 24,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                    }}
                >
                    Reserved Rights of&nbsp;
                </span>
                </strong>
                <strong>
                <span
                    style={{
                    fontSize: 24,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                    }}
                >
                    FarmEasy
                </span>
                </strong>
                <strong>
                <span
                    style={{
                    fontSize: 24,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                    }}
                >
                    :
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                We may discontinue this Website at any time and for any reason, without
                notice. All rights not expressly granted herein are hereby reserved to us.
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                margin: "0in",
                textAlign: "justify",
                background: "white"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 24,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                    }}
                >
                    Applicable Law:
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                Any dispute arising out of these terms and conditions, user agreement,
                privacy policy or the documents they incorporate by reference and/or your
                use of the Website shall be governed and construed in accordance with the
                laws of India, notwithstanding any conflicts of law.
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                margin: "0in",
                textAlign: "justify",
                background: "white"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 24,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                    }}
                >
                    Jurisdiction:
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                You specifically consent to submit any dispute arising out of these terms
                and conditions/User agreement to the jurisdiction of courts of&nbsp;
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                Thane
                </span>
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                &nbsp;District, India.
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                margin: "0in",
                textAlign: "justify",
                background: "white"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 24,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                    }}
                >
                    TERM &amp; TERMINATION:
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                margin: "0in",
                textAlign: "justify",
                background: "white"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                    }}
                >
                    &nbsp;
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                This User Agreement shall be effective form the date and time of your
                acceptance of the Terms of the Website and remain in force unless and
                until terminated by either of us. You may terminate this Agreement at any
                time, provided that you discontinue any further use of this Site. We may
                terminate this User Agreement at any time and may do so immediately
                without notice, and accordingly deny you access to the Site, Such
                termination will be without any liability to us. Upon any termination of
                the User Agreement by either of us, you must promptly destroy all
                materials downloaded or otherwise obtained from this Site, as well as all
                copies of such materials, whether made under the User Agreement or
                otherwise. Any such termination of the User Agreement shall not cancel
                your obligation to pay for the product already ordered from the Site or
                affect any liability that may have arisen under the User Agreement.
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                &nbsp;
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                &nbsp;
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                &nbsp;
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                &nbsp;
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                &nbsp;
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                &nbsp;
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                &nbsp;
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "15.0pt",
                textAlign: "justify",
                background: "white"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                    }}
                >
                    &nbsp;
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                margin: "0in",
                textAlign: "justify",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                &nbsp;
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "0in",
                textAlign: "justify",
                lineHeight: "normal",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                &nbsp;
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "0in",
                textAlign: "justify",
                lineHeight: "normal",
                background: "white"
                }}
            >
                <span
                style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726"
                }}
                >
                &nbsp;
                </span>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                textAlign: "justify"
                }}
            >
                <strong>
                <span
                    style={{
                    fontSize: 21,
                    fontFamily: '"Candara",sans-serif',
                    color: "#262726",
                    background: "white"
                    }}
                >
                    &nbsp;
                </span>
                </strong>
            </p>
            <p
                style={{
                marginRight: "0in",
                marginLeft: "0in",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                marginTop: "0in",
                marginBottom: "8.0pt",
                textAlign: "justify"
                }}
            >
                <span style={{ fontSize: 21, fontFamily: '"Candara",sans-serif' }}>
                &nbsp;
                </span>
            </p>
        </>
    )
}

export default TermsAndConditions;