import React, { useEffect, useState } from "react";
import { Rate, Space, Table, Typography, AutoComplete, Modal, Input } from "antd";
import { AddJama, AddProduct, getVendors } from "../../API";

const AddJamaEntry = () => {

    const [productName, setProductName] = useState("");
    const [productPrice, setProductPrice] = useState("");
    const [productDiscountPrice, setProductDiscountPrice] = useState("");
    const [fileSelected, setFileSelected] = useState(null);
    const [vendors, setVendors] = useState([]);
    const [inputValueVendor, setInputValueVendor] = useState('');
    const [selectedVendorID, setSelectedVendorID] = useState("");
    const [selectedVendorName, setSelectedVendorName] = useState("");
    const [selectedVendorMobile, setSelectedVendorMobile] = useState("");
    const [amountSelected, setAmountSelected] = useState("");

    useEffect(() => {
        getVendors("1").then(res => {
            //setLoading(false);
            setVendors(res.Vendors)
        })
    }, []);

    const AddJamaNewEntry = () => {
        if (selectedVendorID === "") {
            alert("Please select Vendor.")
        } else if (amountSelected === "") {
            alert("Please enter Amount.")
        } else {
            let newDate = new Date()
            let date = newDate.getDate();
            if (date < 10) {
                date = "0"+date;
            } else {
    
            }
            let month = newDate.getMonth() + 1;
            if (month < 10) {
                month = "0"+month;
            } else {
    
            }
            let year = newDate.getFullYear();
            let finaldate = date+"-"+month+"-"+year;
            AddJama(selectedVendorID, selectedVendorName, amountSelected, selectedVendorMobile, finaldate)
            .then(res => {
                setSelectedVendorID('');
                setSelectedVendorName('');
                setSelectedVendorMobile('');
                setAmountSelected('');
                alert("Jama Added Successfully.")
            })
        }
    }

    return (
        <div className="container mt-5">
            <h2 className="mb-3">Add Nave Entry</h2>
            
            <div className="mb-3">
                <label className="form-label" htmlFor="purchasedfrom">
                    Search By Vendor Name
                </label>
                <AutoComplete
                    className="form-control"
                    //value={inputValueCustomer}
                    options={vendors}
                    autoFocus={true}
                    style={{ width: 200 }}
                    filterOption={(inputValue, option) =>
                        option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                    onSelect={(value, data) => {
                        setInputValueVendor(value);
                        setSelectedVendorID(data.id);
                        setSelectedVendorName(data.vendor_name);
                        setSelectedVendorMobile(data.vendor_contact);
                        //searchByVendor(data.id);
                        //searchBillsByCustomer(data.id);
                    }}
                    onChange={() => {
                        setInputValueVendor("");
                    }}
                />
            </div>
            
            <label className="form-label mt-1" htmlFor="purchasedfrom">
                Enter Amount
            </label>
            <Input value={amountSelected} className="form-control" type="number" id="quantity" required onChange={(evt) => { setAmountSelected(evt.target.value) }} />
            <button className="btn btn-danger mb-5 mt-4" onClick={() => AddJamaNewEntry()}>
                Add Jama Entry
            </button>
        </div>
    )
}

export default AddJamaEntry;