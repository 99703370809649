import logo from './logo.svg';
import './App.css';
import AppHeader from './Components/AppHeader';
import { Image, Space, Modal, Input } from "antd";
import SideMenu from './Components/SideMenu';
import PageContent from './Components/PageContent';
import AppFooter from './Components/AppFooter';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { useState } from 'react';

function App() {

  const [showADmin, setShowAdmin] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const checkLogin = () => {
    if (username.trim() === "") {
      alert("Please enter proper username")
    } else if (password.trim() === "") {
      alert("Please enter proper password")
    } else if (username.trim() === "farmeasy1230@gmail.com" && password.trim() === "farm@1230#") {
      setIsModalVisible(false);
      setShowAdmin(true)
    } else {
      alert("Wrong username and password")
    }
  }

  const openApp = () => {
    
  }

  if (showADmin === false) {
    return (
      <html lang="en">
        <head>
          <meta charset="UTF-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <title>Starbucks Landing Page</title>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
          <link
            href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;800&display=swap"
            rel="stylesheet"
          />
          <link rel="stylesheet" href="style.css" />
          <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css"/>
          <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Lato"/>
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        </head>
        <body>
          <div class="container">
            <div class="navbar">
              <div class="navbar__logo">
                <Image
                  preview={false}
                  width={250}
                  src="https://farmeasy.co.in/API/Images/logo.png"/>
              </div>
              <div class="navbar-menu__wrapper">
                <div class="navbar__menu">
                  <a href="#" class="active"> Home </a>
                  {/* <a href="https://farmeasy.co.in/refundpolicy.html"> Refund Policy </a>
                  <a href="https://farmeasy.co.in/privacypolicy.html"> Privacy Policy </a>
                  <a href="https://farmeasy.co.in/cancellationpolicy.html"> Cancellation Policy </a>
                  <a href="https://farmeasy.co.in/termsandconditions.html"> Terms and Conditions </a>
                  <a href="https://farmeasy.co.in/shippingpolicy.html"> Cancellation Policy </a> */}
                </div>
                <div class="navbar__auth">
                  <button onClick={() => setIsModalVisible(true)} class="sign-in">Admin Login</button>
                </div>
              </div>
            </div>
            <div class="hero">
              <div class="hero__content">
                <div class="snippets">
                  <span> Welcome </span>
                </div>
                <div class="title">
                  <span>
                    <span class="summer">FarmEasy</span>
                  </span>
                </div>
                <div class="title">
                  <span>
                   Get Natural & Fresh Vegetables
                  </span>
                </div>
                <div class="description">
                  <span>
                    We deliver Fresh Vegetables to your doorstep. And we accept wholesale orders. Cash on Delivery Available.
                  </span>
                </div>
                <button onClick={() => window.open("https://play.google.com/store/apps/details?id=com.farmeasynaturalfresh.farmeasy")} class="order-now">Download Android App Now</button>
              </div>
              <div class="hero__image">
              <Image
                preview={false}
                //width={700}
                src="https://farmeasy.co.in/API/Images/farmeasydeliverytransperant.png"/>
              </div>
            </div>
            <div class="title">
              <span>
                About Us
              </span>
            </div>
            <div class="descriptionaboutus">
              <span>
                FamEasy Natural & Fresh company is working since 2023. The only director of the company is Nishant Avhad. He established this company with the help of his father and is running it well.
              </span>
            </div>
            <div class="descriptionaboutus">
              <span>
                FarmEasy is B2B business Model. FarmEasy is a digital platform joined by many farmers as well as many commercial traders , hotel owners and food processing companies who require large quantities of fruits and vegetables. FarmEasy is providing 2 or 3 quality of per vegetables and fruits for customers. so customer can choose suitable for him the quality and buy it easily . These purchased goods are delivered at home within a very short time. FarmEasy became the link between the farmer and this company.
              </span>
            </div>
            <div class="descriptionaboutus">
              <span>
                FarmEasy accepts both cash and online payment cards But does not work on credit.
              </span>
            </div>
            <div class="w3-row-padding ">
            <div class="w3-row-padding w3-center">
              <div class="w3-third">
                <div class="w3-container">
                <Image
                  preview={false}
                  width={250}
                  src="https://farmeasy.co.in/API/Images/delivery-truck.png"/>
                <h2>Fast Delivery</h2><br/>
                <p>Our Drivers deliver to your doorsteps without damages and delays.</p>
                </div>
              </div>

              <div class="w3-third">
                <div class="w3-container">
                <h2></h2><br/>
                <h2></h2><br/>
                <Image
                  preview={false}
                  width={250}
                  marginVertical={50}
                  src="https://farmeasy.co.in/API/Images/veggies.png"/>
                <h2></h2><br/>
                <p></p>
                <h2>Fresh Vegetables</h2><br/>
                <p>Our Veggies comes directly from the farmers everyday. So no chance they are rotten.</p>
                <p></p>
                </div>
              </div>

              <div class="w3-third">
                <div class="w3-container">
                <h2></h2><br/>
                <h2></h2><br/>
                <Image
                  preview={false}
                  width={150}
                  src="https://farmeasy.co.in/API/Images/get-money.png"/>
                <h2></h2><br/>
                <h2>Wholesale Prices</h2><br/>
                <p>We offer less prices for products than market on wholesale purchase.</p>
                </div>
              </div>
            </div>
            </div>
            <Space style={{ marginTop: 100 }}>
            <footer class="footer-distributed">
              <div class="footer-left">

                <h3>Farm<span>Easy</span></h3>

                <p class="footer-links">
                  <a href="#" class="link-1">Home</a>
                  
                  <a href="https://farmeasy.co.in/refundpolicy.html">Refund Policy</a>
                
                  <a href="https://farmeasy.co.in/privacypolicy.html">Privacy Policy</a>
                
                  <a href="https://farmeasy.co.in/cancellationpolicy.html">Cancellation Policy</a>
                  
                  <a href="https://farmeasy.co.in/termsandconditions.html">Terms and Conditions</a>
                  
                  <a href="https://farmeasy.co.in/shippingpolicy.html">Shipping Policy</a>
                </p>

                <p class="footer-company-about">
                  <span>Company Profile</span>
                  <p>FarmEasy is a wholesale platform for fruits and vegetables. We serve fresh and natural fruits and vegetables. We allow hotels restaurants, retailers and fruit or vegetable processing companies to buy everything from vegetables and fruits. We offer both door step delivery and pick up point options.. Doorstep delivery charges are based on order quantity</p>
                </p>

                <div>
                  <i class="fa fa-map-marker"></i>
                  <p><span>Address - PL No 17B, 17C & 20, V-1113, Sector - 19, </span> Vashi, Navi Mumbai, Thane - 400705</p>
                </div>

                <div>
                  <i class="fa fa-envelope"></i>
                  <p><a href="mailto:support@company.com">support@company.com</a></p>
                </div>

                <p class="footer-company-name">FarmEasy Natural & Fresh © 2015</p>
              </div>

              {/* <div class="footer-right">

                <p class="footer-company-about">
                  <span>Company Profile</span>
                  Lorem ipsum dolor sit amet, consectateur adispicing elit. Fusce euismod convallis velit, eu auctor lacus vehicula sit amet.
                </p>

                <div class="footer-icons">

                  <a href="#"><i class="fa fa-facebook"></i></a>
                  <a href="#"><i class="fa fa-instagram"></i></a>
                  <a href="#"><i class="fa fa-twitter"></i></a>

                </div>

              </div> */}

            </footer>
            </Space>
                        
          </div>
          <Modal
                visible={isModalVisible}
                okText="Login"
                closable={false}
                cancelText="Close"
                onOk={() => {
                    checkLogin()
                }}
                onCancel={() => {
                    setIsModalVisible(false);
                }}>
                <div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="stock">
                            Enter Username
                        </label>
                        <Input value={username} className="form-control mb-1" id="stock" required onChange={(evt) => { setUsername(evt.target.value) }}/>
                        <label className="form-label" htmlFor="stock">
                            Enter Password
                        </label>
                        <Input pattern='password' value={password} className="form-control mb-1" id="stock" required onChange={(evt) => { setPassword(evt.target.value) }}/>
                    </div>
                </div>
            </Modal>
        </body>
        
      </html>
    )
  } else {
    return (
      <div className="App">
        <AppHeader />
        <Space className="SideMenuAndPageContent">
          <SideMenu></SideMenu>
          <PageContent></PageContent>
        </Space>
      </div>
    );
  }
}

export default App;
