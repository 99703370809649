import { Rate, Space, Table, Typography } from "antd";
import Avatar from "antd/es/avatar/avatar";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getCustomers, getInventory, getOrders, getUsers, getVendors } from "../../API";
import { Document, Page } from "react-pdf";

function CancellationPolicy() {

    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }){
        setNumPages(numPages);
    }

    return (
        <>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                &nbsp;
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                &nbsp;
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 24, fontFamily: '"Candara",sans-serif' }}>
                Cancellation Policy&nbsp;
                </span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 24, fontFamily: '"Candara",sans-serif' }}>
                &nbsp;
                </span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}>
                The customer can choose to cancel an order any time before it's
                dispatched. The order cannot be canceled once it's out for delivery.
                However, the customer may choose to reject it at the doorstep. Then also
                customer get charges as per return and refund policy. The time window for
                cancellation varies based on different categories and the order cannot be
                canceled once the specified time has passed.
                </span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}>
                &nbsp;
                </span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}>
                &nbsp;In some cases, the customer may not be allowed to cancel the order
                for free, post the specified time and a cancellation fee will be charged.
                The details about the time window mentioned on the product page or order
                confirmation page will be considered final.&nbsp;
                </span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}>
                &nbsp;
                </span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}>
                FarmEasy reserves the right to accept the cancellation of any order.
                FarmEasy also reserves the right to waive off or modify the time window or
                cancellation fee from time to time.
                </span>
            </p>
        </>
    );
}

export default CancellationPolicy;

//https://farmeasy.co.in/API/PDF/CancellationPolicy.pdf