import { Rate, Space, Table, Typography } from "antd";
import Avatar from "antd/es/avatar/avatar";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { EditOrdersStatus, getCustomers, getInventory, getOrders, getOrdersPlaceStatus, getUsers, getVendors } from "../../API";

function OrderPlaceStatus() {

    const [loading, setLoading] = useState(false);
    const [ordersStatus, setOrdersStatus] = useState("");

    useEffect(() => {
        setLoading(true);
        getOrdersPlaceStatus("1").then(res => {
            setLoading(false);
            setOrdersStatus(res.Status[0].status)
        })
    }, []);

    const changeOrdersStatus = (status) => {
        EditOrdersStatus(status).then(res => {
            getOrdersPlaceStatus("1").then(res => {
                setLoading(false);
                setOrdersStatus(res.Status[0].status)
            })
        })
    }

    return (
        <Space size={20} direction="vertical">
            <Typography.Title level={4}>
                Users
            </Typography.Title>
            <Typography.Title level={4}>
                Orders Status: {ordersStatus}
            </Typography.Title>
            <button className="btn btn-danger" onClick={() => ordersStatus === "ON" ? changeOrdersStatus("OFF") : changeOrdersStatus("ON")}>{ordersStatus === "ON" ? "Set Order Status Off" : "Set Order Status On"}</button>
        </Space>
    )
}

export default OrderPlaceStatus;