import { AutoComplete, Typography } from 'antd';
import Input from 'antd/es/input/Input';
import React, { useState, useEffect } from 'react';
import { AddWastedProduct, getAllProducts, getInventory, getInventoryForWaste, getProducts } from '../../API';

const AddWasteMaterial = () => {

    const [selectedProduct, setSelectedProduct] = useState('');
    const [selectedProductID, setSelectedProductID] = useState('');
    const [selectedProductName, setSelectedProductName] = useState('');
    const [selectedProductPrice, setSelectedProductPrice] = useState('');
    const [selectedProductQuan, setSelectedProductQuan] = useState('');
    const [products, setProducts] = useState([]);
    const [allProducts, setAllProducts] = useState([]);

    useEffect(() => {
        getInventory("1")
            .then(res => {
                //setLoading(false);
                console.log(res);
                setProducts(res.Products);
                // getAllProducts("1")
                // .then(res => {
                //     console.log(res);
                //     setAllProducts(res.Products);
                // })
            })
    }, []);

    const addingWastedProduct = () => {
        if (selectedProductID === '') {
            alert('Please Select Product');
        } else if (selectedProductQuan === '') {
            alert('Please Select Product Quantity');
        } else {
            let newDate = new Date()
            let date = newDate.getDate();
            if (date < 10) {
                date = "0"+date;
            } else {

            }
            let month = newDate.getMonth() + 1;
            if (month < 10) {
                month = "0"+month;
            } else {

            }
            let year = newDate.getFullYear();
            let finaldate = date+"-"+month+"-"+year;
            console.log(finaldate);
            AddWastedProduct(selectedProductID, selectedProductName, selectedProductQuan, selectedProductPrice, finaldate)
            .then(res => {
                setSelectedProduct('');
                setSelectedProductID('');
                setSelectedProductPrice('');
                setSelectedProductName('');
                setSelectedProductQuan('');
                alert("Waste Product Added.");
            })
        }
    }

    return (
        <div className="container mt-5">
            <Typography.Title level={4} style={{ marginTop: '30px' }}>
                Add Waste Material
            </Typography.Title>
            <label className="form-label mt-3" htmlFor="purchasedfrom">
                Select Product
            </label>
            <AutoComplete
                className="form-control"
                //value={selectedProduct}
                options={products}
                autoFocus={true}
                style={{ width: 550 }}
                filterOption={(inputValue, option) =>
                    option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
                onSelect={(value, data) => {
                    setSelectedProduct(value);
                    setSelectedProductID(data.id);
                    setSelectedProductPrice(data.purchase_price);
                    setSelectedProductName(data.value)
                    //addProductToOrder(data.id, data.value, data.price);
                }}
                onChange={() => {
                    setSelectedProduct("");
                }}
            />
            <label className="form-label mt-1" htmlFor="purchasedfrom">
                Enter Quantity
            </label>
            <Input value={selectedProductQuan} className="form-control" type="number" id="quantity" required onChange={(evt) => { setSelectedProductQuan(evt.target.value) }} />
            <button className="btn btn-danger mt-3" onClick={() => {
                console.log("Product Added");
                addingWastedProduct()
            }}>
                Add Product to Waste
            </button>
        </div>
    )
}

export default AddWasteMaterial;