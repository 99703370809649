import { Rate, Space, Table, Typography, AutoComplete, Modal, Input, DatePicker } from "antd";
import Avatar from "antd/es/avatar/avatar";
import { useEffect, useState } from "react";
import { Link, useNavigation } from "react-router-dom";
import { EditInventory, EditInventoryFromInventory, getInventory, getInventoryFromDateToDate, getPurchaseByVendor, getVendors } from "../../API";
import { BASE_URL_PRODUCTION } from "../../Utils/AppConstants";
import { jsPDF } from "jspdf";

function Inventory() {

    const [loading, setLoading] = useState(false);
    const [datasource, setDatasource] = useState([]);
    const [datasourceVendorWise, setDatasourceVendorWise] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [inputValueVendor, setInputValueVendor] = useState('');
    const [selectedVendorID, setSelectedVendorID] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalPayVisible, setIsModalPayVisible] = useState(false);
    const [isModalEditDateVisible, setIsModalEditDateVisible] = useState(false);
    const [totalPurchase, setTotalPurchase] = useState(0);
    const [totalJama, setTotalJama] = useState(0);
    const [totalPending, setTotalPending] = useState(0);
    const [selectedVendorName, setSelectedVendorName] = useState("");
    const [editProductID, setEditProductID] = useState("");
    const [editProductAmountPending, setEditProductAmountPending] = useState("");
    const [payingAmount, setPayingAmount] = useState("");
    const [selectedFinalDate, setSelectedFinalDate] = useState("");
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")

    const [selectedInventoryID, setSelectedInventoryID] = useState("");
    const [selectedInventoryProductName, setSelectedInventoryProductName] = useState("");
    const [selectedInventoryPurchasePrice, setSelectedInventoryPurchasePrice] = useState("");
    const [selectedInventoryQuantity, setSelectedInventoryQuantity] = useState("");
    const [selectedInventoryCarat, setSelectedInventoryCarat] = useState("");
    const [selectedInventoryLevitax, setSelectedInventoryLevitax] = useState("");
    const [selectedInventoryTotalPurchase, setSelectedInventoryTotalPurchase] = useState("");
    const [selectedInventoryVendorID, setSelectedInventoryVendorID] = useState("");
    const [selectedInventoryVendorName, setSelectedInventoryVendorName] = useState("");
    const [selectedInventoryVendorContact, setSelectedInventoryVendorContact] = useState("");
    const [selectedInventoryVendorLocation, setSelectedInventoryVendorLocation] = useState("");
    const [editInventoryDialogVisible, setEditInventoryDialogVisible] = useState(false);

    useEffect(() => {
        let newDate = new Date()
        let date = newDate.getDate();
        if (date < 10) {
            date = "0"+date;
        } else {

        }
        let month = newDate.getMonth() + 1;
        if (month < 10) {
            month = "0"+month;
        } else {

        }
        let year = newDate.getFullYear();
        let finaldate = date+"-"+month+"-"+year;
        setSelectedFinalDate(finaldate);
        setLoading(true);
        getInventory("1")
        .then(res => {
            console.log(res);
            setLoading(false);
            setDatasource(res.Products);
            setDatasourceVendorWise(res.Products);
            let tempInventory = [];
            tempInventory = [...res.Products];
            var tempPurchase = 0;
            var tempJama = 0;
            var tempPending = 0
            for (let i = 0; i < res.Products.length; i++) {
                //tempTaxes = tempTaxes + parseInt(res.Products[i].levitax);
                tempPurchase = tempPurchase + (parseInt(res.Products[i].purchase_price) * parseInt(res.Products[i].quantity));
                tempJama = tempJama + parseInt(res.Products[i].amount_paid);
                tempPending = tempPending + parseInt(res.Products[i].amount_pending);
            }
            setTotalPurchase(tempPurchase);
            setTotalJama(tempJama);
            setTotalPending(tempPending);
        });
        getVendors("1").then(res => {
            //setLoading(false);
            setVendors(res.Vendors)
        })
    }, []);

    const searchByVendor = (vendor_id) => {
        getPurchaseByVendor(vendor_id).then(res => {
            setLoading(false);
            setDatasourceVendorWise(res.Products);
            let tempInventory = [];
            tempInventory = [...res.Products];
            var tempPurchase = 0;
            var tempJama = 0;
            var tempPending = 0
            for (let i = 0; i < res.Products.length; i++) {
                //tempTaxes = tempTaxes + parseInt(res.Products[i].levitax);
                tempPurchase = tempPurchase + (parseInt(res.Products[i].purchase_price) * parseInt(res.Products[i].quantity));
                tempJama = tempJama + parseInt(res.Products[i].amount_paid);
                tempPending = tempPending + parseInt(res.Products[i].amount_pending);
            }
            setTotalPurchase(tempPurchase);
            setTotalJama(tempJama);
            setTotalPending(tempPending);
        })
    }

    const getAllInventory = () => {
        getInventory("1")
        .then(res => {
            console.log(res);
            setLoading(false);
            setDatasource(res.Products);
            setDatasourceVendorWise(res.Products);
            let tempInventory = [];
            tempInventory = [...res.Products];
            var tempPurchase = 0;
            var tempJama = 0;
            var tempPending = 0
            for (let i = 0; i < res.Products.length; i++) {
                //tempTaxes = tempTaxes + parseInt(res.Products[i].levitax);
                tempPurchase = tempPurchase + (parseInt(res.Products[i].purchase_price) * parseInt(res.Products[i].quantity));
                tempJama = tempJama + parseInt(res.Products[i].amount_paid);
                tempPending = tempPending + parseInt(res.Products[i].amount_pending);
            }
            setTotalPurchase(tempPurchase);
            setTotalJama(tempJama);
            setTotalPending(tempPending);
        });
    }

    const downloadPdfDocument = (downloadFileName) => {
        const input = document.getElementById("testId");
        const pdf = new jsPDF({ unit: "px", format: "letter", userUnit: "px" });
        pdf.html(input, { margin: [15, 0, 15, 0], html2canvas: { scale: 0.97 } }).then(() => {
            pdf.save(downloadFileName+".pdf");
        });
    }

    const checkAmountPaid = () => {
        if (parseInt(editProductAmountPending) < parseInt(payingAmount)) {
            alert("Amount can not be greater than Pending Amount of Particular Purchase.")
        } else {
            EditInventory(editProductID, (parseInt(editProductAmountPending) - parseInt(payingAmount))).then(res => {
                alert("Pending Amount Paid Successfully.");
                window.location.reload();
            })
        }
    }

    const getDateWiseInventory = () => {
        getInventoryFromDateToDate(startDate, endDate)
            .then(res => {
                console.log("start date: "+startDate+", end date: "+endDate)
                console.log(res);
                //setLoading(false);
                setDatasource(res.Products);
                // setDatasourceVendorWise(res.Products);
                // let tempInventory = [];
                // tempInventory = [...res.Products];
                // var tempPurchase = 0;
                // var tempJama = 0;
                // var tempPending = 0
                // for (let i = 0; i < res.Products.length; i++) {
                //     //tempTaxes = tempTaxes + parseInt(res.Products[i].levitax);
                //     tempPurchase = tempPurchase + (parseInt(res.Products[i].purchase_price) * parseInt(res.Products[i].quantity));
                //     tempJama = tempJama + parseInt(res.Products[i].amount_paid);
                //     tempPending = tempPending + parseInt(res.Products[i].amount_pending);
                // }
                // setTotalPurchase(tempPurchase);
            })
    }

    const EditPurchaseMethod = () => {
        var tempTotal = (parseInt(selectedInventoryPurchasePrice)*parseInt(selectedInventoryQuantity))+parseInt(selectedInventoryLevitax);
        EditInventoryFromInventory(selectedInventoryID, selectedInventoryProductName, selectedInventoryPurchasePrice, selectedInventoryQuantity, selectedInventoryCarat, selectedInventoryLevitax, tempTotal, selectedInventoryVendorID, selectedInventoryVendorName, selectedInventoryVendorContact, selectedInventoryVendorLocation)
        .then(res => {
            setEditInventoryDialogVisible(false)
            alert("Purchase Edited Successfully.")
            let newDate = new Date()
            let date = newDate.getDate();
            if (date < 10) {
                date = "0"+date;
            } else {

            }
            let month = newDate.getMonth() + 1;
            if (month < 10) {
                month = "0"+month;
            } else {

            }
            let year = newDate.getFullYear();
            let finaldate = date+"-"+month+"-"+year;
            setSelectedFinalDate(finaldate);
            setLoading(true);
            getInventory("1")
            .then(res => {
                console.log(res);
                setLoading(false);
                setDatasource(res.Products);
                setDatasourceVendorWise(res.Products);
                let tempInventory = [];
                tempInventory = [...res.Products];
                var tempPurchase = 0;
                var tempJama = 0;
                var tempPending = 0
                for (let i = 0; i < res.Products.length; i++) {
                    //tempTaxes = tempTaxes + parseInt(res.Products[i].levitax);
                    tempPurchase = tempPurchase + (parseInt(res.Products[i].purchase_price) * parseInt(res.Products[i].quantity));
                    tempJama = tempJama + parseInt(res.Products[i].amount_paid);
                    tempPending = tempPending + parseInt(res.Products[i].amount_pending);
                }
                setTotalPurchase(tempPurchase);
                setTotalJama(tempJama);
                setTotalPending(tempPending);
            });
            getVendors("1").then(res => {
                //setLoading(false);
                setVendors(res.Vendors)
            })
        })
    }

    return (
        <Space size={20} direction="vertical">
            <Typography.Title level={4} style={{ marginTop: '30px' }}>
                Purchase
            </Typography.Title>
            {/* <Link to="/addinventory" className="btn btn-danger" type="submit">
                Add Inventory
            </Link> */}
            <Space direction="horizontal" >
                <div className="mb-3">
                    <label className="form-label" htmlFor="purchasedfrom">
                        Select Start Date
                    </label>
                    <DatePicker className="form-control" onChange={(dateString) => {
                        // setStartDate(moment(date).format('YYYY-MM-DD'))
                        //console.log(moment(dateString).format('YYYY-MM-DD'))
                        //console.log(dateString)
                        var tempStartDate = new Intl.DateTimeFormat('en-UK', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(dateString);
                        console.log(tempStartDate.split('/').reverse().join('-'))
                        setStartDate(tempStartDate.split('/').reverse().join('-'))
                    }} />
                </div>
                <div className="mb-3">
                    <label className="form-label" htmlFor="purchasedfrom">
                        Select End Date
                    </label>
                    <DatePicker className="form-control" onChange={(dateString) => {
                        var tempStartDate = new Intl.DateTimeFormat('en-UK', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(dateString);
                        console.log(tempStartDate.split('/').reverse().join('-'))
                        setEndDate(tempStartDate.split('/').reverse().join('-'))
                    }} />
                </div>
                <button className="btn btn-danger" onClick={() => getDateWiseInventory()}>Search By Date</button>
                <button className="btn btn-danger" onClick={() => getAllInventory()}>Clear Search</button>
            </Space>
            
            <Table
                columns={[
                    {
                        title: "Product Name",
                        dataIndex: "product_name"
                    },
                    {
                        title: "Purchase Price",
                        dataIndex: "purchase_price",
                        render: (value) => <span>₹{value}</span>
                    },
                    {
                        title: "Purchase Total",
                        dataIndex: "total_purchase",
                        render: (value) => <span>₹{value}</span>
                    },
                    {
                        title: "Quantity",
                        dataIndex: "quantity"
                    },
                    {
                        title: "Carat",
                        dataIndex: "carat"
                    },
                    {
                        title: "Levi Tax",
                        dataIndex: "levitax"
                    },
                    {
                        title: "Vendor Name",
                        dataIndex: "vendor_name"
                    },
                    {
                        title: "Vendor Contact",
                        dataIndex: "vendor_contact"
                    },
                    {
                        title: "Vendor Location",
                        dataIndex: "vendor_location"
                    },
                    {
                        title: "Purchase Date",
                        dataIndex: "purchase_date"
                    },
                    {
                        title: "",
                        dataIndex: "product_name",
                        render: (value, data) => <button className="btn btn-danger" onClick={() => {
                            console.log("Edit");
                            setSelectedInventoryID(data.id)
                            setSelectedInventoryProductName(data.product_name)
                            setSelectedInventoryPurchasePrice(data.purchase_price)
                            setSelectedInventoryTotalPurchase(data.total_purchase)
                            setSelectedInventoryQuantity(data.quantity)
                            setSelectedInventoryCarat(data.carat)
                            setSelectedInventoryLevitax(data.levitax)
                            setSelectedInventoryVendorID(data.vendor_id)
                            setSelectedInventoryVendorName(data.vendor_name)
                            setSelectedInventoryVendorContact(data.vendor_contact)
                            setSelectedInventoryVendorLocation(data.vendor_location)
                            setEditInventoryDialogVisible(true)
                            //EditProductQuantity(data.id);
                            
                        }}>Edit Purchase</button>
                    },
                ]}
                loading={loading}
                dataSource={datasource}
                pagination={{
                    pageSize: 5
                }}>

            </Table>
            <Modal
                visible={editInventoryDialogVisible}
                okText="Edit Purchase"
                closable={false}
                cancelText="Close"
                onOk={() => {
                    //checkAmountPaid();
                    EditPurchaseMethod()
                    //downloadPdfDocument("test");
                }}
                onCancel={() => {
                    setEditInventoryDialogVisible(false);
                }}>
                <div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="stock">
                            Enter Product Name
                        </label>
                        <Input value={selectedInventoryProductName} className="form-control" id="stock" required onChange={(evt) => { 
                            //setEditedPrice(evt.target.value) 
                            setSelectedInventoryProductName(evt.target.value)
                        }}/>
                        <label className="form-label" htmlFor="stock">
                            Enter Product Purchase Price
                        </label>
                        <Input value={selectedInventoryPurchasePrice} className="form-control" type="number" id="stock" required onChange={(evt) => { 
                            //setEditedPrice(evt.target.value) 
                            setSelectedInventoryPurchasePrice(evt.target.value)
                        }}/>
                        <label className="form-label" htmlFor="stock">
                            Enter Product Purchase Quantity
                        </label>
                        <Input value={selectedInventoryQuantity} className="form-control" type="number" id="stock" required onChange={(evt) => { 
                            //setEditedPrice(evt.target.value) 
                            setSelectedInventoryQuantity(evt.target.value)
                        }}/>
                        <label className="form-label" htmlFor="stock">
                            Enter Product Carats
                        </label>
                        <Input value={selectedInventoryCarat} className="form-control" type="number" id="stock" required onChange={(evt) => { 
                            //setEditedPrice(evt.target.value) 
                            setSelectedInventoryCarat(evt.target.value)
                        }}/>
                        <label className="form-label" htmlFor="stock">
                            Enter Product Carats
                        </label>
                        <Input value={selectedInventoryLevitax} className="form-control" type="number" id="stock" required onChange={(evt) => { 
                            //setEditedPrice(evt.target.value) 
                            setSelectedInventoryLevitax(evt.target.value)
                        }}/>
                        <label className="form-label" htmlFor="purchasedfrom">
                            Selected Vendor
                        </label>
                        <label className="form-control" htmlFor="purchasedfrom">
                            {selectedInventoryVendorName}
                        </label>
                        <label className="form-label" htmlFor="purchasedfrom">
                            Edit Vendor
                        </label>
                        <AutoComplete
                            className="form-control"
                            //value={selectedInventoryVendorName}
                            //value={inputValueCustomer}
                            options={vendors}
                            autoFocus={true}
                            style={{ width: 200 }}
                            filterOption={(inputValue, option) =>
                                option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                            onSelect={(value, data) => {
                                setInputValueVendor(value);
                                setSelectedInventoryVendorID(data.id);
                                setSelectedInventoryVendorName(data.vendor_name);
                                setSelectedInventoryVendorContact(data.vendor_contact);
                                setSelectedInventoryVendorLocation(data.vendor_location);
                                //searchBillsByCustomer(data.id);
                            }}
                            onChange={() => {
                                setInputValueVendor("");
                            }}
                        />
                    </div>
                </div>
            </Modal>
            <Modal
                visible={isModalPayVisible}
                okText="Save"
                closable={false}
                cancelText="Close"
                onOk={() => {
                    checkAmountPaid();
                    //downloadPdfDocument("test");
                }}
                onCancel={() => {
                    setIsModalPayVisible(false);
                }}>
                <div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="stock">
                            Enter Amount
                        </label>
                        <Input value={payingAmount} className="form-control" type="number" id="stock" required onChange={(evt) => { 
                            //setEditedPrice(evt.target.value) 
                            setPayingAmount(evt.target.value)
                        }}/>
                    </div>
                </div>
            </Modal>
            <Modal
                visible={isModalVisible}
                okText="Print"
                style={{ width: "100%", resize: "none" }}
                closable={false}
                cancelText="Close"
                onOk={() => {
                    let newDate = new Date()
                    let date = newDate.getDate();
                    if (date < 10) {
                        date = "0"+date;
                    } else {

                    }
                    let month = newDate.getMonth() + 1;
                    if (month < 10) {
                        month = "0"+month;
                    } else {

                    }
                    let year = newDate.getFullYear();
                    let finaldate = date+"-"+month+"-"+year;
                    selectedVendorName === "" ? downloadPdfDocument("All Vendors "+finaldate) : downloadPdfDocument(selectedVendorName+" "+finaldate)
                }}
                onCancel={() => {
                    setIsModalVisible(false);
                }}>
                <div id="testId" class="mt-10 mb-7 w-100">
                    <div class="justify-content-center">
                    <div>
                        <div class="card">
                        <div class="card-body p-2">
                            <div class="text-muted mb-2">Jama Nagad Details</div>
                            <div class="text-muted mb-2">Date: {selectedFinalDate}</div>
                            <div class="text-muted mb-2">Vendor: {selectedVendorName === "" ? "All Vendors" : selectedVendorName }</div>
                            <div class="border-top border-gray-200 pt-4 mt-2 mb-2"></div>
                            <div class="mt-5">
                            <Table
                                columns={[
                                    {
                                        title: "Product Name",
                                        dataIndex: "product_name"
                                    },
                                    {
                                        title: "Purchase Price",
                                        dataIndex: "purchase_price",
                                        render: (value) => <span>₹{value}</span>
                                    },
                                    {
                                        title: "Quantity",
                                        dataIndex: "quantity"
                                    },
                                    {
                                        title: "Carat",
                                        dataIndex: "carat"
                                    },
                                    {
                                        title: "Purchase Date",
                                        dataIndex: "purchase_date"
                                    },
                                ]}
                                dataSource={datasourceVendorWise}
                                pagination={false}>

                            </Table>
                            </div>
                            <div class="mt-5">
                            <Table
                                columns={[
                                    {
                                        title: "Product Name",
                                        dataIndex: "product_name"
                                    },
                                    {
                                        title: "Purchase Total",
                                        dataIndex: "total_purchase",
                                        render: (value) => <span>₹{value}</span>
                                    },
                                    {
                                        title: "Amount Paid",
                                        dataIndex: "amount_paid",
                                        render: (value) => <span>₹{value}</span>
                                    },
                                    {
                                        title: "Amount Paid Date",
                                        dataIndex: "amount_paid_date"
                                    },
                                ]}
                                dataSource={datasourceVendorWise}
                                pagination={false}>

                            </Table>
                            </div>
                            <div class="mt-5">
                            <div class="d-flex justify-content-end mt-3">
                                <h5 class="me-3">Total Purchase:</h5>
                                <h5>₹{totalPurchase}</h5>
                            </div>
                            <div class="d-flex justify-content-end mt-3">
                                <h5 class="me-3">Total Jama:</h5>
                                <h5>₹{totalJama}</h5>
                            </div>
                            <div class="d-flex justify-content-end mt-3">
                                <h5 class="me-3">Total Pending:</h5>
                                <h5>₹{totalPending}</h5>
                            </div>
                            {/* <div class="d-flex justify-content-end mt-3">
                                <h5 class="me-3">Total Profits:</h5>
                                <h5>₹{parseInt(dhadabookRevenue) - parseInt(dhadabookPurchase) - parseInt(dhadabookWaste) - parseInt(dhadabookTaxes)}</h5>
                            </div> */}
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </Modal>
        </Space>
    )
}

export default Inventory;