import { Rate, Space, Table, Typography } from "antd";
import Avatar from "antd/es/avatar/avatar";
import { useEffect, useState } from "react";
import { Link, useNavigation } from "react-router-dom";
import { getInventory } from "../../API";

function LevisAndTaxes() {

    const [loading, setLoading] = useState(false);
    const [datasource, setDatasource] = useState([]);

    useEffect(() => {
        setLoading(true);
        getInventory("1").then(res => {
            setLoading(false);
            setDatasource(res.Products);
        })
    }, []);

    return (
        <Space size={20} direction="vertical">
            <Typography.Title level={4} style={{ marginTop: '30px' }}>
                Taxes
            </Typography.Title>
            <Table
                columns={[
                    {
                        title: "Product Name",
                        dataIndex: "product_name"
                    },
                    {
                        title: "Levi/Tax",
                        dataIndex: "levitax",
                        render: (value) => <span>₹{value}</span>
                    },
                ]}
                loading={loading}
                dataSource={datasource}
                pagination={{
                    pageSize: 5
                }}>

            </Table>
        </Space>
    )
}

export default LevisAndTaxes;