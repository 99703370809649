import React, { useState } from "react";
import { Rate, Space, Table, Typography, Modal, Menu, Dropdown } from "antd";
import Input from 'antd/es/input/Input';
import { AddProduct } from "../../API";

const AddNewProduct = () => {

    const [productName, setProductName] = useState("");
    const [productPrice, setProductPrice] = useState("");
    const [productCategory, setProductCategory] = useState("");
    const [productDiscountPrice, setProductDiscountPrice] = useState("");
    const [productDescription, setProductDescription] = useState("");
    const [productSize, setProductSize] = useState("");
    const [productStorage, setProductStorage] = useState("");
    const [productDisclaimer, setProductDisclaimer] = useState("");
    const [productCountry, setProductCountry] = useState("");
    const [productQuality, setProductQuality] = useState("0");
    const [productQualityDisplay, setProductQualityDisplay] = useState("First");
    const [productUnit, setProductUnit] = useState("");
    const [fileSelected, setFileSelected] = useState(null);

    const addProductNew = () => {
        if (productName === '') {
            alert('Please add Product Name.');
        } else if (productPrice === '') {
            alert('Please add Product Price.');
        } else if (productCategory === '') {
            alert('Please add Product Category.');
        } else if (productDiscountPrice === '') {
            alert('Please add Product Discount Price.');
        } else if (productPrice === '') {
            alert('Please select product Photo.');
        } else if (productDescription === '') {
            alert('Please select product Description.');
        } else if (productQuality === '') {
            alert('Please select product Quality.');
        } else if (productUnit === '') {
            alert('Please select product Unit.');
        } else {
            AddProduct(productName, productPrice, productCategory, productDiscountPrice, productDescription, fileSelected, productSize, productStorage, productDisclaimer, productCountry, productQuality, productUnit)
            .then(res => {
                setProductName("");
                setProductPrice("");
                setProductDiscountPrice("");
                setFileSelected(null);
                alert("Product Added Successfully.");
            }) 
        }
    }

    const handleClick = ({key}) => {
        console.log(key)
        if (key === "1") {
            setProductQualityDisplay("First")
        } else if (key === "2") {
            setProductQualityDisplay("Second")
        } else if (key === "3") {
            setProductQualityDisplay("Third")
        } else {

        }
        setProductQuality(key)
        //you can perform setState here
    }

    const handleCategoryClick = ({key}) => {
        console.log(key)
        setProductCategory(key)
        //you can perform setState here
    }
    
    const handleUnitClick = ({key}) => {
        console.log(key)
        setProductUnit(key)
        //you can perform setState here
    }

    const category = (
        <Menu onClick={handleCategoryClick}>
            <Menu.Item key="Vegetables">Vegetables</Menu.Item>
            <Menu.Item key="Leafy Vegetables">Leafy Vegetables</Menu.Item>
            <Menu.Item key="Exotic Vegetables">Exotic Vegetables</Menu.Item>
        </Menu>
    );
    
    const menu = (
        <Menu onClick={handleClick}>
            <Menu.Item key="1">First</Menu.Item>
            <Menu.Item key="2">Second</Menu.Item>
            <Menu.Item key="3">Third</Menu.Item>
        </Menu>
    );

    const unit = (
        <Menu onClick={handleUnitClick}>
            <Menu.Item key="KG">KG</Menu.Item>
            <Menu.Item key="Dozen">Dozen</Menu.Item>
            <Menu.Item key="Bunch">Bunch</Menu.Item>
            <Menu.Item key="Pack">Pack</Menu.Item>
        </Menu>
    );

    const onFileChange = (event) => {
        // Update the state
        //this.setState({ selectedFile:  });
        setFileSelected(event.target.files[0]);
    };

    return (
        <div className="container mt-5">
            <h2 className="mb-3">Add Product Details</h2>
            
            <label className="form-label mt-1" htmlFor="purchasedfrom">

            </label>
            <label className="form-label mt-1" htmlFor="purchasedfrom">
                Enter Name
            </label>
            <Input value={productName} className="form-control" id="quantity" required onChange={(evt) => { setProductName(evt.target.value) }} />
            
            <label className="form-label mt-1" htmlFor="purchasedfrom">
                Enter Price
            </label>
            <Input value={productPrice} className="form-control" type="number" id="quantity" required onChange={(evt) => { setProductPrice(evt.target.value) }} />
            <label className="form-label mt-1" htmlFor="purchasedfrom">
                Enter Discount Price
            </label>
            <Input value={productDiscountPrice} className="form-control" type="number" id="quantity" required onChange={(evt) => { setProductDiscountPrice(evt.target.value) }} />

            <label className="form-label mt-1" htmlFor="purchasedfrom">
                Enter Description
            </label>
            <textarea value={productDescription} className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg" id="quantity" required onChange={(evt) => { setProductDescription(evt.target.value) }} />

            <label className="form-label mt-1" htmlFor="purchasedfrom">
                Enter Size
            </label>
            <Input value={productSize} className="form-control" id="quantity" required onChange={(evt) => { setProductSize(evt.target.value) }} />

            <label className="form-label mt-1" htmlFor="purchasedfrom">
                Enter Storage Tips
            </label>
            <textarea value={productStorage} className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg" id="quantity" required onChange={(evt) => { setProductStorage(evt.target.value) }} />

            <label className="form-label mt-1" htmlFor="purchasedfrom">
                Enter Disclaimer
            </label>
            <textarea value={productDisclaimer} className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg" id="quantity" required onChange={(evt) => { setProductDisclaimer(evt.target.value) }} />

            <label className="form-label mt-1" htmlFor="purchasedfrom">
                Enter Country
            </label>
            <Input value={productCountry} className="form-control" id="quantity" required onChange={(evt) => { setProductCountry(evt.target.value) }} />
            <label className="form-label mt-1" htmlFor="stock">
                Set Category
            </label>
            <Dropdown overlay={category} trigger={["click"]}>
                <a
                    className="form-control"
                    onClick={(e) => e.preventDefault()}
                >
                    {productCategory}
                </a>
            </Dropdown>
            <label className="form-label mt-1" htmlFor="stock">
                Set Quality
            </label>
            <Dropdown overlay={menu} trigger={["click"]}>
                <a
                    className="form-control"
                    onClick={(e) => e.preventDefault()}
                >
                    {productQualityDisplay}
                </a>
            </Dropdown>
            <label className="form-label mt-1" htmlFor="stock">
                Set Unit
            </label>
            <Dropdown overlay={unit} trigger={["click"]}>
                <a
                    className="form-control"
                    onClick={(e) => e.preventDefault()}
                >
                    {productUnit}
                </a>
            </Dropdown>

            <label className="form-label mt-1" htmlFor="purchasedfrom">
                Select Photo
            </label>
            <input className="form-control" type="file" onChange={onFileChange} />
            <button className="btn btn-danger mb-5 mt-4" onClick={() => addProductNew()}>
                Add Product
            </button>
        </div>
    )
}

export default AddNewProduct;