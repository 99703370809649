import { json } from "react-router-dom";
import { BASE_URL, BASE_URL_PRODUCTION, TEST_URL } from "../Utils/AppConstants";

export const getOrders = (masteruserid) => {
    let formdata = new FormData();
    formdata.append("userid", masteruserid);
    return fetch(BASE_URL_PRODUCTION + "getBills.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getPlacedOrders = (masteruserid) => {
    let formdata = new FormData();
    formdata.append("userid", masteruserid);
    return fetch(BASE_URL_PRODUCTION + "getReceivedBills.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getOrdersPlaceStatus = () => {
    let formdata = new FormData();
    formdata.append("user_id", "1");
    return fetch(BASE_URL_PRODUCTION + "getOrdersPlaceStatus.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const addRefunds = (userid, username, usermobile, orderid, amount, paymentid, refundtype) => {
    let formdata = new FormData();
    formdata.append("userid", userid);
    formdata.append("username", username);
    formdata.append("usermobile", usermobile);
    formdata.append("orderid", orderid);
    formdata.append("amount", amount);
    formdata.append("paymentid", paymentid);
    formdata.append("refund_type", refundtype);
    return fetch(BASE_URL_PRODUCTION + "addRefund.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getRefunds = () => {
    let formdata = new FormData();
    formdata.append("userid", "1");
    return fetch(BASE_URL_PRODUCTION + "getRefunds.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getProductsByQuantity = (id) => {
    let formdata = new FormData();
    formdata.append("id", id);
    return fetch(BASE_URL_PRODUCTION + "getProductByQuantity.php", {
        method: 'POST',
        body: formdata,
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getDeliveryIndex = (id) => {
    let formdata = new FormData();
    formdata.append("userid", id);
    return fetch(BASE_URL_PRODUCTION + "getDeliveryIndexes.php", {
        method: 'POST',
        body: formdata,
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const editProductsByQuantity = (id, quantity) => {
    let formdata = new FormData();
    formdata.append("id", id);
    formdata.append("quantity", quantity);
    return fetch(BASE_URL_PRODUCTION + "editProductQuantity.php", {
        method: 'POST',
        body: formdata,
    })
    //.then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const editDeliveryIndex = (multiplier, charge_name) => {
    let formdata = new FormData();
    formdata.append("multiplier", multiplier);
    formdata.append("charge_name", charge_name);
    return fetch(BASE_URL_PRODUCTION + "editDeliveryIndex.php", {
        method: 'POST',
        body: formdata,
    })
    //.then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getCustomerAddresses = (masteruserid) => {
    let formdata = new FormData();
    formdata.append("user_id", masteruserid);
    return fetch(BASE_URL_PRODUCTION + "getAddresses.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getDateWisePaymentHistory = (masteruserid, fromdate, todate) => {
    let formdata = new FormData();
    formdata.append("user_id", masteruserid);
    formdata.append("from_date", fromdate);
    formdata.append("to_date", todate);
    return fetch(BASE_URL_PRODUCTION + "getPaymentsHistoryFromToDate.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getCustomerPaymentHistory = (masteruserid, customer_mobile) => {
    let formdata = new FormData();
    formdata.append("user_id", masteruserid);
    formdata.append("customer_mobile", customer_mobile);
    return fetch(BASE_URL_PRODUCTION + "getPaymentHistoryByCustomer.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getBillIDPaymentHistory = (masteruserid, billid) => {
    let formdata = new FormData();
    formdata.append("user_id", masteruserid);
    formdata.append("bill_id", billid);
    return fetch(BASE_URL_PRODUCTION + "getPaymentHistoryByBillID.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getStatusPaymentHistory = (masteruserid, status) => {
    let formdata = new FormData();
    formdata.append("user_id", masteruserid);
    formdata.append("status", status);
    return fetch(BASE_URL_PRODUCTION + "getPaymentHistoryByStatus.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getOffers = (masteruserid) => {
    let formdata = new FormData();
    formdata.append("user_id", masteruserid);
    return fetch(BASE_URL_PRODUCTION + "getOffers.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const deleteOffers = (id) => {
    let formdata = new FormData();
    formdata.append("id", id);
    formdata.append("status", "DELETE");
    return fetch(BASE_URL_PRODUCTION + "editOffer.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getAcceptedOrders = (masteruserid) => {
    let formdata = new FormData();
    formdata.append("userid", masteruserid);
    return fetch(BASE_URL_PRODUCTION + "getBillsAccepted.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getFilteredOrders = (from_date, to_date, customer_mobile, order_status, order_type) => {
    let formdata = new FormData();
    formdata.append("userid", "1");
    formdata.append("from_date", from_date);
    formdata.append("to_date", to_date);
    formdata.append("customer_mobile", customer_mobile);
    formdata.append("order_status", order_status);
    formdata.append("order_type", order_type);
    return fetch(BASE_URL_PRODUCTION + "getBillsFiltered.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getCancelledOrders = (masteruserid) => {
    let formdata = new FormData();
    formdata.append("userid", masteruserid);
    return fetch(BASE_URL_PRODUCTION + "getBillsCancelled.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const addTimeSlots = (timeSlot, timeDuration) => {
    let formdata = new FormData();
    formdata.append("time_slot", timeSlot);
    formdata.append("time_duration", timeDuration);
    return fetch(BASE_URL_PRODUCTION + "addTimeSlot.php", {
        method: 'POST',
        body: formdata
    })
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const editTimeSlots = (id, status) => {
    let formdata = new FormData();
    formdata.append("id", id);
    formdata.append("status", status);
    return fetch(BASE_URL_PRODUCTION + "editTimeSlot.php", {
        method: 'POST',
        body: formdata
    })
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getTimeSlots = (masteruserid) => {
    let formdata = new FormData();
    formdata.append("user_id", masteruserid);
    return fetch(BASE_URL_PRODUCTION + "getTimeSlots.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getOrdersByStatus = () => {
    let formdata = new FormData();
    formdata.append("order_status", "PACKED");
    return fetch(BASE_URL_PRODUCTION + "getBillsByStatus.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getOrdersDelivered = () => {
    let formdata = new FormData();
    formdata.append("order_status", "DELIVERED");
    return fetch(BASE_URL_PRODUCTION + "getBillsByStatus.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getTodaysOrders = (date) => {
    let formdata = new FormData();
    formdata.append("date", date);
    return fetch(BASE_URL_PRODUCTION + "getBillsByDate.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getTodaysAcceptedOrders = (date) => {
    let formdata = new FormData();
    formdata.append("date", date);
    return fetch(BASE_URL_PRODUCTION + "getBillsAcceptedByDate.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getPincodes = (masteruserid) => {
    let formdata = new FormData();
    formdata.append("user_id", masteruserid);
    return fetch(BASE_URL_PRODUCTION + "getPincodes.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getNewPincodes = (masteruserid) => {
    let formdata = new FormData();
    formdata.append("userid", masteruserid);
    return fetch(BASE_URL_PRODUCTION + "getNewPincode.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getPickUpPoints = (masteruserid) => {
    let formdata = new FormData();
    formdata.append("user_id", masteruserid);
    return fetch(BASE_URL_PRODUCTION + "getPickUpPoints.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getJama = (vendorid) => {
    let formdata = new FormData();
    formdata.append("vendor_id", vendorid);
    return fetch(BASE_URL_PRODUCTION + "getJamaDetails.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getOrdersByCustomer = (masteruserid) => {
    let formdata = new FormData();
    formdata.append("customer_mobile", masteruserid);
    return fetch(BASE_URL_PRODUCTION + "getBillsByCustomer.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getOrdersByID = (masteruserid) => {
    let formdata = new FormData();
    formdata.append("billid", masteruserid);
    return fetch(BASE_URL_PRODUCTION + "getBillByID.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getPaymentHistory = (masteruserid) => {
    let formdata = new FormData();
    formdata.append("billid", masteruserid);
    return fetch(BASE_URL_PRODUCTION + "getPaymentHistory.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const EditOrder = (id, billdata, total) => {
    let formdata = new FormData();
    formdata.append("id", id);
    formdata.append("billdata", billdata);
    formdata.append("total_amount", total);
    return fetch(BASE_URL_PRODUCTION + "editOrder.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const setOfferProduct = (id,status) => {
    let formdata = new FormData();
    formdata.append("id", id);
    formdata.append("status", status);
    return fetch(BASE_URL_PRODUCTION + "setOfferProduct.php", {
        method: 'POST',
        body: formdata
    })
    //.then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const EditInventoryFromInventory = (id, product_name, purchase_price, quantity, carat, levitax, total_purchase, vendor_id, vendor_name, vendor_contact, vendor_location) => {
    let formdata = new FormData();
    formdata.append("id", id);
    formdata.append("product_name", product_name);
    formdata.append("purchase_price", purchase_price);
    formdata.append("quantity", quantity);
    formdata.append("carat", carat);
    formdata.append("levitax", levitax);
    formdata.append("total_purchase", total_purchase);
    formdata.append("vendor_id", vendor_id);
    formdata.append("vendor_name", vendor_name);
    formdata.append("vendor_contact", vendor_contact);
    formdata.append("vendor_location", vendor_location);
    return fetch(BASE_URL_PRODUCTION + "editInventory.php", {
        method: 'POST',
        body: formdata
    })
    //.then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getOrdersByDate = (masteruserid) => {
    let formdata = new FormData();
    formdata.append("date", masteruserid);
    return fetch(BASE_URL_PRODUCTION + "getDatewiseBills.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getInventory = (masteruserid) => {
    let formdata = new FormData();
    formdata.append("user_id", masteruserid);
    return fetch(BASE_URL_PRODUCTION + "getInventory.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}


export const getInventoryForWaste = (masteruserid) => {
    let formdata = new FormData();
    formdata.append("user_id", masteruserid);
    return fetch(BASE_URL_PRODUCTION + "getInventoryForWaste.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getInventoryDatewise = (masteruserid) => {
    let formdata = new FormData();
    formdata.append("purchase_date", masteruserid);
    return fetch(BASE_URL_PRODUCTION + "getDatewiseInventory.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getInventoryFromDateToDate = (fromdate, todate) => {
    let formdata = new FormData();
    formdata.append("user_id", "1");
    formdata.append("from_date", fromdate);
    formdata.append("to_date", todate);
    return fetch(BASE_URL_PRODUCTION + "getInventoryFromToDate.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getOrdersFromDateToDate = (fromdate, todate, mobile) => {
    let formdata = new FormData();
    formdata.append("user_id", "1");
    formdata.append("from_date", fromdate);
    formdata.append("to_date", todate);
    formdata.append("customer_mobile", mobile);
    return fetch(BASE_URL_PRODUCTION + "getBillsFromToDate.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getWasteFromDateToDate = (fromdate, todate) => {
    let formdata = new FormData();
    formdata.append("user_id", "1");
    formdata.append("from_date", fromdate);
    formdata.append("to_date", todate);
    return fetch(BASE_URL_PRODUCTION + "getWasteMaterialFromToDate.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getInventoryFromDateToDateAndVendor = (fromdate, todate, vendor) => {
    let formdata = new FormData();
    formdata.append("vendor_id", vendor);
    formdata.append("from_date", fromdate);
    formdata.append("to_date", todate);
    return fetch(BASE_URL_PRODUCTION + "getInventoryFromToAndVendor.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getProducts = (masteruserid) => {
    let formdata = new FormData();
    formdata.append("userid", masteruserid);
    return fetch(BASE_URL_PRODUCTION + "getProducts.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getAllProducts = (masteruserid) => {
    let formdata = new FormData();
    formdata.append("userid", masteruserid);
    return fetch(BASE_URL_PRODUCTION + "getAllProducts.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getCustomers = (masteruserid) => {
    let formdata = new FormData();
    formdata.append("userid", masteruserid);
    return fetch(BASE_URL_PRODUCTION + "getCustomers.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getVendors = (masteruserid) => {
    let formdata = new FormData();
    formdata.append("masteruserid", masteruserid);
    return fetch(BASE_URL_PRODUCTION + "getVendor.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getWastedProducts = (masteruserid) => {
    let formdata = new FormData();
    formdata.append("userid", masteruserid);
    return fetch(BASE_URL_PRODUCTION + "getWasteMaterial.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getDateWiseWaste = (masteruserid) => {
    let formdata = new FormData();
    formdata.append("expiry_date", masteruserid);
    return fetch(BASE_URL_PRODUCTION + "getDateWiseWasteMaterial.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getUsers = (masteruserid) => {
    let formdata = new FormData();
    formdata.append("masteruserid", masteruserid);
    return fetch(BASE_URL_PRODUCTION + "getUsers.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getWasteResale = () => {
    let formdata = new FormData();
    formdata.append("userid", "1");
    return fetch(BASE_URL_PRODUCTION + "getWasteResale.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getDeliveryUsers = (masteruserid) => {
    let formdata = new FormData();
    formdata.append("role", "DeliveryStaff");
    return fetch(BASE_URL_PRODUCTION + "getDeliveryUsers.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const getPurchaseByVendor = (vendor_id) => {
    let formdata = new FormData();
    formdata.append("vendor_id", vendor_id);
    return fetch(BASE_URL_PRODUCTION + "getVendorWiseInventory.php", {
        method: 'POST',
        body: formdata
    })
    .then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const AddProduct = (productName, productPrice, productCategory, productDiscount, productDescription, photo, size, storage, disclaimer, country, quality, unit) => {
    let formdata = new FormData();
    formdata.append("productName", productName);
    formdata.append("price", productPrice);
    formdata.append("category", productCategory);
    formdata.append("discount", productDiscount);
    formdata.append("description", productDescription);
    formdata.append("userid", "1");
    formdata.append('photo', photo);
    formdata.append('size', size);
    formdata.append('storage_tips', storage);
    formdata.append('disclaimer', disclaimer);
    formdata.append('country_of_origin', country);
    formdata.append('quality', quality);
    formdata.append('unit', unit);
    return fetch(BASE_URL_PRODUCTION + "addProducts.php", {
        method: 'POST',
        body: formdata
    })
    //.then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const AddOfferAPI = (photo) => {
    let formdata = new FormData();
    formdata.append('photo', photo);
    formdata.append('extra_field', "1");
    return fetch(BASE_URL_PRODUCTION + "addOffer.php", {
        method: 'POST',
        body: formdata
    })
    //.then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const AddWastedProduct = (productID, productName, productQuantity, productPrice, expiryDate) => {
    let formdata = new FormData();
    formdata.append("product_id", productID);
    formdata.append("product_name", productName);
    formdata.append("quantity", productQuantity);
    formdata.append("product_price", productPrice);
    formdata.append("expiry_date", expiryDate);
    formdata.append("status", "ACTIVE");
    formdata.append("userid", "1");
    return fetch(BASE_URL_PRODUCTION + "addWasteMaterial.php", {
        method: 'POST',
        body: formdata
    })
    //.then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const AddCustomer = (customerName, customerMobile) => {
    let formdata = new FormData();
    formdata.append("customerName", customerName);
    formdata.append("mobile", customerMobile);
    formdata.append("userid", "1");
    return fetch(BASE_URL_PRODUCTION + "addCustomers.php", {
        method: 'POST',
        body: formdata
    })
    //.then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const AddPincodes = (pincode, city, deliveryCharges, KMIndex, DozenIndex, JudiIndex, PackIndex) => {
    let formdata = new FormData();
    formdata.append("pincode", pincode);
    formdata.append("city", city);
    formdata.append("user_id", "1");
    formdata.append("delivery_charges", deliveryCharges);
    formdata.append("KM_index", KMIndex);
    formdata.append("Dozen_index", DozenIndex);
    formdata.append("Judi_index", JudiIndex);
    formdata.append("Pack_index", PackIndex);
    return fetch(BASE_URL_PRODUCTION + "addPincode.php", {
        method: 'POST',
        body: formdata
    })
    //.then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const AddVendor= (VendorName, VendorMobile, VendorLocation) => {
    let formdata = new FormData();
    formdata.append("vendor_name", VendorName);
    formdata.append("vendor_contact", VendorMobile);
    formdata.append("vendor_location", VendorLocation);
    return fetch(BASE_URL_PRODUCTION + "addVendor.php", {
        method: 'POST',
        body: formdata
    })
    //.then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const AddWasteResale= (customer_name, customer_mobile, customer_id, product_id, product_name, resale_price, resale_quantity, resale_total) => {
    let formdata = new FormData();
    formdata.append("customer_name", customer_name);
    formdata.append("customer_mobile", customer_mobile);
    formdata.append("customer_id", customer_id);
    formdata.append("product_id", product_id);
    formdata.append("product_name", product_name);
    formdata.append("resale_price", resale_price);
    formdata.append("resale_quantity", resale_quantity);
    formdata.append("resale_total", resale_total);
    return fetch(BASE_URL_PRODUCTION + "addWasteResale.php", {
        method: 'POST',
        body: formdata
    })
    //.then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const AddInventoryAPI = (ProductID, ProductName, ProductQuantity, PurchasePrice, VendorID, VendorName, VendorMobile, VendorLocation, LeviTax, PattaKadata, date) => {
    let formdata = new FormData();
    formdata.append("product_id", ProductID);
    formdata.append("product_name", ProductName);
    formdata.append("quantity", ProductQuantity);
    formdata.append("purchase_price", PurchasePrice);
    formdata.append("vendor_id", VendorID);
    formdata.append("vendor_name", VendorName);
    formdata.append("vendor_contact", VendorMobile);
    formdata.append("vendor_location", VendorLocation);
    formdata.append("user_id", "1");
    formdata.append("levitax", LeviTax);
    formdata.append("pattakadata", PattaKadata);
    formdata.append("purchase_date", date);
    console.log(formdata);
    return fetch(BASE_URL_PRODUCTION + "addInventory.php", {
        method: 'POST',
        body: formdata
    })
    //.then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const AddBillOrder = (billid, billdata, totalamount, customerid, customerName, customerMobile, date) => {
    let formdata = new FormData();
    formdata.append("billid", billid);
    formdata.append("billdata", billdata);
    formdata.append("total_amount", totalamount);
    formdata.append("customer_id", customerid);
    formdata.append("customer_name", customerName);
    formdata.append("customer_mobile", customerMobile);
    formdata.append("userid", "1");
    formdata.append("date", date);
    return fetch(BASE_URL_PRODUCTION + "addBillWithoutImage.php", {
        method: 'POST',
        body: formdata
    })
    //.then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const AddUsersNew = (name, username, mobile, password, role) => {
    let formdata = new FormData();
    formdata.append("name", name);
    formdata.append("company_name", "");
    formdata.append("username", username);
    formdata.append("mobile", mobile);
    formdata.append("password", password);
    formdata.append("role", role);
    formdata.append("masteruserid", "1");
    return fetch(BASE_URL_PRODUCTION + "addUser.php", {
        method: 'POST',
        body: formdata
    })
    //.then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const EditProduct = (id, productPrice, productCategory, productName, productActualPrice, productDescription, productAvailability, productUnit, productSize, productStorageTips, productDisclaimer, productCountryOfOrigin) => {
    let formdata = new FormData();
    formdata.append("discount", productPrice);
    formdata.append("productName", productName);
    formdata.append("price", productActualPrice);
    formdata.append("category", productCategory);
    formdata.append("description", productDescription);
    formdata.append("availability", productAvailability);
    formdata.append("unit", productUnit);
    formdata.append("size", productSize);
    formdata.append("storage_tips", productStorageTips);
    formdata.append("disclaimer", productDisclaimer);
    formdata.append("country_of_origin", productCountryOfOrigin);
    formdata.append("id", id);
    return fetch(BASE_URL_PRODUCTION + "editProduct.php", {
        method: 'POST',
        body: formdata
    })
    //.then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const EditPincode = (id, delivery_charges, KM_index, Dozen_index, Judi_index, Pack_index) => {
    let formdata = new FormData();
    formdata.append("id", id);
    formdata.append("delivery_charges", delivery_charges);
    formdata.append("KM_index", KM_index);
    formdata.append("Dozen_index", Dozen_index);
    formdata.append("Judi_index", Judi_index);
    formdata.append("Pack_index", Pack_index);
    return fetch(BASE_URL_PRODUCTION + "editPincode.php", {
        method: 'POST',
        body: formdata
    })
    //.then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const EditPickUpPoint = (id, address) => {
    let formdata = new FormData();
    formdata.append("address", address);
    formdata.append("id", id);
    return fetch(BASE_URL_PRODUCTION + "editPickUpPoint.php", {
        method: 'POST',
        body: formdata
    })
    //.then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const EditBillStatus = (billId) => {
    let formdata = new FormData();
    formdata.append("billid", billId);
    formdata.append("order_status", "ACCEPTED");
    return fetch(BASE_URL_PRODUCTION + "editBillStatus.php", {
        method: 'POST',
        body: formdata
    })
    //.then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const SetOrderCancelled = (billId) => {
    let formdata = new FormData();
    formdata.append("billid", billId);
    formdata.append("order_status", "CANCELLED");
    return fetch(BASE_URL_PRODUCTION + "editBillStatus.php", {
        method: 'POST',
        body: formdata
    })
    //.then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const SetOrderRefund = (billId) => {
    let formdata = new FormData();
    formdata.append("billid", billId);
    formdata.append("order_status", "REFUNDED");
    return fetch(BASE_URL_PRODUCTION + "editBillStatus.php", {
        method: 'POST',
        body: formdata
    })
    //.then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const EditWasteToInActive = (id) => {
    let formdata = new FormData();
    formdata.append("id", id);
    formdata.append("status", "REFUNDED");
    return fetch(BASE_URL_PRODUCTION + "editWasteProduct.php", {
        method: 'POST',
        body: formdata
    })
    //.then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const EditBillStatusPacked = (billId) => {
    let formdata = new FormData();
    formdata.append("billid", billId);
    formdata.append("order_status", "PACKED");
    return fetch(BASE_URL_PRODUCTION + "editBillStatus.php", {
        method: 'POST',
        body: formdata
    })
    //.then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const EditOrdersStatus = (status) => {
    let formdata = new FormData();
    formdata.append("status", status);
    return fetch(BASE_URL_PRODUCTION + "editOrderPlaceStatus.php", {
        method: 'POST',
        body: formdata
    })
    //.then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const EditDeliveryBoy = (billId, deliveryBoyID, deliveryBoyName, deliveryBoyMobile) => {
    let formdata = new FormData();
    formdata.append("billid", billId);
    formdata.append("delivery_boy_id", deliveryBoyID);
    formdata.append("delivery_boy_name", deliveryBoyName);
    formdata.append("delivery_boy_mobile", deliveryBoyMobile);
    return fetch(BASE_URL_PRODUCTION + "editBillDeliveryBoy.php", {
        method: 'POST',
        body: formdata
    })
    //.then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const AddJama = (vendor_id, vendor_name, amount, vendor_mobile, date) => {
    let formdata = new FormData();
    formdata.append("vendor_id", vendor_id);
    formdata.append("vendor_name", vendor_name);
    formdata.append("amount", amount);
    formdata.append("vendor_mobile", vendor_mobile);
    formdata.append("date", date);
    return fetch(BASE_URL_PRODUCTION + "addJamaEntry.php", {
        method: 'POST',
        body: formdata
    })
    //.then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

export const EditInventory = (id, productPrice) => {
    let formdata = new FormData();
    formdata.append("amount_paid", productPrice);
    formdata.append("id", id);
    return fetch(BASE_URL_PRODUCTION + "editAmountPaidInventory.php", {
        method: 'POST',
        body: formdata
    })
    //.then(res => res.json())
    .then(response => {
        return response;
    })
    .catch(error => {
        console.error(error);
    });
}

// For Test Video