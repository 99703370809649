import { Rate, Space, Table, Typography, Modal, Input, DatePicker } from "antd";
import Avatar from "antd/es/avatar/avatar";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useReactToPrint } from 'react-to-print';
import { getCustomerAddresses, getCustomers, getInventory, getOrders, getOrdersByCustomer, EditOrder, getPlacedOrders, getAcceptedOrders, getOrdersFromDateToDate } from "../../API";

function Customers() {

    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [datasource, setDatasource] = useState([]);
    const [customerOrders, setCustomerOrders] = useState([]);
    const [datasourceAccepted, setDatasourceAccepted] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showEditProduct, setShowEditProduct] = useState(true);
    const [showCustomerOrders, setShowCustomerOrders] = useState(false);
    const [selectedCustomers, setSelectedCustomer] = useState('');
    const [showAddress, setShowAddress] = useState(false);
    const [customerAddress, setCustomerAddress] = useState([])
    const [selectedCustomerNameAddress, setSelectedCustomerNameAddress] = useState("")
    const [selectedCustomerName, setSelectedCustomerName] = useState('');
    const [selectedCustomerMobile, setSelectedCustomerMobile] = useState('');
    const [selectedDeliveryTimeSlot, setSelectedDeliveryTimeSlot] = useState('');
    const [selectedBill, setSelectedBill] = useState('');
    const [selectedBillID, setSelectedBillID] = useState('');
    const [selectedBillDate, setSelectedBillDate] = useState('');
    const [selectedTotalAmount, setSelectedTotalAmount] = useState('');
    const [selectedAdvancePayment, setSelectedAdvancePayment] = useState('');
    const [selectedAddress, setSelectedAddress] = useState('');
    const [selectedDeliveryCharges, setSelectedDeliveryCharges] = useState('');
    const [selectedProductList, setSelectedProductList] = useState([]);
    const [editProductBool, setEditProductBool] = useState(false);
    const [editID, setEditID] = useState(false);
    const [editQuantity, setEditQuantity] = useState(false);
    const [editPrice, setEditPrice] = useState(false);
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("");
    const componentRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        getCustomers("1").then(res => {
            setLoading(false);
            setDatasource(res.Customers)
        })
    }, []);

    const loadCustomerAddress = (userid) => {
        getCustomerAddresses(userid)
            .then(res => {
                setCustomerAddress(res.Addresses)
                setShowAddress(true)
            })
    }

    const getCustomerOrders = (mobile) => {
        setStartDate("");
        setEndDate("");
        getOrdersByCustomer(mobile).then(res => {
            setShowCustomerOrders(true);
            setCustomerOrders(res.Bills)
        })
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const editOrder = (id, qty, price) => {
        let tempProductList = selectedProductList;
        for (let i = 0; i < tempProductList.length; i++) {
            if (tempProductList[i].id === id) {
                tempProductList[i].qty = qty;
                tempProductList[i].price = price
            } else {

            }
        }
        setSelectedProductList(tempProductList);
        calculateTotal();
    }

    const calculateTotal = () => {
        let tempTotalAmount = 0;
        for (let i = 0; i < selectedProductList.length; i++) {
            tempTotalAmount = tempTotalAmount + (parseFloat(selectedProductList[i].price) * parseFloat(selectedProductList[i].qty));
        }
        setEditProductBool(false)
        setSelectedTotalAmount(tempTotalAmount);
        editOrderMethod(tempTotalAmount);
    }

    const editOrderMethod = (tempTotalAmount) => {
        console.log(JSON.stringify(selectedProductList));
        EditOrder(selectedBill, JSON.stringify(selectedProductList), tempTotalAmount)
            .then(res => {
                alert("Order " + selectedBillID + " Edited Successfully.");
                getPlacedOrders("1").then(res => {
                    setLoading(false);
                    setDatasource(res.Bills)
                });
                getAcceptedOrders("1")
                    .then(res => {
                        console.log(res);
                        setDatasourceAccepted(res.Bills);
                    })
                getCustomers("1").then(res => {
                    //setLoading(false);
                    setCustomers(res.Customers)
                })
            })
    }

    const getDateWiseReport = () => {
        if (startDate === "") {
            alert("Please select start date.")
        } else if (endDate === "") {
            alert("Please select end date.")
        } else {
            getOrdersFromDateToDate(startDate, endDate, selectedCustomerMobile)
            .then(res => {
                setCustomerOrders(res.Bills)
            })
        }
    }

    return (
        <Space size={20} direction="vertical">
            <Typography.Title level={4}>
                Customers
            </Typography.Title>
            <Link to="/addcustomers" className="btn btn-danger" type="submit">
                Add Customers
            </Link>
            <Table
                columns={[
                    // {
                    //     title: "Image",
                    //     dataIndex: "image",
                    //     render: (link) => {
                    //         return <Avatar src={link} />
                    //     }
                    // },
                    {
                        title: "Customer Name",
                        dataIndex: "customerName"
                    },
                    {
                        title: "Phone",
                        dataIndex: "mobile"
                    },
                    {
                        title: "",
                        dataIndex: "billdata",
                        render: (value, data) => <button className="btn btn-danger" onClick={() => {
                            setSelectedCustomerNameAddress(data.customerName)
                            loadCustomerAddress(data.mobile)
                        }}>Show Addresses</button>
                    },
                    {
                        title: "",
                        dataIndex: "billdata",
                        render: (value, data) => <button className="btn btn-danger" onClick={() => {
                            //navigate('/customerorders')
                            setSelectedCustomer(data.customerName);
                            setSelectedCustomerMobile(data.mobile)
                            getCustomerOrders(data.mobile);
                        }}>Show Orders</button>
                    },
                    // {
                    //     title: "Email",
                    //     dataIndex: "email"
                    // },
                    // {
                    //     title: "Username",
                    //     dataIndex: "username"
                    // },
                    // {
                    //     title: "Pincode",
                    //     dataIndex: "address",
                    //     render: (address) => {
                    //         return <span>{address.postalCode}</span>
                    //     }
                    // },
                    // {
                    //     title: "Address",
                    //     dataIndex: "address",
                    //     render: (address) => {
                    //         return <span>{address.address} {address.city} {address.state}</span>
                    //     }
                    // },

                ]}
                loading={loading}
                dataSource={datasource}
                pagination={{
                    pageSize: 5
                }}>

            </Table>

            {showCustomerOrders ?
                <Space direction="vertical">
                    <Typography.Title level={4}>
                        Orders by {selectedCustomers}
                    </Typography.Title>
                    <Space direction="horizontal" >
                        <div className="mb-3">
                            <label className="form-label" htmlFor="purchasedfrom">
                                Select Start Date
                            </label>
                            <DatePicker className="form-control" onChange={(dateString) => {
                                // setStartDate(moment(date).format('YYYY-MM-DD'))
                                //console.log(moment(dateString).format('YYYY-MM-DD'))
                                //console.log(dateString)
                                var tempStartDate = new Intl.DateTimeFormat('en-UK', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(dateString);
                                console.log(tempStartDate.split('/').reverse().join('-'))
                                setStartDate(tempStartDate.split('/').reverse().join('-'))
                            }} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="purchasedfrom">
                                Select End Date
                            </label>
                            <DatePicker className="form-control" onChange={(dateString) => {
                                var tempStartDate = new Intl.DateTimeFormat('en-UK', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(dateString);
                                console.log(tempStartDate.split('/').reverse().join('-'))
                                setEndDate(tempStartDate.split('/').reverse().join('-'))
                            }} />
                        </div>
                        <button className="btn btn-danger mt-3" onClick={() => getDateWiseReport()}>Show Orders Between Date</button>
                    </Space>
                    <Table
                        columns={[
                            // {
                            //     title: "Image",
                            //     dataIndex: "image",
                            //     render: (link) => {
                            //         return <Avatar src={link} />
                            //     }
                            // },
                            {
                                title: "Bill Number",
                                dataIndex: "billid"
                            },
                            {
                                title: "Bill Date",
                                dataIndex: "date"
                            },
                            {
                                title: "Order Type",
                                dataIndex: "order_type"
                            },
                            {
                                title: "Order Status",
                                dataIndex: "order_status"
                            },
                            {
                                title: "Total Amount",
                                dataIndex: "total_amount"
                            },
                            {
                                title: "Delivery Charges",
                                dataIndex: "delivery_charges"
                            },
                            {
                                title: "Advance Payment",
                                dataIndex: "advance_payment_amount"
                            },
                            {
                                title: "",
                                dataIndex: "billdata",
                                render: (value, data) => <button className="btn btn-danger" onClick={() => {
                                    setSelectedCustomerName(data.customer_name);
                                    setSelectedCustomerMobile(data.customer_mobile);
                                    setSelectedBill(data.id);
                                    setSelectedBillID(data.billid);
                                    setSelectedBillDate(data.date);
                                    setSelectedTotalAmount(data.total_amount);
                                    setSelectedAddress(data.address)
                                    setSelectedDeliveryCharges(data.delivery_charges)
                                    if (data.order_type === "ONLINE PAYMENT") {
                                        setSelectedAdvancePayment(data.total_amount)
                                    } else {
                                        setSelectedAdvancePayment(data.advance_payment_amount)
                                    }
                                    let productListSelected = [];
                                    productListSelected = [...JSON.parse(data.billdata)];
                                    setSelectedProductList(productListSelected);
                                    //console.log(productListSelected);
                                    //openDialogBill();
                                    setIsModalVisible(true);
                                }}>View Details</button>
                            },
                            // {
                            //     title: "Email",
                            //     dataIndex: "email"
                            // },
                            // {
                            //     title: "Username",
                            //     dataIndex: "username"
                            // },
                            // {
                            //     title: "Pincode",
                            //     dataIndex: "address",
                            //     render: (address) => {
                            //         return <span>{address.postalCode}</span>
                            //     }
                            // },
                            // {
                            //     title: "Address",
                            //     dataIndex: "address",
                            //     render: (address) => {
                            //         return <span>{address.address} {address.city} {address.state}</span>
                            //     }
                            // },

                        ]}
                        loading={loading}
                        dataSource={customerOrders}
                        pagination={{
                            pageSize: 5
                        }}>

                    </Table>
                </Space> : null}
            <Modal
                visible={showAddress}
                closable={false}
                okText="Ok"
                cancelText="Close"
                onOk={() => {
                    setShowAddress(false);
                }}
                onCancel={() => {
                    setShowAddress(false);
                }}>
                <div id="testId" class="container mt-10 mb-7">
                    <div class="row justify-content-center">
                        <div class="col-lg-12 col-xl-15">
                            <div class="card">
                                <div class="card-body p-5">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="text-muted mb-2">Customer Addresses</div>
                                            <strong>{selectedCustomerNameAddress}</strong>
                                        </div>
                                    </div>
                                    <div>
                                        <Table
                                            columns={[
                                                {
                                                    title: "Address",
                                                    dataIndex: "address"
                                                },
                                                {
                                                    title: "Pincode",
                                                    dataIndex: "pincode"
                                                },
                                            ]}
                                            loading={loading}
                                            dataSource={customerAddress}
                                            pagination={false}>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                visible={editProductBool}
                okText="Save"
                closable={false}
                cancelText="Close"
                onOk={() => {
                    editOrder(editID, editQuantity, editPrice)
                    //downloadPdfDocument("test");
                }}
                onCancel={() => {
                    setEditProductBool(false);
                }}>
                <div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="stock">
                            Enter Waste Quantity
                        </label>
                        <Input value={editQuantity} className="form-control mb-1" type="number" id="stock" required onChange={(evt) => { setEditQuantity(evt.target.value) }} />
                    </div>
                </div>
            </Modal>
            <Modal
                visible={isModalVisible}
                okText="Print"
                closable={false}
                cancelText="Close"
                // onOk={() => {
                //     //downloadPdfDocument("#"+selectedBillID+"_Bill_PDF");
                //     setEditProduct()
                // }}
                onOk={() => {
                    setShowEditProduct(false)
                    setTimeout(() => {
                        handlePrint()
                    }, 500)
                    setTimeout(() => {
                        setShowEditProduct(true)
                    }, 500)
                }}
                onCancel={() => {
                    setSelectedAdvancePayment('')
                    setIsModalVisible(false);
                }}>
                <div ref={componentRef} id="testId" class="container mt-10 mb-7">
                    <div class="row justify-content-center">
                        <div class="col-lg-15 col-xl-15">
                            <div class="card">
                                <div class="card-body p-5">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="text-muted mb-2">Customer Name</div>
                                            <strong>{selectedCustomerName}</strong>
                                        </div>
                                        <div class="col-md-6 text-md-end">
                                            <div class="text-muted mb-2">Customer Mobile</div>
                                            <strong>{selectedCustomerMobile}</strong>
                                        </div>
                                    </div>

                                    <div class="border-top border-gray-200 pt-4 mt-4 mb-5">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="text-muted mb-2">Order No.</div>
                                                <strong>#{selectedBillID}</strong>
                                            </div>
                                            <div class="col-md-6 text-md-end">
                                                <div class="text-muted mb-2">Payment Date</div>
                                                <strong>{selectedBillDate}</strong>
                                            </div>
                                        </div>
                                        <div class="border-top border-gray-200 pt-4 mt-4 mb-1"></div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="text-muted mb-2">Address.</div>
                                                <strong>{selectedAddress}</strong>
                                            </div>
                                            <div class="col-md-6 text-md-end">
                                                <div class="text-muted mb-2">Selected Time Slot</div>
                                                <strong>{selectedDeliveryTimeSlot}</strong>
                                            </div>
                                        </div>
                                    </div>
                                    {showEditProduct ?
                                        <div>
                                            <Table
                                                columns={[
                                                    {
                                                        title: "Product",
                                                        dataIndex: "title"
                                                    },
                                                    {
                                                        title: "Weight",
                                                        dataIndex: "qty"
                                                    },
                                                    {
                                                        title: "Rate",
                                                        dataIndex: "price",
                                                        render: (value) => <span>₹{value}</span>
                                                    },
                                                    {
                                                        title: "Cost",
                                                        dataIndex: "price",
                                                        render: (value, data) => <span>₹{(parseFloat(data.price) * parseFloat(data.qty))}</span>
                                                    },
                                                    {
                                                        title: "",
                                                        dataIndex: "price",
                                                        render: (value, data) =>
                                                            <button className="btn btn-danger" onClick={() => {
                                                                //acceptOrder(data.billid);
                                                                setEditProductBool(true)
                                                                setEditID(data.id);
                                                                setEditQuantity(data.qty);
                                                                setEditPrice(data.price);
                                                            }}>Edit Product</button>
                                                    },
                                                ]}
                                                loading={loading}
                                                dataSource={selectedProductList}
                                                pagination={false}>
                                            </Table>
                                        </div> :
                                        <div>
                                            <Table
                                                columns={[
                                                    {
                                                        title: "Product",
                                                        dataIndex: "title"
                                                    },
                                                    {
                                                        title: "Weight",
                                                        dataIndex: "qty"
                                                    },
                                                    {
                                                        title: "Rate",
                                                        dataIndex: "price",
                                                        render: (value) => <span>₹{value}</span>
                                                    },
                                                    {
                                                        title: "Cost",
                                                        dataIndex: "price",
                                                        render: (value, data) => <span>₹{(parseFloat(data.price) * parseFloat(data.qty))}</span>
                                                    },
                                                ]}
                                                loading={loading}
                                                dataSource={selectedProductList}
                                                pagination={false}>
                                            </Table>
                                        </div>}

                                    <div class="mt-5">
                                        <div class="d-flex justify-content-end mt-3">
                                            <h5 class="me-3">Delivery Charges:</h5>
                                            <h5>₹{selectedDeliveryCharges}</h5>
                                        </div>
                                        {selectedAdvancePayment === '' ? null :
                                            <div>
                                                <div class="d-flex justify-content-end mt-3">
                                                    <h5 class="me-3">Advance Payment:</h5>
                                                    <h5>₹{selectedAdvancePayment}</h5>
                                                </div>
                                                <div class="d-flex justify-content-end mt-3">
                                                    <h5 class="me-3">Pending Payment:</h5>
                                                    <h5>₹{parseInt(selectedTotalAmount) - parseInt(selectedAdvancePayment)}</h5>
                                                </div>
                                            </div>}
                                        <div class="d-flex justify-content-end mt-3">
                                            <h5 class="me-3">Total:</h5>
                                            <h5>₹{selectedTotalAmount}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </Space>
    )
}

export default Customers;