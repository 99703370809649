import { AutoComplete } from 'antd';
import Input from 'antd/es/input/Input';
import React, { useState, useEffect } from 'react';
import { AddBillOrder, getCustomers, getProducts } from '../../API';

const customers = [
    {
        label: "Sushant Mali",
        value: "Sushant Mali"
    },
    {
        label: "Nishant Avhad",
        value: "Nishant Avhad"
    },
]

var productsSelected = [];

const AddOrders = () => {
    const [formStatus, setFormStatus] = useState('Add Orders');
    const [selectedOptionCustomer, setSelectedOptionCustomer] = useState('');
    const [inputValueCustomer, setInputValueCustomer] = useState('');
    const [selectedProduct, setSelectedProduct] = useState('');
    const [selectedProductID, setSelectedProductID] = useState('');
    const [selectedProductPrice, setSelectedProductPrice] = useState('');
    const [selectedProductQuan, setSelectedProductQuan] = useState('');
    const [selectedProductsList, setSelectedProductsList] = useState([]);
    const [products, setProducts] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [selectedCustomerID, setSelectedCustomerID] = useState("");
    const [selectedCustomerName, setSelectedCustomerName] = useState("");
    const [selectedCustomerMobile, setSelectedCustomerMobile] = useState("");

    useEffect(() => {
        getProducts("1")
        .then(res => {
            //setLoading(false);
            console.log(res.Products);
            setProducts(res.Products);
        })
    }, []);

    useEffect(() => {
        //setLoading(true);
        getCustomers("1").then(res => {
            //setLoading(false);
            setCustomers(res.Customers)
        })
    }, []);

    const PlaceOrder = () => {
        if (selectedProductsList.length === 0) {
            alert('Please add at least one product.')
        } else if (selectedCustomerID === '') {
            alert('Please add Customer.');
        } else {
            let newDate = new Date()
            let date = newDate.getDate();
            if (date < 10) {
                date = "0"+date;
            } else {
    
            }
            let month = newDate.getMonth() + 1;
            if (month < 10) {
                month = "0"+month;
            } else {
    
            }
            let year = newDate.getFullYear();
            let finaldate = date+"-"+month+"-"+year;
            console.log(finaldate);
    
            let billid = "EASY"+finaldate+getRandomInt(1000, 9999);
            var total = 0;
            for (let i = 0; i < selectedProductsList.length; i++) {
                total = total + (parseInt(selectedProductsList[i].price)*parseInt(selectedProductsList[i].qty))
            }
    
            AddBillOrder(billid, JSON.stringify(selectedProductsList), total, selectedCustomerID, selectedCustomerName, selectedCustomerMobile, finaldate)
            .then(res => {
                setInputValueCustomer('');
                setSelectedCustomerID('');
                setSelectedCustomerName('');
                setSelectedCustomerMobile('');
                setSelectedProduct('');
                setSelectedProductID('');
                setSelectedProductPrice('');
                setSelectedProductsList([]);
                setSelectedProductQuan('');
                alert("Order Placed Successfully.")
            })
        }
        
    }

    const getRandomInt = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const addProductToOrder = (id, title, price, quan) => {
        //productsSelected = selectedProductsList;

        if (id === "") {

        } else if (title === "") {

        } else if (price === "") {

        } else if (quan === "") {

        } else {
            if (checkProductAlreadyPresent(title)) {

            } else {
                let tempObject = {
                    id: id,
                    title: title,
                    qty: quan,
                    price: price
                }
                productsSelected = [...selectedProductsList, tempObject];
                setSelectedProductsList(productsSelected);
            }
        }
    }

    const checkProductAlreadyPresent = (title) => {
        var resultBoolean = false;
        if (selectedProductsList.length === 0) {

        } else {
            for (let i = 0; i < selectedProductsList.length; i++) {
                if (selectedProductsList[i].title === title) {
                    resultBoolean = true;
                } else {

                }
            }
        }
        return resultBoolean;
    }

    return (
        <>
            <div className="container">
                <div className="row mx-2">
                    <div className="container">
                        <h2 className="mb-3">Add Order Details</h2>
                        {/* <form onSubmit={onSubmit}> */}
                        <div className="mb-3">
                            <label className="form-label" htmlFor="purchasedfrom">
                                Customer Name
                            </label>
                            <AutoComplete
                                className="form-control"
                                //value={inputValueCustomer}
                                options={customers}
                                autoFocus={true}
                                style={{ width: 200 }}
                                filterOption={(inputValue, option) =>
                                    option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                                onSelect={(value, data) => {
                                    setInputValueCustomer(value);
                                    setSelectedCustomerID(data.id);
                                    setSelectedCustomerName(data.customerName);
                                    setSelectedCustomerMobile(data.mobile);
                                }}
                                onChange={() => {
                                    setInputValueCustomer("");
                                }}

                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="purchasedfrom">
                                Add Products
                            </label>
                            <label className="form-label" htmlFor="purchasedfrom">
                                Select Product
                            </label>
                            <AutoComplete
                                className="form-control"
                                //value={selectedProduct}
                                options={products}
                                autoFocus={true}
                                style={{ width: 200 }}
                                filterOption={(inputValue, option) =>
                                    option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                                onSelect={(value, data) => {
                                    setSelectedProduct(value);
                                    setSelectedProductID(data.id);
                                    setSelectedProductPrice(data.price);
                                    //addProductToOrder(data.id, data.value, data.price);
                                }}
                                onChange={() => {
                                    setSelectedProduct("");
                                }}
                            />
                            <label className="form-label mt-1" htmlFor="purchasedfrom">
                                Enter Quantity
                            </label>
                            <Input value={selectedProductQuan} className="form-control" type="number" id="quantity" required onChange={(evt) => { setSelectedProductQuan(evt.target.value) }} />
                            <button className="btn btn-danger mt-3" onClick={() => {
                                console.log("Product Added");
                                addProductToOrder(selectedProductID, selectedProduct, selectedProductPrice, selectedProductQuan);
                            }}>
                                Add Product to Order
                            </button>

                        </div>
                        {/* </form> */}
                    </div>
                    <button className="btn btn-danger mt-1 mb-3 my-4" style={{ width: 200, marginLeft: 10 }} onClick={() => PlaceOrder()}>
                        Place Order
                    </button>
                    <div className="mx-2">
                        <h2 className="mb-3">Selected Products</h2>
                    </div>
                    {selectedProductsList.map((product, index) => {
                        return (
                            <div className="col-md-4 mb-4">
                                <div className="modal-content rounded-4 shadow text-center p-2" key={product.id}>
                                    <div className="card-body">
                                        <h5 className="card-title mb-0 fs-6 mt-1">{product.title.substring(0, 12)}...</h5>
                                        <p className="card-text lead fw-bold">₹{product.price}</p>
                                        <p className="card-text lead fw-bold fs-6">Quantity: {product.qty}</p>
                                        {/* <div style={{ height: '40px' }} className="nav justify-content-center text-white bg-success rounded-4 mx-5">
                                            <button style={{ height: '40px' }} className="btn text-white bg-success fw-bold" onClick={() => {
                                                //dispatch(delItem(product));
                                                //setProductQty(product.id, "MINUS");
                                            }}>-</button>
                                            <p style={{ height: '40px', marginTop: '8px' }} className="text-white fs-6 mx-3 fw-bold">{product.qty}</p>
                                            <button style={{ height: '40px' }} className="btn text-white bg-success fw-bold" onClick={() => {
                                                //dispatch(addItem(product));
                                                //setProductQty(product.id, "ADD");
                                            }}>+</button>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default AddOrders;