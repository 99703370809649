import { Rate, Space, Table, Typography } from "antd";
import Avatar from "antd/es/avatar/avatar";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getCustomers, getInventory, getOrders, getRefunds, getUsers, getVendors } from "../../API";

function Refunds() {

    const [loading, setLoading] = useState(false);
    const [datasource, setDatasource] = useState([]);

    useEffect(() => {
        setLoading(true);
        getRefunds("1").then(res => {
            setLoading(false);
            setDatasource(res.Refunds)
        })
    }, []);

    return (
        <Space size={20} direction="vertical">
            <Typography.Title level={4}>
                Refunds
            </Typography.Title>
            <Table
                columns={[
                    {
                        title: "Username",
                        dataIndex: "username"
                    },
                    {
                        title: "Mobile",
                        dataIndex: "usermobile"
                    },
                    {
                        title: "Order ID",
                        dataIndex: "orderid"
                    },
                    {
                        title: "Refund Amount",
                        dataIndex: "amount"
                    },
                    {
                        title: "Payment ID",
                        dataIndex: "paymentid"
                    },
                    {
                        title: "Refund Type",
                        dataIndex: "refund_type"
                    },
                    // {
                    //     title: "Email",
                    //     dataIndex: "email"
                    // },
                    // {
                    //     title: "Username",
                    //     dataIndex: "username"
                    // },
                    // {
                    //     title: "Pincode",
                    //     dataIndex: "address",
                    //     render: (address) => {
                    //         return <span>{address.postalCode}</span>
                    //     }
                    // },
                    // {
                    //     title: "Address",
                    //     dataIndex: "address",
                    //     render: (address) => {
                    //         return <span>{address.address} {address.city} {address.state}</span>
                    //     }
                    // },

                ]}
                loading={loading}
                dataSource={datasource}
                pagination={{
                    pageSize: 5
                }}>

            </Table>
        </Space>
    )
}

export default Refunds;