import { Rate, Space, Table, Typography } from "antd";
import Avatar from "antd/es/avatar/avatar";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getCustomers, getInventory, getOrders, getUsers, getVendors } from "../../API";
import { Document, Page } from "react-pdf";

function PrivacyPolicy() {

    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }){
        setNumPages(numPages);
    }

    return (
        <>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <strong>
                <span style={{ fontSize: 21, fontFamily: '"Candara",sans-serif' }}>
                    Privacy Policy
                </span>
                </strong>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16 }}>
                1. Background Geo Location: Background Geo collects location data to enable tracking your trips and visit to the farmer location and calculate distance travelled even the app is closed or not in use This data will uploaded to our internal portal of FarmEasy here may user can check its travelling history.
                </span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16 }}>
                2. Your Privacy - Our Commitment: We respect and value your trust in us
                and your privacy is very important to us. We do not publish, sell or share
                your personal information to third parties for their marketing purposes
                without your explicit consent. Please read this Privacy Policy to learn
                more about the ways in which we use and protect your personal information.
                </span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16 }}>
                3. Policy Updates: Our privacy policy is subject to change at any time
                without notice. To make sure you are aware of any changes, please review
                this policy periodically. Such changes shall be effective immediately upon
                posting to this Website
                </span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16 }}>
                4. Terms and Conditions STATED UPFRONT AND BOLDLY: By using this Website
                you agree to be bound by the terms and conditions of this Privacy Policy.
                If at any time you do not agree to the terms of this Privacy Policy,
                please do not use this Website and refrain from purchasing from us online.
                By mere use of the Website you expressly consent to our use and disclosure
                of your personal information in accordance with this Privacy Policy. By
                accessing the services provided by us and/or your placing an order on the
                website you agree to the collection and use of your data by us in the
                manner provided in this Privacy Policy.
                </span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16 }}>
                5. User/Buyer Information Collected i. Collection of Information: You can
                browse the Website without telling us who you are or revealing any
                personal information about yourself. Once you give us your personal
                information, you are not anonymous to us. Wherever possible, we indicate
                which fields are required and which fields are optional. You may have the
                option not to provide information by choosing not to use a particular
                service or feature on the Website. We may automatically track certain
                information about you based upon your behavior on our Website. We use this
                information to do internal research on our users' demographics, interests,
                and behavior to better understand, protect and serve our users. This
                information is compiled and analyzed on an aggregated basis. This
                information may include the URL that you just came from (whether this URL
                is on our Website or not), which URL you next go to (whether this URL is
                on our Website or not), your computer browser information, and your IP
                address etc. We will collect anonymous traffic information from you when
                you visit our Website. We will collect personally identifiable information
                about you only as part of a voluntary registration process, on-line
                survey, or contest or any combination thereof.
                </span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16 }}>
                7. Use of the Information Collected We use personal information to provide
                the services you request. To the extent we use your personal information
                for marketing and advertisement to you, we will provide you the ability to
                opt-out of such uses. You agree that we may use personal information about
                you to improve our marketing and promotional efforts, to analyze site
                usage, improve the Website's content and product offerings, and customize
                the Website's content, layout, and services. You agree that we may use
                your personal information to contact you. We use your personal information
                to:
                </span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16 }}>
                In our efforts to continually improve our product and service offerings,
                we collect and analyze demographic and profile data about our users'
                activity on our Website. We may identify and use your IP address to help
                diagnose problems with our server, and to administer our Website. Your IP
                address is also used to identify you and to gather broad demographic
                information. We will occasionally ask you to complete optional online
                surveys. These surveys may ask you for contact information and demographic
                information (like zip code, age, or income level). We use this data to
                tailor your experience at our Website, providing you with content that we
                think you might be interested in and to display content according to your
                preferences.
                </span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16 }}>
                8. Information We Share We do not sell or otherwise disclose personal
                information about our customers, except as described herein. We may share
                personal information with our other corporate entities and affiliates to
                help detect and prevent identity theft, fraud and other potentially
                illegal acts, detect correlate related or multiple accounts to prevent
                abuse of our services, and to facilitate joint or co-branded services that
                you request where such services are provided by more than one corporate
                entity. We may disclose personal information if required to do so by law
                or in the good faith belief that such disclosure is reasonably necessary
                to respond to subpoenas, court orders, or other legal process. We may
                disclose personal information to law enforcement offices, third party
                rights owners, or others in the good faith belief that such disclosure is
                reasonably necessary to enforce our Terms or Privacy Policy, respond to
                claims that an advertisement, posting or other content violates the rights
                of a third party, or protect the rights, property or personal safety of
                our users. We and our affiliates will share / sell some or all of your
                personal information with another business entity should we (or our
                assets) plan to merge with, or be acquired by that business entity, or
                re-organization, amalgamation, restructuring of business. Should such a
                transaction occur that other business entity (or the new combined entity)
                will be required to follow this privacy policy with respect to your
                personal information.
                </span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16 }}>
                9. User's Choice with Respect to the Information Collected We provide all
                Users/Buyers with the option to opt-out of receiving promotional,
                marketing-related communications from us after setting up an account. You
                may change your interests at any time and may opt-in or opt-out of any
                marketing / promotional / newsletters mailings. You may update your
                information and change your account settings at any time. We reserve our
                right to send you certain service related communication, considered to be
                a part of your account without offering you the facility to opt-out. Upon
                request, we will remove/block your personally identifiable information
                from our database, thereby cancelling your registration. However, your
                information may remain stored in archive on our servers even after the
                deletion or the termination of your account. If we plan to use your
                personally identifiable information for any commercial purposes, we will
                notify you at the time we collect that information and allow you to
                opt-out of having your information used for those purposes.
                </span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16 }}>
                11. Security of Information Collected Our Website has stringent security
                measures in place to protect the loss, misuse, and alteration of the
                information under our control. The secure server software (SSL) encrypts
                all information you put in before it is sent to us. Whenever you change or
                access your account information, we offer the use of a secure server. Once
                your information is in our possession we adhere to strict security
                guidelines, protecting it against unauthorized access. Our servers are
                accessible only to authorized personnel and that your information is
                shared with respective personnel on need to know basis to complete the
                transaction and to provide the services requested by you. Although we will
                endeavor to safeguard the confidentiality of your personally identifiable
                information, transmissions made by means of the Internet or telecom or
                other network/s cannot be made absolutely secure. By using this Website
                you agree that we will have no liability for disclosure of your
                information due to errors in transmission or unauthorized acts of third
                parties. To prevent unauthorized access, maintain data accuracy, and
                ensure correct use of information, we will employ reasonable security
                practices and procedures and current internet security methods and
                technologies in compliance with Information Technology Act, 2000 and rules
                made there under.
                </span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16 }}>
                13.Contact List Contact list is currently used for users to easily invite
                their friends &amp; family on the app. To ensure data privacy, contact
                list cannot be accessed or saved on Farmeasy databases or servers.
                </span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16 }}>
                14.Personal Information As part of the registration process, we collect
                the following personally identifiable information about you: Name
                including first and last name, alternate email address, mobile phone
                number and contact details, Postal code, Demographic profile (like your
                age, gender, occupation, education, address etc.) and information about
                the pages on the site you visit/access, the links you click on the site,
                the number of times you access the page and any such browsing information.
                </span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16 }}>
                15.Contact List Contact list is currently used for users to easily invite
                their friends &amp; family on the app. To ensure data privacy, contact
                lists cannot be accessed or saved on our databases or servers
                </span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16 }}>
                16.Data Security We will use technical and organisational measures to
                safeguard your Data and we store your Data on secure servers. Technical
                and organisational measures include measures to deal with any suspected
                data breach. If you suspect any misuse or loss or unauthorised access to
                your Data, please let us know immediately by contacting us by e-mail.
                </span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16 }}>
                17.Data Procession We take steps to ensure that the Data we collect under
                this Privacy Notice is processed according to the provisions of this
                Privacy Notice and the requirements of applicable law. &nbsp;To ensure
                that your Data receives an adequate level of protection, we have put in
                place appropriate written contracts with Partners and Service Providers
                that we share your Data with. This ensures your Data is treated by such
                parties in a way that is consistent with applicable law.
                </span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16 }}>
                18.Privacy Notice Changes Our business changes constantly and our Privacy
                Notice will change also, you should check our website and mobile
                application frequently to see recent changes. The updated version will be
                effective as soon as it is accessible. Any changes will be immediately
                posted on our website and mobile application and you are deemed to have
                accepted the terms of the updated Privacy Notice on your first use of our
                website or mobile application or first purchase of the products and/or
                services following the alterations. We encourage you to review this
                Privacy Notice frequently to be informed of how we are protecting your
                information.
                </span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16 }}>&nbsp;</span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 16 }}>
                19.Contact Us To request to review, update, or delete your personal
                information or to otherwise reach us, please submit a request by calling
                us at 1800 267 0997 or e-mailing farmeasy1230@gmail.com You may contact us
                for information on Service Providers, Partners with whom we may share your
                Data in compliance with this Privacy Notice and applicable law. We will
                respond to your request within 30 days
                </span>
            </p>
        </>
    );
}

export default PrivacyPolicy;

//https://farmeasy.co.in/API/PDF/PrivacyPolicy.pdf