import { Rate, Space, Table, Typography, AutoComplete, Modal, Input, DatePicker } from "antd";
import Avatar from "antd/es/avatar/avatar";
import { useEffect, useState } from "react";
import { Link, useNavigation } from "react-router-dom";
import { EditInventory, getInventory, getInventoryFromDateToDate, getInventoryFromDateToDateAndVendor, getJama, getPurchaseByVendor, getVendors } from "../../API";
import { BASE_URL_PRODUCTION } from "../../Utils/AppConstants";
import { jsPDF } from "jspdf";
import moment from 'moment';
import 'moment/locale/zh-cn';

function JamaNave() {

    const [loading, setLoading] = useState(false);
    const [datasource, setDatasource] = useState([]);
    const [datasourceVendorWise, setDatasourceVendorWise] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [inputValueVendor, setInputValueVendor] = useState('');
    const [selectedVendorID, setSelectedVendorID] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalPayVisible, setIsModalPayVisible] = useState(false);
    const [isModalEditDateVisible, setIsModalEditDateVisible] = useState(false);
    const [totalPurchase, setTotalPurchase] = useState(0);
    const [totalJama, setTotalJama] = useState(0);
    const [totalPending, setTotalPending] = useState(0);
    const [selectedVendorName, setSelectedVendorName] = useState("");
    const [editProductID, setEditProductID] = useState("");
    const [editProductAmountPending, setEditProductAmountPending] = useState("");
    const [payingAmount, setPayingAmount] = useState("");
    const [selectedFinalDate, setSelectedFinalDate] = useState("");
    const [jamaList, setJamaList] = useState([]);
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")

    useEffect(() => {
        let newDate = new Date()
        let date = newDate.getDate();
        if (date < 10) {
            date = "0"+date;
        } else {

        }
        let month = newDate.getMonth() + 1;
        if (month < 10) {
            month = "0"+month;
        } else {

        }
        let year = newDate.getFullYear();
        let finaldate = date+"-"+month+"-"+year;
        setSelectedFinalDate(finaldate);
        setLoading(true);
        getInventory("1")
        .then(res => {
            console.log(res);
            setLoading(false);
            setDatasource(res.Products);
            setDatasourceVendorWise(res.Products);
            let tempInventory = [];
            tempInventory = [...res.Products];
            var tempTaxes = 0;
            var tempPurchase = 0;
            var tempJama = 0;
            var tempPending = 0
            for (let i = 0; i < res.Products.length; i++) {
                tempTaxes = tempTaxes + parseInt(res.Products[i].levitax);
                tempPurchase = tempPurchase + (parseInt(res.Products[i].purchase_price) * parseInt(res.Products[i].quantity));
                tempJama = tempJama + parseInt(res.Products[i].amount_paid);
                tempPending = tempPending + parseInt(res.Products[i].amount_pending);
            }
            setTotalPurchase(parseInt(tempTaxes) + parseInt(tempPurchase));
            //setTotalJama(tempJama);
            //setTotalPending(tempPending);
        });
        getVendors("1").then(res => {
            //setLoading(false);
            setVendors(res.Vendors)
        })
    }, []);

    const searchByVendor = (vendor_id) => {
        getPurchaseByVendor(vendor_id).then(res => {
            setLoading(false);
            setDatasourceVendorWise(res.Products);
            let tempInventory = [];
            tempInventory = [...res.Products];
            var tempTaxes = 0;
            var tempPurchase = 0;
            var tempJama = 0;
            var tempPending = 0
            for (let i = 0; i < res.Products.length; i++) {
                tempTaxes = tempTaxes + parseInt(res.Products[i].levitax);
                tempPurchase = tempPurchase + (parseInt(res.Products[i].purchase_price) * parseInt(res.Products[i].quantity));
                tempJama = tempJama + parseInt(res.Products[i].amount_paid);
                tempPending = tempPending + parseInt(res.Products[i].amount_pending);
            }
            setTotalPurchase(parseInt(tempTaxes) + parseInt(tempPurchase));
            //setTotalJama(tempJama);
            //setTotalPending(tempPending);
        })
    }

    const getAllInventory = () => {
        getInventory("1")
        .then(res => {
            console.log(res);
            setLoading(false);
            setDatasource(res.Products);
            setDatasourceVendorWise(res.Products);
            let tempInventory = [];
            tempInventory = [...res.Products];
            var tempTaxes = 0;
            var tempPurchase = 0;
            var tempJama = 0;
            var tempPending = 0
            for (let i = 0; i < res.Products.length; i++) {
                tempTaxes = tempTaxes + parseInt(res.Products[i].levitax);
                tempPurchase = tempPurchase + (parseInt(res.Products[i].purchase_price) * parseInt(res.Products[i].quantity));
                tempJama = tempJama + parseInt(res.Products[i].amount_paid);
                tempPending = tempPending + parseInt(res.Products[i].amount_pending);
            }
            setTotalPurchase(parseInt(tempTaxes) + parseInt(tempPurchase));
            //setTotalJama(tempJama);
            //setTotalPending(tempPending);
        });
    }

    const downloadPdfDocument = (downloadFileName) => {
        const input = document.getElementById("testId");
        const pdf = new jsPDF({ unit: "px", format: "letter", userUnit: "px" });
        pdf.html(input, { margin: [15, 0, 15, 0], html2canvas: { scale: 0.4 } }).then(() => {
            pdf.save(downloadFileName+".pdf");
        });
    }

    const checkAmountPaid = () => {
        if (parseInt(editProductAmountPending) < parseInt(payingAmount)) {
            alert("Amount can not be greater than Pending Amount of Particular Purchase.")
        } else {
            EditInventory(editProductID, (parseInt(editProductAmountPending) - parseInt(payingAmount))).then(res => {
                alert("Pending Amount Paid Successfully.");
                window.location.reload();
            })
        }
    }

    const printJamaNagad = () => {
        if (selectedVendorName === "") {
            alert("Please select vendor first.")
        } else {
            getJama(selectedVendorID).then(res => {
                setJamaList(res.Vendors);
                console.log(res.Vendors);
                let tempjama = 0;
                let tempTotal = 0;
                for (let i = 0; i < res.Vendors.length; i++) {
                    //console.log(res.Vendors[i].amount)
                    tempjama = tempjama + parseInt(res.Vendors[i].amount);
                }
                setTotalJama(tempjama);
                setTotalPending(totalPurchase - tempjama);
            })
            setIsModalVisible(true)
        }
    }

    const getDateWiseInventory = () => {
        console.log("Vendor ID: "+selectedVendorID);
        if (selectedVendorID === "") {
            getInventoryFromDateToDate(startDate, endDate)
            .then(res => {
                console.log("start date: "+startDate+", end date: "+endDate)
                console.log(res);
                //setLoading(false);
                setDatasource(res.Products);
                setDatasourceVendorWise(res.Products);
                let tempInventory = [];
                tempInventory = [...res.Products];
                var tempTaxes = 0;
                var tempPurchase = 0;
                var tempJama = 0;
                var tempPending = 0
                for (let i = 0; i < res.Products.length; i++) {
                    tempTaxes = tempTaxes + parseInt(res.Products[i].levitax);
                    tempPurchase = tempPurchase + (parseInt(res.Products[i].purchase_price) * parseInt(res.Products[i].quantity));
                    tempJama = tempJama + parseInt(res.Products[i].amount_paid);
                    tempPending = tempPending + parseInt(res.Products[i].amount_pending);
                }
                setTotalPurchase(parseInt(tempTaxes) + parseInt(tempPurchase));
            })
        } else {
            getInventoryFromDateToDateAndVendor(startDate, endDate, selectedVendorID)
            .then(res => {
                console.log("start date: "+startDate+", end date: "+endDate)
                console.log(res);
                //setLoading(false);
                setDatasource(res.Products);
                setDatasourceVendorWise(res.Products);
                let tempInventory = [];
                tempInventory = [...res.Products];
                var tempTaxes = 0;
                var tempPurchase = 0;
                var tempJama = 0;
                var tempPending = 0
                for (let i = 0; i < res.Products.length; i++) {
                    tempTaxes = tempTaxes + parseInt(res.Products[i].levitax);
                    tempPurchase = tempPurchase + (parseInt(res.Products[i].purchase_price) * parseInt(res.Products[i].quantity));
                    tempJama = tempJama + parseInt(res.Products[i].amount_paid);
                    tempPending = tempPending + parseInt(res.Products[i].amount_pending);
                }
                setTotalPurchase(parseInt(tempTaxes) + parseInt(tempPurchase));
            })
        }
        
    }

    return (
        <Space size={20} direction="vertical">
            <Typography.Title level={4} style={{ marginTop: '30px' }}>
                Jama Nagad Details
            </Typography.Title>
            <Link to="/addjamaentry" className="btn btn-danger" type="submit">
                Add Jama Details
            </Link>
            <button className="btn btn-danger" onClick={() => printJamaNagad()}>Print Jama Nagad</button>
            <div className="mb-3">
                <Space direction="horizontal" >
                    <label className="form-label" htmlFor="purchasedfrom">
                        Search By Vendor Name
                    </label>
                    <AutoComplete
                        className="form-control"
                        //value={inputValueCustomer}
                        options={vendors}
                        autoFocus={true}
                        style={{ width: 200 }}
                        filterOption={(inputValue, option) =>
                            option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                        onSelect={(value, data) => {
                            setInputValueVendor(value);
                            setSelectedVendorID(data.id);
                            setSelectedVendorName(data.vendor_name);
                            searchByVendor(data.id);
                            //searchBillsByCustomer(data.id);
                        }}
                        onChange={() => {
                            setInputValueVendor("");
                        }}
                    />
                    <div className="mb-3">
                        <label className="form-label" htmlFor="purchasedfrom">
                            Select Start Date
                        </label>
                        <DatePicker className="form-control" onChange={(dateString) => {
                            // setStartDate(moment(date).format('YYYY-MM-DD'))
                            //console.log(moment(dateString).format('YYYY-MM-DD'))
                            //console.log(dateString)
                            var tempStartDate = new Intl.DateTimeFormat('en-UK', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(dateString);
                            console.log(tempStartDate.split('/').reverse().join('-'))
                            setStartDate(tempStartDate.split('/').reverse().join('-'))
                        }} />
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="purchasedfrom">
                            Select End Date
                        </label>
                        <DatePicker className="form-control" onChange={(dateString) => {
                            var tempStartDate = new Intl.DateTimeFormat('en-UK', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(dateString);
                            console.log(tempStartDate.split('/').reverse().join('-'))
                            setEndDate(tempStartDate.split('/').reverse().join('-'))
                        }} />
                    </div>
                    <button className="btn btn-danger" onClick={() => getDateWiseInventory()}>Search By Date</button>
                </Space>
            </div>
            <button className="btn btn-danger" onClick={() => getAllInventory()}>Clear Search</button>
            {/* <Link to="/addinventory" className="btn btn-danger" type="submit">
                Add Inventory
            </Link> */}
            <Table
                columns={[
                    {
                        title: "Product Name",
                        dataIndex: "product_name"
                    },
                    {
                        title: "Purchase Price",
                        dataIndex: "purchase_price",
                        render: (value) => <span>₹{value}</span>
                    },
                    {
                        title: "Levi Tax",
                        dataIndex: "levitax",
                        render: (value) => <span>₹{value}</span>
                    },
                    {
                        title: "Quantity",
                        dataIndex: "quantity"
                    },
                    {
                        title: "Unit",
                        dataIndex: "unit"
                    },
                    {
                        title: "Carat",
                        dataIndex: "carat"
                    },
                    {
                        title: "Purchase Total",
                        dataIndex: "total_purchase",
                        render: (value) => <span>₹{value}</span>
                    },
                    {
                        title: "Vendor Name",
                        dataIndex: "vendor_name"
                    },
                    {
                        title: "Vendor Contact",
                        dataIndex: "vendor_contact"
                    },
                    {
                        title: "Purchase Date",
                        dataIndex: "purchase_date"
                    },
                    {
                        title: "Amount Paid",
                        dataIndex: "amount_paid"
                    },
                    // {
                    //     title: "Amount Pending",
                    //     dataIndex: "amount_pending"
                    // },
                    // {
                    //     title: "",
                    //     dataIndex: "id",
                    //     render: (value, data) => <button className="btn btn-danger" onClick={() => {
                    //         console.log("Edit");
                    //         setEditProductID(data.id);
                    //         setEditProductAmountPending(data.amount_pending);
                    //         //setSelectedProductID(data.id);
                    //         setIsModalPayVisible(true)
                    //     }}>Pay Pending Amount</button>
                    // },
                ]}
                loading={loading}
                dataSource={datasourceVendorWise}
                pagination={{
                    pageSize: 5
                }}>

            </Table>
            <Modal
                visible={isModalPayVisible}
                okText="Save"
                closable={false}
                cancelText="Close"
                onOk={() => {
                    checkAmountPaid();
                    //downloadPdfDocument("test");
                }}
                onCancel={() => {
                    setIsModalPayVisible(false);
                }}>
                <div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="stock">
                            Enter Amount
                        </label>
                        <Input value={payingAmount} className="form-control" type="number" id="stock" required onChange={(evt) => { 
                            //setEditedPrice(evt.target.value) 
                            setPayingAmount(evt.target.value)
                        }}/>
                    </div>
                </div>
            </Modal>
            <Modal
                visible={isModalVisible}
                okText="Print"
                style={{ width: 1000, resize: "none" }}
                closable={false}
                cancelText="Close"
                onOk={() => {
                    let newDate = new Date()
                    let date = newDate.getDate();
                    if (date < 10) {
                        date = "0"+date;
                    } else {

                    }
                    let month = newDate.getMonth() + 1;
                    if (month < 10) {
                        month = "0"+month;
                    } else {

                    }
                    let year = newDate.getFullYear();
                    let finaldate = date+"-"+month+"-"+year;
                    selectedVendorName === "" ? downloadPdfDocument("All Vendors "+finaldate) : downloadPdfDocument(selectedVendorName+" "+finaldate)
                }}
                onCancel={() => {
                    setIsModalVisible(false);
                }}>
                <div id="testId" style={{ width: 1000 }}>
                    <div class="justify-content-center">
                    <div>
                        <div class="card">
                        <div class="p-1">
                            <div class="text-muted mb-2">Jama Nagad Details</div>
                            <div class="text-muted mb-2">Date: {selectedFinalDate}</div>
                            <div class="text-muted mb-2">Vendor: {selectedVendorName === "" ? "All Vendors" : selectedVendorName }</div>
                            <div class="border-top border-gray-200 pt-4 mt-2 mb-2"></div>
                            <div class="mt-5">
                            <Table
                                columns={[
                                    // {
                                    //     title: "Vendor Name",
                                    //     dataIndex: "vendor_name"
                                    // },
                                    {
                                        title: "Vendor Mobile",
                                        dataIndex: "vendor_mobile"
                                    },
                                    {
                                        title: "Amount",
                                        dataIndex: "amount",
                                        render: (value) => <span>₹{value}</span>
                                    },
                                    {
                                        title: "Date",
                                        dataIndex: "date"
                                    },
                                ]}
                                dataSource={jamaList}
                                pagination={false}>

                            </Table>
                            </div>
                            <div class="mt-5">
                            <Table
                                columns={[
                                    {
                                        title: "Date",
                                        dataIndex: "purchase_date"
                                    },
                                    {
                                        title: "Name",
                                        dataIndex: "product_name"
                                    },
                                    {
                                        title: "Weight",
                                        dataIndex: "quantity"
                                    },
                                    {
                                        title: "Purchase Price",
                                        dataIndex: "purchase_price",
                                        render: (value) => <span>₹{value}</span>
                                    },
                                    {
                                        title: "Levi Tax",
                                        dataIndex: "levitax",
                                        render: (value) => <span>₹{value}</span>
                                    },
                                    {
                                        title: "Carat",
                                        dataIndex: "carat"
                                    },
                                    {
                                        title: "Total",
                                        dataIndex: "total_purchase",
                                        render: (value) => <span>₹{value}</span>
                                    },
                                    // {
                                    //     title: "Amount Pending",
                                    //     dataIndex: "amount_pending",
                                    //     render: (value) => <span>₹{value}</span>
                                    // },
                                    
                                ]}
                                dataSource={datasourceVendorWise}
                                pagination={false}>

                            </Table>
                            </div>
                            {/* <div class="mt-5">
                            <Table
                                columns={[
                                    {
                                        title: "Product Name",
                                        dataIndex: "product_name"
                                    },
                                    {
                                        title: "Purchase Total",
                                        dataIndex: "total_purchase",
                                        render: (value) => <span>${value}</span>
                                    },
                                    {
                                        title: "Amount Paid",
                                        dataIndex: "amount_paid",
                                        render: (value) => <span>${value}</span>
                                    },
                                    {
                                        title: "Amount Pending",
                                        dataIndex: "amount_pending",
                                        render: (value) => <span>${value}</span>
                                    },
                                    {
                                        title: "Amount Paid Date",
                                        dataIndex: "amount_paid_date"
                                    },
                                ]}
                                dataSource={datasourceVendorWise}
                                pagination={false}>

                            </Table>
                            </div> */}
                            <div class="mt-5">
                            <div class="d-flex justify-content-end mt-3">
                                <h5 class="me-3">Total Purchase:</h5>
                                <h5>₹{totalPurchase}</h5>
                            </div>
                            <div class="d-flex justify-content-end mt-3">
                                <h5 class="me-3">Total Jama:</h5>
                                <h5>₹{totalJama}</h5>
                            </div>
                            <div class="d-flex justify-content-end mt-3">
                                <h5 class="me-3">Total Pending:</h5>
                                <h5>₹{totalPending}</h5>
                            </div>
                            {/* <div class="d-flex justify-content-end mt-3">
                                <h5 class="me-3">Total Profits:</h5>
                                <h5>${parseInt(dhadabookRevenue) - parseInt(dhadabookPurchase) - parseInt(dhadabookWaste) - parseInt(dhadabookTaxes)}</h5>
                            </div> */}
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </Modal>
        </Space>
    )
}

export default JamaNave;