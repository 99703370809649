import { Rate, Space, Table, Typography } from "antd";
import Avatar from "antd/es/avatar/avatar";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getCustomers, getInventory, getOrders, getUsers, getVendors } from "../../API";
import { Document, Page } from "react-pdf";

function RefundPolicy() {

    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);
    var perf =require('./RefundPolicy.pdf');

    function onDocumentLoadSuccess({ numPages }){
        setNumPages(numPages);
    }

    return (
        <>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 35 }}>Return &amp; Refund Policy</span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                &nbsp;
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                &nbsp;
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 19 }}>&nbsp;</span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 19 }}>
                All products listed under a particular category may not have the same
                Returns &amp; Refund policy.&nbsp;
                </span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <strong>
                <span style={{ fontSize: 19 }}>
                    Once the order is completed, it is not possible to cancel or refund the
                    order in any way.
                </span>
                </strong>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 19 }}>
                For all products, the returns policy provided on the product page shall
                prevail over the general returns policy. Do refer the respective item's
                applicable return policy on the product page for any exceptions to this
                returns policy and the table below&nbsp;
                </span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 19 }}>
                The return &amp; refund policy is divided into two parts; Do read all
                sections carefully to understand the conditions and cases under which
                returns &amp; refund will be accepted.&nbsp;
                </span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 19 }}>&nbsp;</span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 19 }}>&nbsp;</span>
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                <span style={{ fontSize: 19 }}>&nbsp;</span>
            </p>
            <table
                style={{
                float: "left",
                width: "462.9pt",
                borderCollapse: "collapse",
                border: "none",
                marginLeft: "6.75pt",
                marginRight: "6.75pt"
                }}
            >
                <tbody>
                <tr>
                    <td
                    style={{
                        width: "115.7pt",
                        border: "1pt solid windowtext",
                        padding: "0in 5.4pt",
                        height: "65.5pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "center"
                        }}
                    >
                        <strong>
                        <span style={{ fontSize: 21 }}>Products</span>
                        </strong>
                    </p>
                    </td>
                    <td
                    style={{
                        width: "115.7pt",
                        borderTop: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        borderBottom: "1pt solid windowtext",
                        borderImage: "initial",
                        borderLeft: "none",
                        padding: "0in 5.4pt",
                        height: "65.5pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "center"
                        }}
                    >
                        <strong>
                        <span style={{ fontSize: 21 }}>Quality Of Product</span>
                        </strong>
                    </p>
                    </td>
                    <td
                    style={{
                        width: "115.75pt",
                        borderTop: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        borderBottom: "1pt solid windowtext",
                        borderImage: "initial",
                        borderLeft: "none",
                        padding: "0in 5.4pt",
                        height: "65.5pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "center"
                        }}
                    >
                        <strong>
                        <span style={{ fontSize: 21 }}>Before Dispatched</span>
                        </strong>
                    </p>
                    </td>
                    <td
                    style={{
                        width: "115.75pt",
                        borderTop: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        borderBottom: "1pt solid windowtext",
                        borderImage: "initial",
                        borderLeft: "none",
                        padding: "0in 5.4pt",
                        height: "65.5pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "center"
                        }}
                    >
                        <strong>
                        <span style={{ fontSize: 21 }}>After Dispatched</span>
                        </strong>
                    </p>
                    </td>
                </tr>
                <tr>
                    <td
                    rowSpan={3}
                    style={{
                        width: "115.7pt",
                        borderRight: "1pt solid windowtext",
                        borderBottom: "1pt solid windowtext",
                        borderLeft: "1pt solid windowtext",
                        borderImage: "initial",
                        borderTop: "none",
                        padding: "0in 5.4pt",
                        height: "26.65pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <span style={{ fontSize: 19 }}>Vegetable</span>
                    </p>
                    </td>
                    <td
                    style={{
                        width: "115.7pt",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        padding: "0in 5.4pt",
                        height: "26.65pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <span style={{ fontSize: 19 }}>First</span>
                    </p>
                    </td>
                    <td
                    style={{
                        width: "115.75pt",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        padding: "0in 5.4pt",
                        height: "26.65pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            Applicable For Returns &amp; Refund
                        </span>
                        </strong>
                    </p>
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            96% of total bill will refund and 4% will deduct as return
                            charge .
                        </span>
                        </strong>
                    </p>
                    </td>
                    <td
                    style={{
                        width: "115.75pt",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        padding: "0in 5.4pt",
                        height: "26.65pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            Applicable For Returns &amp; Refund
                        </span>
                        </strong>
                    </p>
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            88% of total bill will refund and 12% will deduct as return
                            charge .
                        </span>
                        </strong>
                    </p>
                    </td>
                </tr>
                <tr>
                    <td
                    style={{
                        width: "115.7pt",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        padding: "0in 5.4pt",
                        height: "24.6pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <span style={{ fontSize: 19 }}>Second&nbsp;</span>
                    </p>
                    </td>
                    <td
                    style={{
                        width: "115.75pt",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        padding: "0in 5.4pt",
                        height: "24.6pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            Applicable For Refund &amp; Returns
                        </span>
                        </strong>
                    </p>
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            94% of total bill will refund and 6% will deduct as return
                            charge .
                        </span>
                        </strong>
                    </p>
                    </td>
                    <td
                    style={{
                        width: "115.75pt",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        padding: "0in 5.4pt",
                        height: "24.6pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            Applicable For Refund &amp; Returns
                        </span>
                        </strong>
                    </p>
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            88% of total bill will refund and 12% will deduct as return
                            charge .
                        </span>
                        </strong>
                    </p>
                    </td>
                </tr>
                <tr>
                    <td
                    style={{
                        width: "115.7pt",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        padding: "0in 5.4pt",
                        height: "21.7pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <span style={{ fontSize: 19 }}>Third</span>
                    </p>
                    </td>
                    <td
                    style={{
                        width: "115.75pt",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        padding: "0in 5.4pt",
                        height: "21.7pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            Applicable For Refund &amp; Return
                        </span>
                        </strong>
                    </p>
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            94% of total bill will refund and 6% will deduct as return
                            charge .
                        </span>
                        </strong>
                    </p>
                    </td>
                    <td
                    style={{
                        width: "115.75pt",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        padding: "0in 5.4pt",
                        height: "21.7pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            Applicable For Refund &amp; Return
                        </span>
                        </strong>
                    </p>
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            88% of total bill will refund and 12% will deduct as return
                            charge&nbsp;
                        </span>
                        </strong>
                    </p>
                    </td>
                </tr>
                <tr>
                    <td
                    rowSpan={3}
                    style={{
                        width: "115.7pt",
                        borderRight: "1pt solid windowtext",
                        borderBottom: "1pt solid windowtext",
                        borderLeft: "1pt solid windowtext",
                        borderImage: "initial",
                        borderTop: "none",
                        padding: "0in 5.4pt",
                        height: "24.55pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <span style={{ fontSize: 19 }}>Leafy Vegetable&nbsp;</span>
                    </p>
                    </td>
                    <td
                    style={{
                        width: "115.7pt",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        padding: "0in 5.4pt",
                        height: "24.55pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <span style={{ fontSize: 19 }}>First</span>
                    </p>
                    </td>
                    <td
                    style={{
                        width: "115.75pt",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        padding: "0in 5.4pt",
                        height: "24.55pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            Applicable For Returns &amp; Refund
                        </span>
                        </strong>
                    </p>
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            96% of total bill will refund and 4% will deduct as return
                            charge .
                        </span>
                        </strong>
                    </p>
                    </td>
                    <td
                    style={{
                        width: "115.75pt",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        padding: "0in 5.4pt",
                        height: "24.55pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            Applicable For Returns &amp; Refund
                        </span>
                        </strong>
                    </p>
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            88% of total bill will refund and 12% will deduct as return
                            charge .
                        </span>
                        </strong>
                    </p>
                    </td>
                </tr>
                <tr>
                    <td
                    style={{
                        width: "115.7pt",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        padding: "0in 5.4pt",
                        height: "22.65pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <span style={{ fontSize: 19 }}>Second&nbsp;</span>
                    </p>
                    </td>
                    <td
                    style={{
                        width: "115.75pt",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        padding: "0in 5.4pt",
                        height: "22.65pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            Applicable For Refund &amp; Returns
                        </span>
                        </strong>
                    </p>
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            94% of total bill will refund and 6% will deduct as return
                            charge .
                        </span>
                        </strong>
                    </p>
                    </td>
                    <td
                    style={{
                        width: "115.75pt",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        padding: "0in 5.4pt",
                        height: "22.65pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            Applicable For Refund &amp; Returns
                        </span>
                        </strong>
                    </p>
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            88% of total bill will refund and 12% will deduct as return
                            charge .
                        </span>
                        </strong>
                    </p>
                    </td>
                </tr>
                <tr>
                    <td
                    style={{
                        width: "115.7pt",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        padding: "0in 5.4pt",
                        height: "21.7pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <span style={{ fontSize: 19 }}>Third</span>
                    </p>
                    </td>
                    <td
                    style={{
                        width: "115.75pt",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        padding: "0in 5.4pt",
                        height: "21.7pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            Applicable For Refund &amp; Return
                        </span>
                        </strong>
                    </p>
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            94% of total bill will refund and 6% will deduct as return
                            charge .
                        </span>
                        </strong>
                    </p>
                    </td>
                    <td
                    style={{
                        width: "115.75pt",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        padding: "0in 5.4pt",
                        height: "21.7pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            Applicable For Refund &amp; Return
                        </span>
                        </strong>
                    </p>
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            88% of total bill will refund and 12% will deduct as return
                            charge&nbsp;
                        </span>
                        </strong>
                    </p>
                    </td>
                </tr>
                <tr>
                    <td
                    rowSpan={3}
                    style={{
                        width: "115.7pt",
                        borderRight: "1pt solid windowtext",
                        borderBottom: "1pt solid windowtext",
                        borderLeft: "1pt solid windowtext",
                        borderImage: "initial",
                        borderTop: "none",
                        padding: "0in 5.4pt",
                        height: "23.45pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <span style={{ fontSize: 19 }}>Exotic Vegetable&nbsp;</span>
                    </p>
                    </td>
                    <td
                    style={{
                        width: "115.7pt",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        padding: "0in 5.4pt",
                        height: "23.45pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <span style={{ fontSize: 19 }}>First</span>
                    </p>
                    </td>
                    <td
                    style={{
                        width: "115.75pt",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        padding: "0in 5.4pt",
                        height: "23.45pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            Applicable For Returns &amp; Refund
                        </span>
                        </strong>
                    </p>
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            96% of total bill will refund and 4% will deduct as return
                            charge .
                        </span>
                        </strong>
                    </p>
                    </td>
                    <td
                    style={{
                        width: "115.75pt",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        padding: "0in 5.4pt",
                        height: "23.45pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            Applicable For Returns &amp; Refund
                        </span>
                        </strong>
                    </p>
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            88% of total bill will refund and 12% will deduct as return
                            charge .
                        </span>
                        </strong>
                    </p>
                    </td>
                </tr>
                <tr>
                    <td
                    style={{
                        width: "115.7pt",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        padding: "0in 5.4pt",
                        height: "22.65pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <span style={{ fontSize: 19 }}>Second&nbsp;</span>
                    </p>
                    </td>
                    <td
                    style={{
                        width: "115.75pt",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        padding: "0in 5.4pt",
                        height: "22.65pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            Applicable For Refund &amp; Returns
                        </span>
                        </strong>
                    </p>
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            94% of total bill will refund and 6% will deduct as return
                            charge .
                        </span>
                        </strong>
                    </p>
                    </td>
                    <td
                    style={{
                        width: "115.75pt",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        padding: "0in 5.4pt",
                        height: "22.65pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            Applicable For Refund &amp; Returns
                        </span>
                        </strong>
                    </p>
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            88% of total bill will refund and 12% will deduct as return
                            charge .
                        </span>
                        </strong>
                    </p>
                    </td>
                </tr>
                <tr>
                    <td
                    style={{
                        width: "115.7pt",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        padding: "0in 5.4pt",
                        height: "22.7pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <span style={{ fontSize: 19 }}>Third</span>
                    </p>
                    </td>
                    <td
                    style={{
                        width: "115.75pt",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        padding: "0in 5.4pt",
                        height: "22.7pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            Applicable For Refund &amp; Return
                        </span>
                        </strong>
                    </p>
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            94% of total bill will refund and 6% will deduct as return
                            charge .
                        </span>
                        </strong>
                    </p>
                    </td>
                    <td
                    style={{
                        width: "115.75pt",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        padding: "0in 5.4pt",
                        height: "22.7pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            Applicable For Refund &amp; Return
                        </span>
                        </strong>
                    </p>
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            88% of total bill will refund and 12% will deduct as return
                            charge&nbsp;
                        </span>
                        </strong>
                    </p>
                    </td>
                </tr>
                <tr>
                    <td
                    rowSpan={3}
                    style={{
                        width: "115.7pt",
                        borderRight: "1pt solid windowtext",
                        borderBottom: "1pt solid windowtext",
                        borderLeft: "1pt solid windowtext",
                        borderImage: "initial",
                        borderTop: "none",
                        padding: "0in 5.4pt",
                        height: "21.7pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <span style={{ fontSize: 19 }}>Fruits</span>
                    </p>
                    </td>
                    <td
                    style={{
                        width: "115.7pt",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        padding: "0in 5.4pt",
                        height: "21.7pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <span style={{ fontSize: 19 }}>First</span>
                    </p>
                    </td>
                    <td
                    style={{
                        width: "115.75pt",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        padding: "0in 5.4pt",
                        height: "21.7pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            Applicable For Returns &amp; Refund
                        </span>
                        </strong>
                    </p>
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            96% of total bill will refund and 4% will deduct as return
                            charge .
                        </span>
                        </strong>
                    </p>
                    </td>
                    <td
                    style={{
                        width: "115.75pt",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        padding: "0in 5.4pt",
                        height: "21.7pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            Applicable For Returns &amp; Refund
                        </span>
                        </strong>
                    </p>
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            88% of total bill will refund and 12% will deduct as return
                            charge .
                        </span>
                        </strong>
                    </p>
                    </td>
                </tr>
                <tr>
                    <td
                    style={{
                        width: "115.7pt",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        padding: "0in 5.4pt",
                        height: "27.45pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <span style={{ fontSize: 19 }}>Second&nbsp;</span>
                    </p>
                    </td>
                    <td
                    style={{
                        width: "115.75pt",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        padding: "0in 5.4pt",
                        height: "27.45pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            Applicable For Refund &amp; Returns
                        </span>
                        </strong>
                    </p>
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            94% of total bill will refund and 6% will deduct as return
                            charge .
                        </span>
                        </strong>
                    </p>
                    </td>
                    <td
                    style={{
                        width: "115.75pt",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        padding: "0in 5.4pt",
                        height: "27.45pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            Applicable For Refund &amp; Returns
                        </span>
                        </strong>
                    </p>
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            88% of total bill will refund and 12% will deduct as return
                            charge .
                        </span>
                        </strong>
                    </p>
                    </td>
                </tr>
                <tr>
                    <td
                    style={{
                        width: "115.7pt",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        padding: "0in 5.4pt",
                        height: "27.2pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <span style={{ fontSize: 19 }}>Third</span>
                    </p>
                    </td>
                    <td
                    style={{
                        width: "115.75pt",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        padding: "0in 5.4pt",
                        height: "27.2pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            Applicable For Refund &amp; Return
                        </span>
                        </strong>
                    </p>
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            94% of total bill will refund and 6% will deduct as return
                            charge .
                        </span>
                        </strong>
                    </p>
                    </td>
                    <td
                    style={{
                        width: "115.75pt",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "1pt solid windowtext",
                        borderRight: "1pt solid windowtext",
                        padding: "0in 5.4pt",
                        height: "27.2pt",
                        verticalAlign: "top"
                    }}
                    >
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            Applicable For Refund &amp; Return
                        </span>
                        </strong>
                    </p>
                    <p
                        style={{
                        margin: "0in",
                        fontSize: 13,
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "justify"
                        }}
                    >
                        <strong>
                        <span
                            style={{ fontSize: 16, fontFamily: '"Candara",sans-serif' }}
                        >
                            88% of total bill will refund and 12% will deduct as return
                            charge&nbsp;
                        </span>
                        </strong>
                    </p>
                    </td>
                </tr>
                </tbody>
            </table>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                &nbsp;
            </p>
            <p
                style={{ margin: "0in", fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            >
                &nbsp;
            </p>
        </>
    );
}

export default RefundPolicy;

//https://farmeasy.co.in/API/PDF/RefundPolicy.pdf