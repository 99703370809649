import { Menu } from 'antd';
import { AppstoreAddOutlined, ShopOutlined, ShoppingCartOutlined, UserOutlined, DeleteOutlined, DollarCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import Offers from '../../Pages/Offers';
import RefundPolicy from '../../Pages/RefundPolicy';
import ShippingPolicy from '../../Pages/ShippingPolicy';

function SideMenu() {
    const navigate = useNavigate();
    return (
        <div className="SideMenu">
            <Menu
                onClick={(item) => {
                    //item.key
                    navigate(item.key);
                }}
                items={[
                    {
                        label: "Dasboard",
                        icon: <AppstoreAddOutlined />,
                        key: "/"
                    },
                    {
                        label: "Purchase",
                        icon: <ShopOutlined />,
                        key: "/inventory"
                    },
                    {
                        label: "Products",
                        icon: <ShopOutlined />,
                        key: "/productlist"
                    },
                    {
                        label: "JamaNave",
                        icon: <DollarCircleOutlined />,
                        key: "/jamaNave"
                    },
                    {
                        label: "Orders",
                        icon: <ShoppingCartOutlined />,
                        key: "/orders"
                    },
                    {
                        label: "Customers",
                        icon: <UserOutlined />,
                        key: "/customers"
                    },
                    {
                        label: "Vendors",
                        icon: <UserOutlined />,
                        key: "/vendors"
                    },
                    {
                        label: "Pincodes",
                        icon: <UserOutlined />,
                        key: "/pincodes"
                    },
                    {
                        label: "Users",
                        icon: <UserOutlined />,
                        key: "/users"
                    },
                    {
                        label: "Waste Material",
                        icon: <DeleteOutlined />,
                        key: "/wastematerial"
                    },
                    {
                        label: "Levis And Taxes",
                        icon: <DollarCircleOutlined />,
                        key: "/levisandtaxes"
                    },
                    {
                        label: "Offers",
                        icon: <DollarCircleOutlined />,
                        key: "/offers"
                    },
                    {
                        label: "Order Place Status",
                        icon: <DollarCircleOutlined />,
                        key: "/orderPlaceStatus"
                    },
                    {
                        label: "Time Slot",
                        icon: <DollarCircleOutlined />,
                        key: "/timeSlots"
                    },
                    {
                        label: "Refunds",
                        icon: <DollarCircleOutlined />,
                        key: "/refunds"
                    },
                    {
                        label: "Payment History",
                        icon: <DollarCircleOutlined />,
                        key: "/paymenthistory"
                    },
                    {
                        label: "Refund Policy",
                        icon: <DollarCircleOutlined />,
                        key: "/refundPolicy"
                    },
                    {
                        label: "Privacy Policy",
                        icon: <DollarCircleOutlined />,
                        key: "/privacyPolicy"
                    },
                    {
                        label: "Cancellation Policy",
                        icon: <DollarCircleOutlined />,
                        key: "/cancellationPolicy"
                    },
                    {
                        label: "Terms and Conditions",
                        icon: <DollarCircleOutlined />,
                        key: "/termsAndConditions"
                    },
                    {
                        label: "Shipping Policy",
                        icon: <DollarCircleOutlined />,
                        key: "/shippingPolicy"
                    },
                ]}>

            </Menu>
        </div>
    )
}

export default SideMenu;