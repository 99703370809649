import React, { useState } from "react";
import { Rate, Space, Table, Typography, Modal, Menu, Dropdown } from "antd";
import Input from 'antd/es/input/Input';
import { AddOfferAPI, AddProduct } from "../../API";

const AddOffer = () => {

    const [productName, setProductName] = useState("");
    const [productPrice, setProductPrice] = useState("");
    const [productDiscountPrice, setProductDiscountPrice] = useState("");
    const [productDescription, setProductDescription] = useState("");
    const [productSize, setProductSize] = useState("");
    const [productStorage, setProductStorage] = useState("");
    const [productDisclaimer, setProductDisclaimer] = useState("");
    const [productCountry, setProductCountry] = useState("");
    const [productQuality, setProductQuality] = useState("0");
    const [productQualityDisplay, setProductQualityDisplay] = useState("First");
    const [productUnit, setProductUnit] = useState("");
    const [fileSelected, setFileSelected] = useState(null);

    const addProductNew = () => {
        // if (productName === '') {
        //     alert('Please add Product Name.');
        // } else if (productPrice === '') {
        //     alert('Please add Product Price.');
        // } else if (productDiscountPrice === '') {
        //     alert('Please add Product Discount Price.');
        // } else if (productPrice === '') {
        //     alert('Please select product Photo.');
        // } else if (productDescription === '') {
        //     alert('Please select product Description.');
        // } else if (productQuality === '') {
        //     alert('Please select product Quality.');
        // } else if (productUnit === '') {
        //     alert('Please select product Unit.');
        // } else {
             
        // }
        AddOfferAPI( fileSelected)
        .then(res => {
            alert("Offer Added Successfully.");
        })
    }

    const handleClick = ({key}) => {
        console.log(key)
        if (key === "0") {
            setProductQualityDisplay("First")
        } else if (key === "1") {
            setProductQualityDisplay("Second")
        } else if (key === "2") {
            setProductQualityDisplay("Third")
        } else {

        }
        setProductQuality(key)
        //you can perform setState here
    }

    const handleUnitClick = ({key}) => {
        console.log(key)
        setProductUnit(key)
        //you can perform setState here
    }

    const menu = (
        <Menu onClick={handleClick}>
            <Menu.Item key="1">First</Menu.Item>
            <Menu.Item key="2">Second</Menu.Item>
            <Menu.Item key="3">Third</Menu.Item>
        </Menu>
    );

    const unit = (
        <Menu onClick={handleUnitClick}>
            <Menu.Item key="KG">KG</Menu.Item>
            <Menu.Item key="Dozen">Dozen</Menu.Item>
            <Menu.Item key="Bunch">Bunch</Menu.Item>
        </Menu>
    );

    const onFileChange = (event) => {
        // Update the state
        //this.setState({ selectedFile:  });
        setFileSelected(event.target.files[0]);
    };

    return (
        <div className="container mt-5">
            <h2 className="mb-3">Add Offer Details</h2>
            
            <label className="form-label mt-1" htmlFor="purchasedfrom">

            </label>

            <label className="form-label mt-1" htmlFor="purchasedfrom">
                Select Photo
            </label>
            <input className="form-control" type="file" onChange={onFileChange} />
            <button className="btn btn-danger mb-5 mt-4" onClick={() => addProductNew()}>
                Add Offer
            </button>
        </div>
    )
}

export default AddOffer;