import { Rate, Space, Table, Typography } from "antd";
import Avatar from "antd/es/avatar/avatar";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getCustomers, getInventory, getOrders, getUsers, getVendors } from "../../API";

function Users() {

    const [loading, setLoading] = useState(false);
    const [datasource, setDatasource] = useState([]);

    useEffect(() => {
        setLoading(true);
        getUsers("1").then(res => {
            setLoading(false);
            setDatasource(res.Users)
        })
    }, []);

    return (
        <Space size={20} direction="vertical">
            <Typography.Title level={4}>
                Users
            </Typography.Title>
            <Link to="/adduser" className="btn btn-danger" type="submit">
                Add User
            </Link>
            <Table
                columns={[
                    // {
                    //     title: "Image",
                    //     dataIndex: "image",
                    //     render: (link) => {
                    //         return <Avatar src={link} />
                    //     }
                    // },
                    {
                        title: "User Name",
                        dataIndex: "name"
                    },
                    {
                        title: "Username",
                        dataIndex: "username"
                    },
                    {
                        title: "Mobile",
                        dataIndex: "mobile"
                    },
                    {
                        title: "Password",
                        dataIndex: "password"
                    },
                    {
                        title: "Role",
                        dataIndex: "role"
                    },
                    // {
                    //     title: "Email",
                    //     dataIndex: "email"
                    // },
                    // {
                    //     title: "Username",
                    //     dataIndex: "username"
                    // },
                    // {
                    //     title: "Pincode",
                    //     dataIndex: "address",
                    //     render: (address) => {
                    //         return <span>{address.postalCode}</span>
                    //     }
                    // },
                    // {
                    //     title: "Address",
                    //     dataIndex: "address",
                    //     render: (address) => {
                    //         return <span>{address.address} {address.city} {address.state}</span>
                    //     }
                    // },

                ]}
                loading={loading}
                dataSource={datasource}
                pagination={{
                    pageSize: 5
                }}>

            </Table>
        </Space>
    )
}

export default Users;