import { Rate, Space, Table, Typography } from "antd";
import Avatar from "antd/es/avatar/avatar";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { deleteOffers, getCustomers, getInventory, getOffers, getOrders, getVendors } from "../../API";

function Offers() {

    const [loading, setLoading] = useState(false);
    const [datasource, setDatasource] = useState([]);

    useEffect(() => {
        setLoading(true);
        getOffers("1").then(res => {
            setLoading(false);
            setDatasource(res.Offers)
        })
    }, []);

    const deleteOfferMethod = (id) => {
        deleteOffers(id)
        .then(res => {
            getOffers("1").then(res => {
                setLoading(false);
                setDatasource(res.Offers)
            })
        })
    }

    return (
        <Space size={20} direction="vertical">
            <Typography.Title level={4}>
                Offers
            </Typography.Title>
            <Link to="/addoffer" className="btn btn-danger" type="submit">
                Add Offers
            </Link>
            <Table
                columns={[
                    // {
                    //     title: "Image",
                    //     dataIndex: "image",
                    //     render: (link) => {
                    //         return <Avatar src={link} />
                    //     }
                    // },
                    {
                        title: "Offers",
                        dataIndex: "offer_image",
                        render: (link) => {
                            return <img height={200} width={400} src={link} />
                        }
                    },
                    {
                        title: "",
                        dataIndex: "billdata",
                        render: (value, data) => <button className="btn btn-danger" onClick={() => {
                            console.log("Edit");
                            deleteOfferMethod(data.id)
                        }}>Delete Offer</button>
                    },
                    // {
                    //     title: "Phone",
                    //     dataIndex: "vendor_contact"
                    // },
                    // {
                    //     title: "Location",
                    //     dataIndex: "vendor_location"
                    // },
                    // {
                    //     title: "Email",
                    //     dataIndex: "email"
                    // },
                    // {
                    //     title: "Username",
                    //     dataIndex: "username"
                    // },
                    // {
                    //     title: "Pincode",
                    //     dataIndex: "address",
                    //     render: (address) => {
                    //         return <span>{address.postalCode}</span>
                    //     }
                    // },
                    // {
                    //     title: "Address",
                    //     dataIndex: "address",
                    //     render: (address) => {
                    //         return <span>{address.address} {address.city} {address.state}</span>
                    //     }
                    // },

                ]}
                loading={loading}
                dataSource={datasource}
                pagination={{
                    pageSize: 5
                }}>

            </Table>
        </Space>
    )
}

export default Offers;