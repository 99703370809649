import { Rate, Space, Table, Typography, Modal, Input } from "antd";
import Avatar from "antd/es/avatar/avatar";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { EditPickUpPoint, EditPincode, editDeliveryIndex, getCustomers, getInventory, getNewPincodes, getOrders, getPickUpPoints, getPincodes } from "../../API";

function Pincodes() {

    const [loading, setLoading] = useState(false);
    const [datasource, setDatasource] = useState([]);
    const [pickupPoint, setPickUpPoint] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showEditIndex, setShowEditIndex] = useState(false);
    const [selectedID, setSelectedID] = useState("");
    const [selectedDeliveryCharges, setSelectedDeliveryCharges] = useState("");
    const [selectedKMIndex, setSelectedKMIndex] = useState("");
    const [selectedDozenIndex, setSelectedDozenIndex] = useState("");
    const [selectedJudiIndex, setSelectedJudiIndex] = useState("");
    const [selectedPackIndex, setSelectedPackIndex] = useState("");
    const [newPincodes, setNewPincodes] = useState([]);

    useEffect(() => {
        setLoading(true);
        getPincodes("1").then(res => {
            setLoading(false);
            setDatasource(res.Pincodes)
            getPickup();
        })
        
    }, []);

    const getPickup = () => {
        getPickUpPoints("1")
        .then(res => {
            setPickUpPoint(res.PickupPoint[0].address)
            newPincodesList()
        })
    }

    const newPincodesList = () => {
        getNewPincodes("1").then(res => {
            setNewPincodes(res.NewPincodes)
        })
    }

    const editPickUp = () => {
        EditPickUpPoint("1", pickupPoint)
        .then(res => {
            setIsModalVisible(false);
            getPickup()
        })
    }

    const editIndex = () => {
        EditPincode(selectedID, selectedDeliveryCharges, selectedKMIndex, selectedDozenIndex, selectedJudiIndex, selectedPackIndex)
        .then(res => {
            setSelectedID("");
            setSelectedDeliveryCharges("");
            setSelectedKMIndex("");
            setSelectedDozenIndex("");
            setSelectedJudiIndex("");
            alert("Pincode Edited Successfully.");
            reloadPincodes();
        })
    }

    const reloadPincodes = () => {
        getPincodes("1").then(res => {
            setDatasource(res.Pincodes);
            setShowEditIndex(false);
        })
    }

    return (
        <Space size={20} direction="vertical">
            <Typography.Title level={4}>
                Pincodes
            </Typography.Title>
            <Space direction="horizontal">
                <Link to="/addpincodes" className="btn btn-danger" type="submit">
                    Add Pincodes
                </Link>
                <button className="btn btn-danger" onClick={() => setIsModalVisible(true)}>Set PickUp Point</button>
            </Space>
            <Typography.Title level={4}>
                Pick Up Point : {pickupPoint}
            </Typography.Title>
            <Table
                columns={[
                    {
                        title: "Pincodes",
                        dataIndex: "pincode"
                    },
                    {
                        title: "City",
                        dataIndex: "city"
                    },
                    {
                        title: "Delivery Charges",
                        dataIndex: "delivery_charges"
                    },
                    {
                        title: "KM Index",
                        dataIndex: "KM_index"
                    },
                    {
                        title: "Dozen Index",
                        dataIndex: "Dozen_index"
                    },
                    {
                        title: "Judi Index",
                        dataIndex: "Judi_index"
                    },
                    {
                        title: "Pack Index",
                        dataIndex: "Pack_index"
                    },
                    {
                        title: "",
                        dataIndex: "Judi_index",
                        render: (value, data) => <button className="btn btn-danger" onClick={() => {
                            setSelectedID(data.id);
                            setSelectedDeliveryCharges(data.delivery_charges);
                            setSelectedKMIndex(data.KM_index);
                            setSelectedDozenIndex(data.Dozen_index);
                            setSelectedJudiIndex(data.Judi_index);
                            setSelectedPackIndex(data.Pack_index);
                            setShowEditIndex(true);
                        }}>Edit Pincode</button>
                    },
                ]}
                loading={loading}
                dataSource={datasource}
                pagination={{
                    pageSize: 5
                }}>

            </Table>
            <Typography.Title level={4}>
                New Pincode Requests
            </Typography.Title>
            <Table
                columns={[
                    {
                        title: "Name",
                        dataIndex: "name"
                    },
                    {
                        title: "Mobile",
                        dataIndex: "mobile"
                    },
                    {
                        title: "Address",
                        dataIndex: "address"
                    },
                    {
                        title: "Pincode",
                        dataIndex: "pincode"
                    },
                ]}
                loading={loading}
                dataSource={newPincodes}
                pagination={{
                    pageSize: 5
                }}>

            </Table>
            <Modal
                visible={isModalVisible}
                okText="Save"
                closable={false}
                cancelText="Close"
                onOk={() => {
                    editIndex();
                    //downloadPdfDocument("test");
                }}
                onCancel={() => {
                    setIsModalVisible(false);
                }}>
                <div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="stock">
                            New Product Name
                        </label>
                        <Input value={pickupPoint} className="form-control mb-1" id="stock" required onChange={(evt) => { setPickUpPoint(evt.target.value) }}/>
                    </div>
                </div>
            </Modal>
            <Modal
                visible={showEditIndex}
                closable={false}
                okText="Ok"
                cancelText="Close"
                onOk={() => {
                    setShowEditIndex(false);
                    editIndex();
                }}
                onCancel={() => {
                    setShowEditIndex(false);
                }}>
                <div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="stock">
                            Set Delivery Charges
                        </label>
                        <Input value={selectedDeliveryCharges} className="form-control mb-1" id="stock" required onChange={(evt) => { setSelectedDeliveryCharges(evt.target.value) }}/>
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="stock">
                            Set KM Index
                        </label>
                        <Input value={selectedKMIndex} className="form-control mb-1" id="stock" required onChange={(evt) => { setSelectedKMIndex(evt.target.value) }}/>
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="stock">
                            Set Dozen Index
                        </label>
                        <Input value={selectedDozenIndex} className="form-control mb-1" id="stock" required onChange={(evt) => { setSelectedDozenIndex(evt.target.value) }}/>
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="stock">
                            Set Judi Index
                        </label>
                        <Input value={selectedJudiIndex} className="form-control mb-1" id="stock" required onChange={(evt) => { setSelectedJudiIndex(evt.target.value) }}/>
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="stock">
                            Set Pack Index
                        </label>
                        <Input value={selectedPackIndex} className="form-control mb-1" id="stock" required onChange={(evt) => { setSelectedPackIndex(evt.target.value) }}/>
                    </div>
                </div>
            </Modal>
        </Space>
    )
}

export default Pincodes;