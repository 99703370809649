import { Rate, Space, Table, Typography, AutoComplete, Alert, Modal, Input } from "antd";
import Avatar from "antd/es/avatar/avatar";
import { useEffect, useState } from "react";
import { Link, useNavigation } from "react-router-dom";
import { AddWasteResale, EditWasteToInActive, getCustomers, getInventory, getWasteResale, getWastedProducts } from "../../API";


const WasteMaterial = () => {
    const [loading, setLoading] = useState(false);
    const [datasource, setDatasource] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [wasteResale, setWasterResale] = useState([]);
    const [resaleWasteDialog, setResaleWasteDialog] = useState(false);
    const [selectedCustomerName, setSelectedCustomerName] = useState('');
    const [selectedCustomerMobile, setSelectedCustomerMobile] = useState('');
    const [selectedCustomerID, setSelectedCustomerID] = useState('');
    const [selectedProductID, setSelectedProductID] = useState('');
    const [selectedProductName, setSelectedProductName] = useState('');
    const [selectedResalePrice, setSelectedResalePrice] = useState('');
    const [selectedResaleQuantity, setSelectedResaleQuantity] = useState('');
    const [selectedResaleTotal, setSelectedResaleTotal] = useState('');
    const [inputValueCustomer, setInputValueCustomer] = useState('');
    const [selectedWasteID, setSelectedWasteID] = useState('');

    useEffect(() => {
        setLoading(true);
        getWastedProducts("1").then(res => {
            setLoading(false);
            setDatasource(res.Products);
        })
        getCustomers("1").then(res => {
            setLoading(false);
            setCustomers(res.Customers)
        })
        getWasteResale().then(res => {
            setWasterResale(res.Products);
        })
    }, []);

    const CheckForm = () => {
        if (selectedCustomerMobile === '') {
            alert("Please select Customer")
        } else if (selectedResalePrice === '') {
            alert("Please enter resale price")
        } else if (selectedResaleQuantity === '') {
            alert("Please enter resale quantity")
        } else {
            CalculateResaleTotal();
        }
    }

    const CalculateResaleTotal = () => {
        const tempTotal = parseFloat(selectedResalePrice) * parseFloat(selectedResaleQuantity)
        AddWasteToResale(tempTotal)
    }

    const AddWasteToResale = (total) => {
        AddWasteResale(selectedCustomerName, selectedCustomerMobile, selectedCustomerID, selectedProductID, selectedProductName, selectedResalePrice, selectedResaleQuantity, total).then(res => {
            alert("Product Resale Successful.");
            EditWasteToInActive(selectedWasteID).then(res => {
                getWasteResale().then(res => {
                    setWasterResale(res.Products);
                    setResaleWasteDialog(false)
                })
            })
        })
    }

    return (
        <Space size={20} direction="vertical">
            <Typography.Title level={4} style={{ marginTop: '30px' }}>
                Waste Material
            </Typography.Title>
            <Link to="/addwaste" className="btn btn-danger">
                Add Waste Entry
            </Link>
            <Table
                columns={[
                    {
                        title: "Product Name",
                        dataIndex: "product_name"
                    },
                    {
                        title: "Product Quantity",
                        dataIndex: "quantity"
                    },
                    {
                        title: "Price",
                        dataIndex: "product_price",
                        render: (value) => <span>₹{value}</span>
                    },
                    {
                        title: "Expiry Date",
                        dataIndex: "expiry_date"
                    },
                    {
                        title: "",
                        dataIndex: "price",
                        render: (value, data) =>
                            <button className="btn btn-danger" onClick={() => {
                                //acceptOrder(data.billid);
                                setResaleWasteDialog(true)
                                setSelectedWasteID(data.id)
                                setSelectedProductID(data.id);
                                setSelectedProductName(data.product_name)
                            }}>Resale Product</button>
                    },
                ]}
                loading={loading}
                dataSource={datasource}
                pagination={{
                    pageSize: 5
                }}>

            </Table>
            <label className="form-label" htmlFor="purchasedfrom">
                Waste Resale
            </label>
            <Table
                columns={[
                    {
                        title: "Customer Name",
                        dataIndex: "customer_name"
                    },
                    {
                        title: "Product Name",
                        dataIndex: "product_name"
                    },
                    {
                        title: "Resale Price",
                        dataIndex: "resale_price",
                        render: (value) => <span>₹{value}</span>
                    },
                    {
                        title: "Resale Quantity",
                        dataIndex: "resale_quantity"
                    },
                    {
                        title: "Resale Total",
                        dataIndex: "resale_total",
                        render: (value) => <span>₹{value}</span>
                    },
                ]}
                loading={loading}
                dataSource={wasteResale}
                pagination={{
                    pageSize: 5
                }}>

            </Table>
            <Modal
                visible={resaleWasteDialog}
                okText="Save"
                closable={false}
                cancelText="Close"
                onOk={() => {
                    CheckForm();
                }}
                onCancel={() => {
                    setResaleWasteDialog(false);
                }}>
                <div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="stock">
                            Select Customer
                        </label>
                        <AutoComplete
                            className="form-control"
                            //value={inputValueCustomer}
                            options={customers}
                            autoFocus={true}
                            style={{ width: 200 }}
                            filterOption={(inputValue, option) =>
                                option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                            onSelect={(value, data) => {
                                setSelectedCustomerName(data.customerName);
                                setSelectedCustomerID(data.id);
                                setSelectedCustomerMobile(data.mobile);
                            }}
                            onChange={() => {
                                setInputValueCustomer("");
                            }}
                        />
                        <label className="form-label" htmlFor="stock">
                            Enter Resale Price
                        </label>
                        <Input className="form-control mb-1" type="number" id="stock" required onChange={(evt) => { setSelectedResalePrice(evt.target.value) }} />
                        <label className="form-label" htmlFor="stock">
                            Enter Resale Quantity
                        </label>
                        <Input className="form-control mb-1" type="number" id="stock" required onChange={(evt) => { setSelectedResaleQuantity(evt.target.value) }} />
                    </div>
                </div>
            </Modal>
        </Space>
    )
}

export default WasteMaterial;

