import React, { useState } from "react";
import Input from 'antd/es/input/Input';
import { AddCustomer } from "../../API";

const AddCustomers = () => {

    const [customerName, setCustomerName] = useState("");
    const [customerMobile, setCustomerMobile] = useState("");

    const addCustomerNew = () => {
        if (customerName === '') {
            alert('Please add Customer Name.');
        } else if (customerMobile === '') {
            alert('Please add Customer Mobile.');
        } else {
            AddCustomer(customerName, customerMobile)
            .then(res => {
                setCustomerName("");
                setCustomerMobile("");
                alert("Customer Added Successfully.");
            })
        }
        
    }

    return (
        <div className="container mt-5">
            <h2 className="mb-3">Add Customer Details</h2>
            {/* <form onSubmit={onSubmit}> */}
            <div className="mb-3">
                <label className="form-label" htmlFor="title">
                    Customer Name
                </label>
                <Input value={customerName} className="form-control" id="quantity" required onChange={(evt) => { setCustomerName(evt.target.value) }} />
            </div>
            <div className="mb-3">
                <label className="form-label" htmlFor="price">
                    Customer Mobile
                </label>
                <Input value={customerMobile} className="form-control" type="number" id="quantity" required onChange={(evt) => { setCustomerMobile(evt.target.value) }} />
            </div>
            <button className="btn btn-danger mb-5" onClick={() => addCustomerNew()}>
                Add Customer
            </button>
        </div>
    )
}

export default AddCustomers;