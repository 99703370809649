import { BrowserRouter, Route, Routes } from "react-router-dom";
import AddCustomers from "../../Pages/AddCustomers";
import AddInventory from "../../Pages/AddInventory";
import AddOrders from "../../Pages/AddOrders";
import Customers from "../../Pages/Customers";
import Dashboard from "../../Pages/Dashboard";
import Inventory from "../../Pages/Inventory";
import Orders from "../../Pages/Orders";
import WasteMaterial from "../../Pages/WasteMaterial";
import AddWasteMaterial from "../../Pages/AddWasteMaterial";
import LevisAndTaxes from "../../Pages/LevisAndTaxes";
import AddNewProduct from "../../Pages/AddNewProduct";
import AddVendors from "../../Pages/AddVendors";
import Vendors from "../../Pages/Vendors";
import ProductList from "../../Pages/ProductList";
import Users from "../../Pages/Users";
import AddUsers from "../../Pages/AddUsers";
import JamaNave from "../../Pages/JamaNave";
import AddJamaEntry from "../../Pages/AddJamaEntry";
import Pincodes from "../../Pages/Pincodes";
import AddPincodesScreen from "../../Pages/AddPincodesScreen";
import DeliveryIndex from "../../Pages/DeliveryIndex";
import DeliveryChargesDetails from "../../Pages/DeliveriesChagesDetails";
import Offers from "../../Pages/Offers";
import AddOffer from "../../Pages/AddOffer";
import OrderPlaceStatus from "../../Pages/OrderPlaceStatus";
import RefundPolicy from "../../Pages/RefundPolicy";
import PrivacyPolicy from "../../Pages/PrivacyPolicy";
import CancellationPolicy from "../../Pages/CancellationPolicy";
import TermsAndConditions from "../../Pages/TermsAndConditions";
import ShippingPolicy from "../../Pages/ShippingPolicy";
import TimeSlots from "../../Pages/TimeSlots";
import Refunds from "../../Pages/Refunds";
import PaymentHistory from "../../Pages/PaymentHistory";
import CustomerOrders from "../../Pages/CustomerOrders";

function AppRoutes() {
    return (
        <div>
            <Routes>
                <Route path="/" element={<Dashboard />}></Route>
                <Route path="/inventory" element={<Inventory />}></Route>
                <Route path="/orders" element={<Orders />}></Route>
                <Route path="/customerorders" element={<CustomerOrders />}></Route>
                <Route path="/addinventory" element={<AddInventory />}></Route>
                <Route path="/addnewproduct" element={<AddNewProduct />}></Route>
                <Route path="/addvendors" element={<AddVendors />}></Route>
                <Route path="/addorders" element={<AddOrders />}></Route>
                <Route path="/addcustomers" element={<AddCustomers />}></Route>
                <Route path="/addwaste" element={<AddWasteMaterial />}></Route>
                <Route path="/adduser" element={<AddUsers />}></Route>
                <Route path="/addoffer" element={<AddOffer />}></Route>
                <Route path="/customers" element={<Customers />}></Route>
                <Route path="/vendors" element={<Vendors />}></Route>
                <Route path="/users" element={<Users />}></Route>
                <Route path="/wastematerial" element={<WasteMaterial />}></Route>
                <Route path="/levisandtaxes" element={<LevisAndTaxes />}></Route>
                <Route path="/productlist" element={<ProductList />}></Route>
                <Route path="/jamaNave" element={<JamaNave />}></Route>
                <Route path="/addjamaentry" element={<AddJamaEntry />}></Route>
                <Route path="/pincodes" element={<Pincodes />}></Route>
                <Route path="/addpincodes" element={<AddPincodesScreen />}></Route>
                <Route path="/deliveryindex" element={<DeliveryChargesDetails />}></Route>
                <Route path="/offers" element={<Offers />}></Route>
                <Route path="/orderPlaceStatus" element={<OrderPlaceStatus />}></Route>
                <Route path="/refundPolicy" element={<RefundPolicy />}></Route>
                <Route path="/privacyPolicy" element={<PrivacyPolicy />}></Route>
                <Route path="/cancellationPolicy" element={<CancellationPolicy />}></Route>
                <Route path="/termsAndConditions" element={<TermsAndConditions />}></Route>
                <Route path="/shippingPolicy" element={<ShippingPolicy />}></Route>
                <Route path="/timeSlots" element={<TimeSlots />}></Route>
                <Route path="/refunds" element={<Refunds />}></Route>
                <Route path="/paymenthistory" element={<PaymentHistory />}></Route>
            </Routes>
        </div>
    )
}

export default AppRoutes;