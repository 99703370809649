import React from "react";

const ShippingPolicy = () => {
    return (
        <>
            <p>Shipping Policy&nbsp;</p>
            <p>
                <br />
            </p>
            <p>
                FarmEasy provides delivery in certain area only. Nowly we provides delivery
                in Mumbai &amp; Navi Mumbai, Panvel area.&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                Cost of shipping : we don't provide door step delivery at free cost. We
                charge it depends upon order weight and order location
            </p>
            <p>
                <br />
            </p>
            <p>
                Shipping updates : we gives you shipping updates on SMS as well as website.
                It may be delay or some times it will not done because of server issues.
            </p>
            <p>
                <br />
            </p>
            <p>
                Shipping time : we provides orders within 12 hour's. If you order today it's
                delivered you second day morning. It may be delay because of rode
                traffic.&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                Buy online and pick up at the store: Yes, we provide you pick up point. In
                this option you don't charge delivery charge. And timing of pickup is
                informed you by SMS or mail .&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                Tracking Information : tracking information available for customer on site
                or our app. Or you can directly contact to our customer care officer for its
                update.&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>
                Changes to this Policy : We reserve a rights to change this policy at any
                point without any noticed. It will direct update on site.&nbsp;
            </p>
            <p>
                <br />
            </p>
            <p>Applicable Law</p>
            <p>
                The Shipping Policy will be covered under the general rules of the Indian
                Contract Act, 1872
            </p>
        </>
    )
}

export default ShippingPolicy;