import { AutoComplete } from 'antd';
import React, { useEffect, useState } from 'react'
import { getProducts, getVendors, AddInventoryAPI } from '../../API';
import Input from 'antd/es/input/Input';

const categories = [
    { label: 'Vegetables', value: 'Vegetables' },
    { label: 'Fruits', value: 'Fruits' },
    { label: 'Organic Vegetables', value: 'Organic Vegetables' },
];


const AddInventory = () => {
    const [formStatus, setFormStatus] = useState('Add Inventory');
    const [selectedOptionCategory, setSelectedOptionCategory] = useState('');
    const [selectedOptionCustomer, setSelectedOptionCustomer] = useState('');
    const [products, setProducts] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [inputValueCategory, setInputValueCategory] = useState('');
    const [inputValueCustomer, setInputValueCustomer] = useState('');
    const [selectedProduct, setSelectedProduct] = useState('');
    const [selectedProductID, setSelectedProductID] = useState('');
    const [selectedProductName, setSelectedProductName] = useState('');
    const [productQuantity, setProductQuantity] = useState('');
    const [purchasePrice, setPurchasePrice] = useState('');
    const [vendorID, setVendorID] = useState('');
    const [vendorName, setVendorName] = useState('');
    const [vendorContact, setVendorContact] = useState('');
    const [vendorLocation, setVendorLocation] = useState('');
    const [levitax, setLeviTax] = useState('');
    const [pattaKadata, setPattaKadata] = useState('');

    useEffect(() => {
        getProducts("1")
        .then(res => {
            //setLoading(false);
            console.log(res.Products);
            setProducts(res.Products);
        })
    }, [])

    useEffect(() => {
        getVendors("1")
        .then(res => {
            //setLoading(false);
            console.log(res.Products);
            setVendors(res.Vendors);
        })
    }, []);

    const addInventoryProduct = () => {
        if (selectedProductID === '') {
            alert("Please select Product First.")
        } else if (purchasePrice === '') {
            alert("Please select Product Purchase Price.")
        } else if (productQuantity === '') {
            alert("Please select Product Purchase Stock.")
        } else if (vendorID === '') {
            alert("Please select Product Vendor.")
        } else {
            let newDate = new Date()
            let date = newDate.getDate();
            if (date < 10) {
                date = "0"+date;
            } else {
    
            }
            let month = newDate.getMonth() + 1;
            if (month < 10) {
                month = "0"+month;
            } else {
    
            }
            let year = newDate.getFullYear();
            let finaldate = date+"-"+month+"-"+year;
            console.log(finaldate);
            var finalProductQuan = pattaKadata === '' ? productQuantity : (parseInt(productQuantity) - parseInt(pattaKadata))
            AddInventoryAPI(selectedProductID, selectedProductName, finalProductQuan, purchasePrice, vendorID, vendorName, vendorContact, vendorLocation, levitax === '' ? 0 : levitax, pattaKadata === '' ? 0 : pattaKadata, finaldate)
            .then(res => {
                setSelectedProduct('');
                setSelectedProductID('');
                setSelectedProductName('')
                setProductQuantity('');
                setPurchasePrice('');
                setInputValueCustomer('');
                setVendorID('');
                setVendorName('');
                setVendorContact('');
                setVendorLocation('');
                setLeviTax('');
                setPattaKadata('');
                alert("Inventory Added Successfully.")
            })
        }
    }

    return (
        <div className="container mt-5">
            <h2 className="mb-3">Add Inventory Details</h2>
            {/* <form onSubmit={onSubmit}> */}
            <div className="mb-3">
                <label className="form-label" htmlFor="purchasedfrom">
                    Select Product
                </label>
                <AutoComplete
                    className="form-control"
                    //value={selectedProduct}
                    options={products}
                    autoFocus={true}
                    style={{ width: 200 }}
                    filterOption={(inputValue, option) =>
                        option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                    onSelect={(value, data) => {
                        setSelectedProduct(value);
                        setSelectedProductID(data.id);
                        setSelectedProductName(data.productName)
                        //addProductToOrder(data.id, data.value, data.price);
                    }}
                    onChange={() => {
                        setSelectedProduct("");
                    }}
                />
            </div>
            <div className="mb-3">
                <label className="form-label" htmlFor="price">
                    Price
                </label>
                <Input value={purchasePrice} className="form-control" type="number" id="price" required onChange={(evt) => { setPurchasePrice(evt.target.value) }}/>
            </div>
            <div className="mb-3">
                <label className="form-label" htmlFor="price">
                    Patta Kadata
                </label>
                <Input value={pattaKadata} className="form-control" type="number" id="pattakadata" required onChange={(evt) => { setPattaKadata(evt.target.value) }}/>
            </div>
            <div className="mb-3">
                <label className="form-label" htmlFor="price">
                    Levis and Taxes
                </label>
                <Input value={levitax} className="form-control" type="number" id="tax" required onChange={(evt) => { setLeviTax(evt.target.value) }}/>
            </div>
            <div className="mb-3">
                <label className="form-label" htmlFor="stock">
                    Stock
                </label>
                <Input value={productQuantity} className="form-control" type="number" id="stock" required onChange={(evt) => { setProductQuantity(evt.target.value) }}/>
            </div>
            <div className="mb-3">
                <label className="form-label" htmlFor="purchasedfrom">
                    Purchased From
                </label>
                {/* <input className="form-control" type="text" id="purchasedfrom" required /> */}
                <AutoComplete
                    className="form-control"
                    //value={inputValueCustomer}
                    options={vendors}
                    autoFocus={true}
                    style={{ width: 200 }}
                    filterOption={(inputValue, option) =>
                        option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                    onSelect={(value, data) => {
                        setInputValueCustomer(value);
                        setVendorID(data.id);
                        setVendorName(data.vendor_name);
                        setVendorContact(data.vendor_contact);
                        setVendorLocation(data.vendor_location);
                    }}

                />
            </div>
            <button className="btn btn-danger mb-5" onClick={() => addInventoryProduct()}>
                Add Inventory
            </button>
            {/* </form> */}
        </div>
    )
}
export default AddInventory;